/* eslint-disable react/jsx-indent */
import React, { Component } from 'react';
import { get } from 'lodash';
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
} from 'formik';
import * as Yup from 'yup';
import checkMarkTrue from 'shared/Icons/checkMarkTrue.svg';
import checkMarkFalse from 'shared/Icons/checkMarkFalse.svg';
import { Button } from 'shared';
import { IconUser } from 'shared/Icons';
import PropTypes from 'prop-types';
import { changePassword } from '../actions';

class ChangePassword extends Component {
  getPasswordValidationSchema = (companyConfig) => {
    const checkPasswordRequirements = get(companyConfig, 'config.password_policy.enabled', false);
    let passwordSchema = Yup.string().required();

    if (checkPasswordRequirements) {
      const minPasswordLength = get(companyConfig, 'config.password_policy.min_length', 0);
      const requiresUppercase = get(companyConfig, 'config.password_policy.caps_required', false);
      const requiresLowercase = get(companyConfig, 'config.password_policy.lowercase_required', false);
      const requiresNumber = get(companyConfig, 'config.password_policy.number_required', false);
      const requiresSpecialChar = get(companyConfig, 'config.password_policy.special_char_required', false);

      if (minPasswordLength) {
        passwordSchema = passwordSchema.min(minPasswordLength);
      }

      if (requiresUppercase) {
        passwordSchema = passwordSchema.matches(/[A-Z]/);
      }

      if (requiresLowercase) {
        passwordSchema = passwordSchema.matches(/[a-z]/);
      }

      if (requiresNumber) {
        passwordSchema = passwordSchema.matches(/[0-9]/);
      }

      if (requiresSpecialChar) {
        passwordSchema = passwordSchema.matches(/[^A-Za-z0-9]/);
      }

      return passwordSchema;
    }
    return passwordSchema;
  };

  renderPasswordRequirements = (values, companyConfig) => {
    const { t } = this.props;

    const checkPasswordRequirements = get(companyConfig, 'config.password_policy.enabled', false);

    if (checkPasswordRequirements) {
      const minPasswordLength = get(companyConfig, 'config.password_policy.min_length', 0);
      const requiresUppercase = get(companyConfig, 'config.password_policy.caps_required', false);
      const requiresLowercase = get(companyConfig, 'config.password_policy.lowercase_required', false);
      const requiresNumber = get(companyConfig, 'config.password_policy.number_required', false);
      const requiresSpecialChar = get(companyConfig, 'config.password_policy.special_char_required', false);

      return (
        <div className="password-requirements">
          <ul>
            {
              minPasswordLength &&
              <li className={values.new_password.length >= minPasswordLength ? 'password-requirement-true' : 'password-requirement-error'}>
                {values.new_password.length >= minPasswordLength ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> :
                  <img src={checkMarkFalse} width="18px" height="18px" alt="" />}
                <span className="password-requirement-text">{t('user_profile_page.characters_requirement', { num: `${minPasswordLength}` })}</span></li>
            }

            {
              requiresUppercase &&
              <li className={/[A-Z]/.test(values.new_password) ? 'password-requirement-true' : 'password-requirement-error'}>
                {/[A-Z]/.test(values.new_password) ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> :
                  <img src={checkMarkFalse} width="18px" height="18px" alt="" />}
                <span className="password-requirement-text">{t('user_profile_page.uppercase_letter_requirement')}</span></li>
            }

            {
              requiresLowercase &&
              <li className={/[a-z]/.test(values.new_password) ? 'password-requirement-true' : 'password-requirement-error'}>
                {/[a-z]/.test(values.new_password) ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> :
                  <img src={checkMarkFalse} width="18px" height="18px" alt="" />}
                <span className="password-requirement-text">{t('user_profile_page.lowercase_letter_requirement')}</span></li>
            }

            {
              requiresNumber &&
              <li className={/[0-9]/.test(values.new_password) ? 'password-requirement-true' : 'password-requirement-error'}>
                {/[0-9]/.test(values.new_password) ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> :
                  <img src={checkMarkFalse} width="18px" height="18px" alt="" />}
                <span className="password-requirement-text">{t('user_profile_page.number_requirement')}</span></li>
            }

            {
              requiresSpecialChar &&
              <li className={/[^A-Za-z0-9]/.test(values.new_password) ? 'password-requirement-true' : 'password-requirement-error'}>
                {/[^A-Za-z0-9]/.test(values.new_password) ? <img src={checkMarkTrue} width="18px" height="18px" alt="" /> :
                  <img src={checkMarkFalse} width="18px" height="18px" alt="" />}
                <span className="password-requirement-text">{t('user_profile_page.special_character_requirement')}</span></li>
            }
          </ul>
        </div>
      );
    }
  };

  render() {
    const { currentUser, t, companyConfig } = this.props;

    const passwordValidationSchema = this.getPasswordValidationSchema(companyConfig);

    return (
      <div>
        <div className="current_user">
          <IconUser height="60px" width="60px" />
          <h3>{currentUser.email}</h3>
        </div>
        <Formik
          initialValues={{ current_password: '', new_password: '', confirm_password: '' }}
          validationSchema={Yup.object().shape({
            current_password: Yup.string().required(t('user_profile_page.current_password_required')),
            // new_password: passwordValidationSchema,
            confirm_password: Yup.string()
              .oneOf([Yup.ref('new_password'), null], t('user_profile_page.passwords_must_match'))
              .required(t('user_profile_page.confirm_password_required')),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            changePassword(values)
              .then(() => {
                setSubmitting(false);
                resetForm();
              })
              .catch(() => {
                setSubmitting(false);
              });
          }}
        >
          {({ values, isSubmitting, handleChange, handleBlur }) => (
            <Form ref={this.formRef} className="change-password-form">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <Field
                        type="password"
                        name="current_password"
                        placeholder={t('user_profile_page.current_pass_placeholder')}
                        value={values.current_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage className="error-message" name="current_password" component="div" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        type="password"
                        name="new_password"
                        placeholder={t('user_profile_page.new_pass_placeholder')}
                        value={values.new_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Field
                        type="password"
                        name="confirm_password"
                        placeholder={t('user_profile_page.confirm_pass_placeholder')}
                        value={values.confirm_password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage className="error-message" name="confirm_password" component="div" />
                    </td>
                  </tr>
                  <tr>
                    <td>{this.renderPasswordRequirements(values, companyConfig)}</td>
                  </tr>
                  <tr>
                    <td>
                      <Button
                        buttonType="submit"
                        type="dark-blue"
                        disabled={
                          isSubmitting ||
                          !values.current_password ||
                          !passwordValidationSchema.isValidSync(values.new_password) || // Ensure new password meets requirements
                          values.new_password !== values.confirm_password // Confirm password matches
                        }
                      >
                        {t('user_profile_page.change_pass_button')}
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  companyConfig: PropTypes.object,
};

export default ChangePassword;
