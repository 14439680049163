/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import moment from 'moment';
import { get } from 'lodash';
import { timeRangeEnum, timeRangeConfig } from 'shared/DatePicker/constants';
import { ConfirmationModal } from 'shared';
import {
  IconRemove,
  IconSettings,
} from 'shared/Icons';
import copyImage from 'shared/Icons/FilePlus.png';
import './style.scss';

import {
  getData, getProductionData, getTransformerData, getEventsData, getCalculatedData, getOEE, getOrdersData,
} from './api';

import AreaChart from '../charts/AreaChart';
import BarChart from '../charts/BarChart';
import PieChart from '../charts/PieChart';
import LineChart from '../charts/LineChart';
import StepChart from '../charts/StepChart';
import ComposedChart from '../charts/ComposedChart';
import SankeyChart from '../charts/SankeyChart';
import ScatterChart from '../charts/ScatterChart';
import PercentAreaChart from '../charts/PercentAreaChart';
import Gauge from '../charts/Gauge';
import Histogram from '../charts/Histogram';
import LocationMap from '../Map';
import Scalar from '../Scalar';
import WidgetPlaceholder from '../WidgetPlaceholder';
import Table from '../Table';
import TableRange from '../TableRange';
import GanttChart from '../charts/GanttChart';
import RealtimeWidget from '../charts/RealtimeWidget';
import OrdersTable from '../OrdersTable/index';
import Label from '../Label/index';
// import { defaultSettings } from '../WidgetForms/constants';

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

class Widget extends Component {
  constructor(props) {
    super();
    this.state = {
      width: this.getWidth() || 300,
      data: props.data || null,
      showMenu: false,
      isLoading: false,
      eventsData: {},
      overrideShowTitlebar: false,
      isCopyWidgetModalOpen: false,
      selectedWidgetTypeCopy: null,
      errorType: false,
      isInViewport: true,
      isFirstLoading: true,
      showConfirmationDialog: false,
      showConfirmationDialogCopy: false,
    };
    this.renderChart = this.renderChart.bind(this);
    this.getWidth = this.getWidth.bind(this);
    // this.setWidgetInterval = this.setWidgetInterval.bind(this);
    this.fetchWidgetData = this.fetchWidgetData.bind(this);
    this.widgetTimeout = null;
    this.widgetRef = React.createRef();
  }

  componentDidMount() {
    // eslint-disable-next-line
    this.setState({ width: this.getWidth() });

    // if (get(this, 'props.widgetData.length')) {
    //   this.fetchWidgetData();
    // }

    if (this.widgetRef.current) {
      const observer = new window.IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          this.setState({ isInViewport: true });
          this.fetchWidgetData();
          observer.unobserve(entry.target);
        } else {
          this.setState({ isInViewport: false });
        }
      }, {
        root: null,
        threshold: 0,
      });

      observer.observe(this.widgetRef.current);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      meta: {
        interval: prevInterval,
        daterange: prevDaterange,
      },
      widgetData: prevWidgetData,
      manualRefresh: prevManualRefresh,
    } = prevProps;
    const {
      meta: {
        interval,
        daterange,
      },
      widgetData,
      manualRefresh,
      globalFilters,
    } = this.props;

    const width = this.getWidth();
    if (prevState.width !== width) {
      // eslint-disable-next-line
      this.setState({ width });
    }
    if (JSON.stringify(prevProps.globalFilters) !== JSON.stringify(globalFilters)) {
      this.fetchWidgetData();
    }
    if (get(this, 'props.type') === 'orderstable' && prevProps && prevProps.settings && prevProps.settings.assets_in &&
      this.props && this.props.settings && this.props.settings.assets_in &&
      (JSON.stringify(prevProps.settings.assets_in) !== JSON.stringify(this.props.settings.assets_in))) {
      this.fetchWidgetData();
    }

    const isManualRefresh = !prevManualRefresh && !!manualRefresh;

    if (
      prevInterval !== interval ||
      !prevDaterange.start.isSame(daterange.start) ||
      !prevDaterange.end.isSame(daterange.end) ||
      (!prevWidgetData && !!widgetData) ||
      isManualRefresh ||
      (
        !!prevWidgetData &&
        !!widgetData &&
        JSON.stringify(prevWidgetData) !== JSON.stringify(widgetData)
      )
    ) {
      if (isManualRefresh) {
        this.props.updateManualRefresh(false);
      }
      if (this.widgetTimeout) {
        clearTimeout(this.widgetTimeout);
      }
      if (get(this, 'props.widgetData.length')) {
        this.fetchWidgetData();
      }
      if (get(this, 'props.type') === 'orderstable') {
        this.fetchWidgetData();
      }
    }
  }

  componentWillUnmount() {
    if (this.widgetTimeout) {
      clearTimeout(this.widgetTimeout);
    }
    source.cancel();
  }

  getEventPointMeta(daterange) {
    let to = daterange.end;
    let from = daterange.start;
    const label = daterange.label;

    if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
      to = timeRangeConfig()[label].endDate;
      from = timeRangeConfig()[label].startDate;
    }

    return {
      daterange,
      axiosSource: source,
      from: from && from.toISOString(),
      to: from && to.toISOString(),
    };
  }

  getDatapointMeta(
    {
      metric,
      group_by: groupBy,
      operation,
      style,
    },
    daterange,
  ) {
    let to = daterange.end;
    let from = daterange.start;
    const label = daterange.label;

    if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
      to = timeRangeConfig()[label].endDate;
      from = timeRangeConfig()[label].startDate;
    }

    return {
      id: (metric && metric.id) || metric,
      daterange,
      groupBy,
      operation,
      axiosSource: source,
      gapFill: style && style.gapFill,
      from: from && from.toISOString(),
      to: to && to.toISOString(),
    };
  }

  getWidth() {
    return get(this, 'myInput.offsetWidth') || 300;
  }

  getWidgetTitle = () => {
    const { title, widget_data } = this.props;

    const regex = /\{(.*?)\}/g;
    let modifiedTitle = title;

    let match;
    for (match = regex.exec(title); match !== null; match = regex.exec(title)) {
      const accessor = match[1];
      const accessorValue = get(widget_data, `[0].metric.${accessor}`, '');
      modifiedTitle = modifiedTitle.replace(match[0], accessorValue);
    }

    return modifiedTitle;
  }

  fetchWidgetData() {
    const {
      type, widgetData, meta: { daterange }, settings, title, globalFilters, companyId,
    } = this.props;
    const { data, isInViewport } = this.state;
    if (!isInViewport) return;
    this.setState({ isLoading: true });
    const promises = [];
    const adjusted_value = settings && settings.adjusted_value ? settings.adjusted_value : null;
    const where = settings && settings.where ? settings.where : null;
    const shift = settings && settings.shift ? settings.shift : null;
    const productType = settings && settings.productType ? settings.productType : null;
    const workOperation = settings && settings.workOperation ? settings.workOperation : null;

    const widgetTitle = title || '';

    switch (type) {
      case 'gauge':
        const wData = widgetData && widgetData.length > 0 && widgetData[0];
        const { metric, groupBy } = wData;

        if (settings && settings.isOEE) {
          const metric_ok = settings && settings.all_items_metric && settings.all_items_metric.id ? settings.all_items_metric.id : null;
          const metric_bad = settings && settings.bad_items_metric && settings.bad_items_metric.id ? settings.bad_items_metric.id : null;
          const metric_utilization = settings && settings.utilization_metric && settings.utilization_metric.id ? settings.utilization_metric.id : null;
          const max_for_minute = settings && settings.max_items_per_min && settings.max_items_per_min ? settings.max_items_per_min : null;
          const value_to_display = settings && settings.value_to_display && settings.value_to_display.label ? settings.value_to_display.label : 'OEE';

          getOEE(Object.assign(
            this.getDatapointMeta(wData, daterange),
            { metric_ok },
            { metric_bad },
            { metric_utilization },
            { max_for_minute },
          ))
            .then((res) => {
              let display_data = null;
              if (res) {
                if (value_to_display === 'OEE') {
                  display_data = res.oee;
                }

                if (value_to_display === 'Quality') {
                  display_data = res.quality;
                }

                if (value_to_display === 'Performance') {
                  display_data = res.performance;
                }

                if (value_to_display === 'Availability') {
                  display_data = res.availability;
                }
              }
              this.setState({
                data: { value: display_data },
                isLoading: false,
              });
              this.delayedFetchWidgetData();
            });
        } else {
          if (metric && metric.data_storage === 'productiondata') {
            getProductionData(Object.assign(
              this.getDatapointMeta(wData, daterange),
              { limit: 1 },
              { adjusted_value },
              { where },
              { shift },
              { productType },
              { widgetTitle },
              { datapointLabel: wData.label },
              { globalFilters },
              { workOperation },
            ))
              .then((res) => {
                const widData = (res && res[Object.keys(res)] && res[Object.keys(res)][0]) || [];
                this.setState({
                  data: widData,
                  isLoading: false,
                });
                this.delayedFetchWidgetData();
              });
          } else {
            getData(Object.assign(
              this.getDatapointMeta(wData, daterange),
              { limit: 1 },
              { adjusted_value },
              { where },
              { shift },
              { productType },
              { widgetTitle },
              { datapointLabel: wData.label },
            ))
              .then((res) => {
                const widData = (res && res[Object.keys(res)] && res[Object.keys(res)][0]) || [];
                this.setState({
                  data: widData,
                  isLoading: false,
                });
                this.delayedFetchWidgetData();
              });
          }
          return null;
        }
        break;
      case 'line':
      case 'step':
      case 'percent':
      case 'scatter':
      case 'composed':
        const graphData = data || {};
        const shiftGraphData = {};
        const fetchEvents = get(this, 'props.settings.legend.events', false);
        let shiftDaterange = {};

        if (fetchEvents) {
          const metricIds = widgetData.map((x) => (x.metric && x.metric.id) || x.metric);
          getEventsData({ ...this.getEventPointMeta(daterange), ids: metricIds })
            .then((res) => {
              this.setState({ eventsData: res });
            });
        }

        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            if (!widget.metric && !widget.style.isCalculatedValueUsage) {
              // we skip datapoints with no metrics
              // but also skip calculated values datapoints
              // because we have one datapoint to connect them
              // one ring to rule them all
              return;
            }
            shiftGraphData[widget.id] = [];

            if (daterange && daterange.selectedShiftDays) {
              for (let i = 0; i <= daterange.selectedShiftDays; i++) {
                const startDateTime = new Date(daterange.start);

                const endDateTime = new Date(daterange.end);

                const startDateShift = moment(startDateTime.setDate(startDateTime.getDate() + i));
                // eslint-disable-next-line no-mixed-operators
                const endDateShift = moment(endDateTime.setDate(endDateTime.getDate() - daterange.selectedShiftDays + i));

                shiftDaterange = { start: startDateShift, end: endDateShift, label: 'custom' };
                if (widget.style.isCalculatedValueUsage) {
                  const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
                  const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
                  const extraFields = {
                    ...widget.style,
                    vars: metricIds,
                  };

                  const isProductionData = calcValsDatapoints.every((value) => {
                    return value.metric.data_storage === 'productiondata';
                  });

                  const promise = getCalculatedData({ ...this.getDatapointMeta(widget, shiftDaterange), isProductionData, extraFields, widgetTitle, datapointLabel: widget.label })
                    .then((res) => {
                      shiftGraphData[widget.id].push(...(res && res[Object.keys(res)]) || []);
                    });
                  promises.push(promise);
                } else {
                  // eslint-disable-next-line
                  if (widget.metric.data_storage === 'productiondata') {

                    const promise = getProductionData(
                      Object.assign(
                        this.getDatapointMeta(widget, shiftDaterange),
                        { fetchEvents },
                        { adjusted_value },
                        { widgetTitle },
                        { shift },
                        { where },
                        { datapointLabel: widget.label },
                        { idx },
                        { globalFilters },
                        { workOperation },
                      ),
                    )
                      .then((res) => {
                        shiftGraphData[widget.id].push(...(res && res[Object.keys(res)]) || []);
                      });
                    promises.push(promise);
                  } else {
                    const promise = getData({ ...this.getDatapointMeta(widget, shiftDaterange), adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                      .then((res) => {
                        shiftGraphData[widget.id].push(...(res && res[Object.keys(res)]) || []);
                      });
                    promises.push(promise);
                  }
                }
              }
            }

            if (widget.style.isCalculatedValueUsage && !daterange.selectedShiftDays) {
              const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
              const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
              const extraFields = {
                ...widget.style,
                vars: metricIds,
              };

              const isProductionData = calcValsDatapoints.every((value) => {
                return value.metric.data_storage === 'productiondata';
              });

              const promise = getCalculatedData({ ...this.getDatapointMeta(widget, daterange), isProductionData, extraFields, widgetTitle, datapointLabel: widget.label })
                .then((res) => {
                  graphData[widget.id] = (res && res[Object.keys(res)]) || [];
                  this.setState({
                    data: { ...graphData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            } else {
              // eslint-disable-next-line
              if (widget.metric.data_storage === 'productiondata' && !daterange.selectedShiftDays) {
                const promise = getProductionData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { shift },
                    { where },
                    { datapointLabel: widget.label },
                    { idx },
                    { globalFilters },
                    { workOperation },
                  ),
                )
                  .then((res) => {
                    graphData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...graphData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else if (widget.metric.data_storage === 'dynamic') {
                const promise = getTransformerData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { productType },
                    { shift },
                    { where },
                    { datapointLabel: widget.label },
                    { idx },
                  ),
                )
                  .then((res) => {
                    graphData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...graphData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (!daterange.selectedShiftDays) {
                  const promise = getData({ ...this.getDatapointMeta(widget, daterange), adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                    .then((res) => {
                      graphData[widget.id] = (res && res[Object.keys(res)]) || [];
                      this.setState({
                        data: { ...graphData },
                        isLoading: false,
                      });
                    });
                  promises.push(promise);
                }
              }
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            if (shiftGraphData && daterange && daterange.selectedShiftDays) {
              this.setState({
                data: { ...shiftGraphData },
                isLoading: false,
              });
            }
            this.delayedFetchWidgetData();
          });
        break;
      case 'gantt':
        const ganttGraphData = data || {};
        const ganttShiftGraphData = {};
        const fetchGanttEvents = get(this, 'props.settings.legend.events', false);
        let ganttShiftDaterange = {};

        if (fetchGanttEvents) {
          const metricIds = widgetData.map((x) => (x.metric && x.metric.id) || x.metric);
          getEventsData({ ...this.getEventPointMeta(daterange), ids: metricIds })
            .then((res) => {
              this.setState({ eventsData: res });
            });
        }

        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            widget.operation = '';
            if (!widget.metric && !widget.style.isCalculatedValueUsage) {
              // we skip datapoints with no metrics
              // but also skip calculated values datapoints
              // because we have one datapoint to connect them
              // one ring to rule them all
              return;
            }
            ganttShiftGraphData[widget.id] = [];

            if (daterange && daterange.selectedShiftDays) {
              for (let i = 0; i <= daterange.selectedShiftDays; i++) {
                const startDateTime = new Date(daterange.start);

                const endDateTime = new Date(daterange.end);

                const startDateShift = moment(startDateTime.setDate(startDateTime.getDate() + i));
                // eslint-disable-next-line no-mixed-operators
                const endDateShift = moment(endDateTime.setDate(endDateTime.getDate() - daterange.selectedShiftDays + i));

                ganttShiftDaterange = { start: startDateShift, end: endDateShift, label: 'custom' };

                const promise = getTransformerData(
                  Object.assign(
                    this.getDatapointMeta(widget, ganttShiftDaterange),
                    { fetchGanttEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { productType },
                    { shift },
                    { where },
                    { datapointLabel: widget.label },
                    { idx },
                  ),
                )
                  .then((res) => {
                    ganttGraphData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...ganttGraphData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              }
            } else {
              const promise = getTransformerData(
                Object.assign(
                  this.getDatapointMeta(widget, daterange),
                  { fetchGanttEvents },
                  { adjusted_value },
                  { widgetTitle },
                  { productType },
                  { shift },
                  { where },
                  { datapointLabel: widget.label },
                  { idx },
                ),
              )
                .then((res) => {
                  ganttGraphData[widget.id] = (res && res[Object.keys(res)]) || [];
                  this.setState({
                    data: { ...ganttGraphData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            if (ganttShiftGraphData && daterange && daterange.selectedShiftDays) {
              this.setState({
                data: { ...ganttShiftGraphData },
                isLoading: false,
              });
            }
            this.delayedFetchWidgetData();
          });
        break;
      case 'sankey':
        const sankeyData = data || {};
        const fetchEventsSankey = get(this, 'props.settings.legend.events', false);
        if (fetchEventsSankey) {
          const metricIds = widgetData.map((x) => (x.metric && x.metric.id) || x.metric);
          getEventsData({ ...this.getEventPointMeta(daterange), ids: metricIds })
            .then((res) => {
              this.setState({ eventsData: res });
            });
        }
        if (widgetData) {
          widgetData.forEach((widgetDataItem) => {
            if (!widgetDataItem.metric) {
              return;
            }
            if (widgetDataItem.metric.data_storage === 'productiondata') {
              const promise = getProductionData(
                Object.assign(
                  this.getDatapointMeta(widgetDataItem, daterange),
                  { fetchEventsSankey },
                  { adjusted_value },
                  { where },
                  { globalFilters },
                ),
              )
                .then((res) => {
                  sankeyData[widgetDataItem.id] = (res && res[Object.keys(res)]) || [];
                  this.setState({
                    data: { ...sankeyData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            } else if (widgetDataItem.metric.data_storage === 'dynamic') {
              const promise = getTransformerData(
                Object.assign(
                  this.getDatapointMeta(widgetDataItem, daterange),
                  { fetchEventsSankey },
                  { adjusted_value },
                  { where },
                ),
              )
                .then((res) => {
                  sankeyData[widgetDataItem.id] = (res && res[Object.keys(res)]) || [];
                  this.setState({
                    data: { ...sankeyData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            } else {
              const promise = getData(
                Object.assign(
                  this.getDatapointMeta(widgetDataItem, daterange),
                  { fetchEventsSankey },
                  { adjusted_value },
                  { where },
                ),
              )
                .then((res) => {
                  sankeyData[widgetDataItem.id] = (res && res[Object.keys(res)]) || [];
                  this.setState({
                    data: { ...sankeyData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            this.delayedFetchWidgetData();
          });
        break;
      case 'map':
        const mapData = data || {};

        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            if (!widget.metric) {
              return;
            }

            const promise = getData({ ...this.getDatapointMeta({ metric, groupBy }, daterange), limit: 500, adjusted_value, where, shift, productType, idx })
              .then((res) => {
                mapData[widget.id] = res || [];
                this.setState({
                  data: { ...mapData },
                  isLoading: false,
                });
              });
            promises.push(promise);
          });
        }

        Promise.all(promises)
          .then(() => {
            this.delayedFetchWidgetData();
          });
        break;
      case 'scalar':
        const widgetDataItem = widgetData && widgetData.filter((wD) => !wD.style.isCalculatedValue && !wD.style.isCalculatedValueUsage)[0];
        const calcWidgetDataItem = widgetData && widgetData.filter((wD) => !wD.style.isCalculatedValue && wD.style.isCalculatedValueUsage)[0];
        // eslint-disable-next-line no-unneeded-ternary
        const showCalcValue = widgetDataItem && calcWidgetDataItem ? true : widgetDataItem && !calcWidgetDataItem ? false : !widgetDataItem && calcWidgetDataItem ? true : false;

        if (!widgetData) {
          return;
        }

        if (showCalcValue) {
          if (calcWidgetDataItem && !settings.isOEE) {
            const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
            const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
            const extraFields = {
              ...calcWidgetDataItem.style,
              vars: metricIds,
            };

            const isProductionData = calcValsDatapoints.every((value) => {
              return value.metric.data_storage === 'productiondata';
            });

            getCalculatedData({ ...this.getDatapointMeta(calcWidgetDataItem, daterange), isProductionData, limit: 1, extraFields, widgetTitle, datapointLabel: calcWidgetDataItem.label })
              .then((res) => {
                const value = get(res, `[${Object.keys(res)[0]}[0]`) || '';
                this.setState({
                  data: value,
                  isLoading: false,
                });
                this.delayedFetchWidgetData();
              });
          }
        } else {
          if (!widgetDataItem || !widgetDataItem.metric) {
            return;
          }
          if (widgetDataItem.metric.data_storage === 'productiondata' && !settings.isOEE) {
            getProductionData(
              {

                ...this.getDatapointMeta(widgetDataItem, daterange),
                limit: 1,
                adjusted_value,
                where,
                shift,
                productType,
                widgetTitle,
                datapointLabel: widgetDataItem.label,
                globalFilters,
                workOperation,
              },
            )
              .then((res) => {
                const value = get(res, `[${Object.keys(res)[0]}[0]`) || '';
                this.setState({
                  data: value,
                  isLoading: false,
                });
                this.delayedFetchWidgetData();
              });
          } else if (widgetDataItem.metric.data_storage === 'dynamic' && !settings.isOEE) {
            getTransformerData(
              {

                ...this.getDatapointMeta(widgetDataItem, daterange),
                limit: 1,
                adjusted_value,
                where,
                shift,
                productType,
                widgetTitle,
                datapointLabel: widgetDataItem.label,
              },
            )
              .then((res) => {
                const value = get(res, `[${Object.keys(res)[0]}[0]`) || '';
                this.setState({
                  data: value,
                  isLoading: false,
                });
                this.delayedFetchWidgetData();
              });
          } else if (widgetDataItem.metric.data_storage !== 'productiondata' && !settings.isOEE) {
            getData(
              {

                ...this.getDatapointMeta(widgetDataItem, daterange),
                limit: 1,
                adjusted_value,
                where,
                shift,
                productType,
                widgetTitle,
                datapointLabel: widgetDataItem.label,
              },
            )
              .then((res) => {
                const value = get(res, `[${Object.keys(res)[0]}[0]`) || '';
                this.setState({
                  data: value,
                  isLoading: false,
                });
                this.delayedFetchWidgetData();
              });
          } else if (settings && settings.isOEE) {
            const metric_ok = settings && settings.all_items_metric && settings.all_items_metric.id ? settings.all_items_metric.id : null;
            const metric_bad = settings && settings.bad_items_metric && settings.bad_items_metric.id ? settings.bad_items_metric.id : null;
            const metric_utilization = settings && settings.utilization_metric && settings.utilization_metric.id ? settings.utilization_metric.id : null;
            const max_for_minute = settings && settings.max_items_per_min && settings.max_items_per_min ? settings.max_items_per_min : null;
            const value_to_display = settings && settings.value_to_display && settings.value_to_display.label ? settings.value_to_display.label : 'OEE';

            getOEE(Object.assign(
              this.getDatapointMeta(widgetDataItem, daterange),
              { metric_ok },
              { metric_bad },
              { metric_utilization },
              { max_for_minute },
            ))
              .then((res) => {
                let display_data = null;
                if (res) {
                  if (value_to_display === 'OEE') {
                    display_data = res.oee;
                  }

                  if (value_to_display === 'Quality') {
                    display_data = res.quality;
                  }

                  if (value_to_display === 'Performance') {
                    display_data = res.performance;
                  }

                  if (value_to_display === 'Availability') {
                    display_data = res.availability;
                  }
                }
                this.setState({
                  data: { value: display_data },
                  isLoading: false,
                });
                this.delayedFetchWidgetData();
              });
          }
        }

        break;
      case 'pie':
        const pieData = data || {};
        const shiftPieData = {};
        let shiftPieDaterange = {};

        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            if (!widget.metric && !widget.style.isCalculatedValueUsage) {
              // we skip datapoints with no metrics
              // but also skip calculated values datapoints
              // because we have one datapoint to connect them
              // one ring to rule them all
              return;
            }
            shiftPieData[widget.id] = [];

            if (daterange && daterange.selectedShiftDays) {
              for (let i = 0; i <= daterange.selectedShiftDays; i++) {
                const startDateTime = new Date(daterange.start);

                const endDateTime = new Date(daterange.end);

                const startDateShift = moment(startDateTime.setDate(startDateTime.getDate() + i));
                // eslint-disable-next-line no-mixed-operators
                const endDateShift = moment(endDateTime.setDate(endDateTime.getDate() - daterange.selectedShiftDays + i));

                shiftPieDaterange = { start: startDateShift, end: endDateShift, label: 'custom' };
                if (widget.style.isCalculatedValueUsage) {
                  const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
                  const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
                  const extraFields = {
                    ...widget.style,
                    vars: metricIds,
                  };

                  const isProductionData = calcValsDatapoints.every((value) => {
                    return value.metric.data_storage === 'productiondata';
                  });

                  const promise = getCalculatedData({ ...this.getDatapointMeta(widget, shiftPieDaterange), isProductionData, limit: 1, extraFields, widgetTitle, datapointLabel: widget.label })
                    .then((res) => {
                      shiftPieData[widget.id].push(...(res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {});
                    });
                  promises.push(promise);
                } else {
                  // eslint-disable-next-line
                  if (widget.metric.data_storage === 'productiondata') {
                    const promise = getProductionData(
                      Object.assign(
                        this.getDatapointMeta(widget, shiftPieDaterange),
                        { limit: 1 },
                        { adjusted_value },
                        { where },
                        { shift },
                        { productType },
                        { widgetTitle },
                        { datapointLabel: widget.label },
                        { idx },
                        { globalFilters },
                        { workOperation },
                      ),
                    )
                      .then((res) => {
                        shiftPieData[widget.id].push(...(res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {});
                      });
                    promises.push(promise);
                  } else if (widget.metric.data_storage === 'dynamic') {
                    const promise = getTransformerData(
                      Object.assign(
                        this.getDatapointMeta(widget, shiftPieDaterange),
                        { limit: 1 },
                        { adjusted_value },
                        { where },
                        { shift },
                        { productType },
                        { widgetTitle },
                        { datapointLabel: widget.label },
                        { idx },

                      ),
                    )
                      .then((res) => {
                        shiftPieData[widget.id].push(...(res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {});
                      });
                    promises.push(promise);
                  } else {
                    const promise = getData({ ...this.getDatapointMeta(widget, shiftPieDaterange), limit: 1, adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                      .then((res) => {
                        shiftPieData[widget.id].push(...(res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {});
                      });
                    promises.push(promise);
                  }
                }
              }
            }

            if (widget.style.isCalculatedValueUsage && !daterange.selectedShiftDays) {
              const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
              const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
              const extraFields = {
                ...widget.style,
                vars: metricIds,
              };

              const isProductionData = calcValsDatapoints.every((value) => {
                return value.metric.data_storage === 'productiondata';
              });

              const promise = getCalculatedData({ ...this.getDatapointMeta(widget, daterange), isProductionData, limit: 1, extraFields, widgetTitle, datapointLabel: widget.label })
                .then((res) => {
                  pieData[widget.id] = (res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {};
                  this.setState({
                    data: { ...pieData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            } else {
              // eslint-disable-next-line
              if (widget.metric.data_storage === 'productiondata' && !daterange.selectedShiftDays) {
                const promise = getProductionData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { limit: 1 },
                    { adjusted_value },
                    { where },
                    { shift },
                    { productType },
                    { widgetTitle },
                    { datapointLabel: widget.label },
                    { idx },
                    { globalFilters },
                    { workOperation },
                  ),
                )
                  .then((res) => {
                    pieData[widget.id] = (res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {};
                    this.setState({
                      data: { ...pieData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else if (widget.metric.data_storage === 'dynamic') {
                const promise = getTransformerData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { limit: 1 },
                    { adjusted_value },
                    { where },
                    { shift },
                    { productType },
                    { widgetTitle },
                    { datapointLabel: widget.label },
                    { idx },
                  ),
                )
                  .then((res) => {
                    pieData[widget.id] = (res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {};
                    this.setState({
                      data: { ...pieData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (!daterange.selectedShiftDays) {
                  const promise = getData({
                    ...this.getDatapointMeta(widget, daterange), limit: 1, adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx,
                  })
                    .then((res) => {
                      pieData[widget.id] = (res[Object.keys(res)[0]] && res[Object.keys(res)[0]][0]) || {};
                      this.setState({
                        data: { ...pieData },
                        isLoading: false,
                      });
                    });
                  promises.push(promise);
                }
              }
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            if (shiftPieData && daterange && daterange.selectedShiftDays) {
              this.setState({
                data: { ...shiftPieData },
                isLoading: false,
              });
            }
            this.delayedFetchWidgetData();
          });
        break;
      case 'bar':
      case 'histogram':
        const barData = data || {};
        const shiftGraphDataBar = {};
        let shiftDaterangeBar = {};

        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            if (!widget.metric && !widget.style.isCalculatedValueUsage) {
              // we skip datapoints with no metrics
              // but also skip calculated values datapoints
              // because we have one datapoint to connect them
              // one ring to rule them all
              return;
            }

            shiftGraphDataBar[widget.id] = [];
            if (daterange && daterange.selectedShiftDays) {
              for (let i = 0; i <= daterange.selectedShiftDays; i++) {
                const startDateTime = new Date(daterange.start);

                const endDateTime = new Date(daterange.end);

                const startDateShift = moment(startDateTime.setDate(startDateTime.getDate() + i));
                // eslint-disable-next-line no-mixed-operators
                const endDateShift = moment(endDateTime.setDate(endDateTime.getDate() - daterange.selectedShiftDays + i));

                shiftDaterangeBar = { start: startDateShift, end: endDateShift, label: 'custom' };

                if (widget.style.isCalculatedValueUsage) {
                  const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
                  const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
                  const extraFields = {
                    ...widget.style,
                    vars: metricIds,
                  };

                  const isProductionData = calcValsDatapoints.every((value) => {
                    return value.metric.data_storage === 'productiondata';
                  });

                  const promise = getCalculatedData({ ...this.getDatapointMeta(widget, shiftDaterangeBar), isProductionData, extraFields, widgetTitle, datapointLabel: widget.label })
                    .then((res) => {
                      shiftGraphDataBar[widget.id].push(...(res && res[Object.keys(res)]) || []);
                    });
                  promises.push(promise);
                } else {
                  // eslint-disable-next-line
                  if (widget.metric.data_storage === 'productiondata') {
                    const promise = getProductionData(
                      Object.assign(
                        this.getDatapointMeta(widget, shiftDaterangeBar),
                        { fetchEvents },
                        { adjusted_value },
                        { widgetTitle },
                        { shift },
                        { where },
                        { datapointLabel: widget.label },
                        { idx },
                        { globalFilters },
                        { workOperation },
                      ),
                    )
                      .then((res) => {
                        shiftGraphDataBar[widget.id].push(...(res && res[Object.keys(res)]) || []);
                      });
                    promises.push(promise);
                  } else {
                    const promise = getData({ ...this.getDatapointMeta(widget, shiftDaterangeBar), adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                      .then((res) => {
                        shiftGraphDataBar[widget.id].push(...(res && res[Object.keys(res)]) || []);
                      });
                    promises.push(promise);
                  }
                }
              }
            }

            if (widget.style.isCalculatedValueUsage && !daterange.selectedShiftDays) {
              const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
              const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
              const extraFields = {
                ...widget.style,
                vars: metricIds,
              };

              const isProductionData = calcValsDatapoints.every((value) => {
                return value.metric.data_storage === 'productiondata';
              });

              const promise = getCalculatedData({ ...this.getDatapointMeta(widget, daterange), isProductionData, extraFields, widgetTitle, datapointLabel: widget.label })
                .then((res) => {
                  barData[widget.id] = (res && res[Object.keys(res)]) || [];
                  this.setState({
                    data: { ...barData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            } else {
              // eslint-disable-next-line
              if (widget.metric.data_storage === 'productiondata' && !daterange.selectedShiftDays) {
                const promise = getProductionData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { where },
                    { shift },
                    { datapointLabel: widget.label },
                    { idx },
                    { globalFilters },
                    { workOperation },
                  ),
                )
                  .then((res) => {
                    barData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...barData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else if (widget.metric.data_storage === 'dynamic') {
                const promise = getTransformerData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { where },
                    { shift },
                    { datapointLabel: widget.label },
                    { idx },
                  ),
                )
                  .then((res) => {
                    barData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...barData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (!daterange.selectedShiftDays) {
                  const promise = getData({ ...this.getDatapointMeta(widget, daterange), adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                    .then((res) => {
                      barData[widget.id] = (res && res[Object.keys(res)]) || [];
                      this.setState({
                        data: { ...barData },
                        isLoading: false,
                      });
                    });
                  promises.push(promise);
                }
              }
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            if (shiftGraphDataBar && daterange && daterange.selectedShiftDays) {
              this.setState({
                data: { ...shiftGraphDataBar },
                isLoading: false,
              });
            }
            this.delayedFetchWidgetData();
          });
        break;
      case 'area':
        const areaData = data || {};
        const shiftGraphDataArea = {};
        let shiftDaterangeArea = {};
        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            if (!widget.metric && !widget.style.isCalculatedValueUsage) {
              // we skip datapoints with no metrics
              // but also skip calculated values datapoints
              // because we have one datapoint to connect them
              // one ring to rule them all
              return;
            }

            shiftGraphDataArea[widget.id] = [];
            if (daterange && daterange.selectedShiftDays) {
              for (let i = 0; i <= daterange.selectedShiftDays; i++) {
                const startDateTime = new Date(daterange.start);

                const endDateTime = new Date(daterange.end);

                const startDateShift = moment(startDateTime.setDate(startDateTime.getDate() + i));
                // eslint-disable-next-line no-mixed-operators
                const endDateShift = moment(endDateTime.setDate(endDateTime.getDate() - daterange.selectedShiftDays + i));

                shiftDaterangeArea = { start: startDateShift, end: endDateShift, label: 'custom' };
                if (widget.style.isCalculatedValueUsage) {
                  const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
                  const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
                  const extraFields = {
                    ...widget.style,
                    vars: metricIds,
                  };

                  const isProductionData = calcValsDatapoints.every((value) => {
                    return value.metric.data_storage === 'productiondata';
                  });

                  const promise = getCalculatedData({ ...this.getDatapointMeta(widget, shiftDaterangeArea), isProductionData, extraFields, widgetTitle, datapointLabel: widget.label })
                    .then((res) => {
                      shiftGraphDataArea[widget.id].push(...(res && res[Object.keys(res)]) || []);
                    });
                  promises.push(promise);
                } else {
                  // eslint-disable-next-line
                  if (widget.metric.data_storage === 'productiondata') {
                    const promise = getProductionData(
                      Object.assign(
                        this.getDatapointMeta(widget, shiftDaterangeArea),
                        { fetchEvents },
                        { adjusted_value },
                        { shift },
                        { where },
                        { idx },
                        { globalFilters },
                        { workOperation },
                      ),
                    )
                      .then((res) => {
                        shiftGraphDataArea[widget.id].push(...(res && res[Object.keys(res)]) || []);
                      });
                    promises.push(promise);
                  } else if (widget.metric.data_storage === 'dynamic') {
                    const promise = getTransformerData(
                      Object.assign(
                        this.getDatapointMeta(widget, shiftDaterangeArea),
                        { fetchEvents },
                        { adjusted_value },
                        { shift },
                        { where },
                        { idx },
                      ),
                    )
                      .then((res) => {
                        shiftGraphDataArea[widget.id].push(...(res && res[Object.keys(res)]) || []);
                      });
                    promises.push(promise);
                  } else {
                    const promise = getData({ ...this.getDatapointMeta(widget, shiftDaterangeArea), adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                      .then((res) => {
                        shiftGraphDataArea[widget.id].push(...(res && res[Object.keys(res)]) || []);
                      });
                    promises.push(promise);
                  }
                }
              }
            }

            if (widget.style.isCalculatedValueUsage && !daterange.selectedShiftDays) {
              const calcValsDatapoints = widgetData.filter((wD) => wD.style.isCalculatedValue);
              const metricIds = calcValsDatapoints.map((cvd) => { return { id: cvd.metric.id, name: cvd.style.alias }; });
              const extraFields = {
                ...widget.style,
                vars: metricIds,
              };

              const isProductionData = calcValsDatapoints.every((value) => {
                return value.metric.data_storage === 'productiondata';
              });

              const promise = getCalculatedData({ ...this.getDatapointMeta(widget, daterange), isProductionData, extraFields, widgetTitle, datapointLabel: widget.label })
                .then((res) => {
                  areaData[widget.id] = (res && res[Object.keys(res)]) || [];
                  this.setState({
                    data: { ...areaData },
                    isLoading: false,
                  });
                });
              promises.push(promise);
            } else {
              // eslint-disable-next-line
              if (widget.metric.data_storage === 'productiondata' && !daterange.selectedShiftDays) {
                const promise = getProductionData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { where },
                    { shift },
                    { datapointLabel: widget.label },
                    { idx },
                    { globalFilters },
                    { workOperation },
                  ),
                )
                  .then((res) => {
                    areaData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...areaData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else if (widget.metric.data_storage === 'dynamic') {
                const promise = getTransformerData(
                  Object.assign(
                    this.getDatapointMeta(widget, daterange),
                    { fetchEvents },
                    { widgetTitle },
                    { where },
                    { shift },
                    { datapointLabel: widget.label },
                    { idx },
                  ),
                )
                  .then((res) => {
                    areaData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...areaData },
                      isLoading: false,
                    });
                  });
                promises.push(promise);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (!daterange.selectedShiftDays) {
                  const promise = getData({ ...this.getDatapointMeta(widget, daterange), adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                    .then((res) => {
                      areaData[widget.id] = (res && res[Object.keys(res)]) || [];
                      this.setState({
                        data: { ...areaData },
                        isLoading: false,
                      });
                    });
                  promises.push(promise);
                }
              }
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            if (shiftGraphDataArea && daterange && daterange.selectedShiftDays) {
              this.setState({
                data: { ...shiftGraphDataArea },
                isLoading: false,
              });
            }
            this.delayedFetchWidgetData();
          });
        break;
      case 'orderstable':
        let assets_in = get(this, 'props.settings.assets_in', []);

        if (assets_in && assets_in.length) {
          assets_in = assets_in.map((obj) => obj.id);
        }

        if (assets_in.length) {
          getOrdersData({ ...this.getEventPointMeta(daterange), companyId, assets_in })
            .then((res) => {
              this.setState({
                data: res || [],
                isLoading: false,
              });
              this.delayedFetchWidgetData();
            });
        }

        break;
      case 'label':
        this.setState({
          isLoading: false,
        });
        break;
      case 'table':
        const tableWidgetDataItem = widgetData && widgetData[0];
        if (!tableWidgetDataItem || !tableWidgetDataItem.metric) {
          return;
        }
        getData({ ...this.getDatapointMeta(tableWidgetDataItem, daterange), adjusted_value, where, shift, productType })
          .then((res) => {
            // console.log(res);
            this.setState({
              data: (res && res[Object.keys(res)]) || [],
              isLoading: false,
            });
            this.delayedFetchWidgetData();
          });
        break;
      case 'tablerange':
        const graphDataTr = data || {};
        const shiftGraphDataTr = {};

        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            if (!widget.metric && !widget.style.isCalculatedValueUsage) {
              // we skip datapoints with no metrics
              // but also skip calculated values datapoints
              // because we have one datapoint to connect them
              // one ring to rule them all
              return;
            }

            // shiftGraphData[widget.id] = [];

            if (daterange && daterange.selectedShiftDays) {
              for (let i = 0; i <= daterange.selectedShiftDays; i++) {
                const startDateTime = new Date(daterange.start);

                const endDateTime = new Date(daterange.end);

                const startDateShift = moment(startDateTime.setDate(startDateTime.getDate() + i));
                // eslint-disable-next-line no-mixed-operators
                const endDateShift = moment(endDateTime.setDate(endDateTime.getDate() - daterange.selectedShiftDays + i));

                shiftDaterange = { start: startDateShift, end: endDateShift, label: 'custom' };
                const promise = getProductionData(
                  Object.assign(
                    this.getDatapointMeta(widget, shiftDaterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { shift },
                    { where },
                    { datapointLabel: widget.label },
                    { idx },
                    { globalFilters },
                  ),
                )
                  .then((res) => {
                    shiftGraphData[widget.id].push(...(res && res[Object.keys(res)]) || []);
                  });
                promises.push(promise);
              }
            }

            const promise = getProductionData(
              Object.assign(
                this.getDatapointMeta(widget, daterange),
                { fetchEvents },
                { adjusted_value },
                { widgetTitle },
                { shift },
                { where },
                { datapointLabel: widget.label },
                { idx },
                { globalFilters },
              ),
            )
              .then((res) => {
                graphDataTr[widget.id] = (res && res[Object.keys(res)]) || [];
                this.setState({
                  data: { ...graphDataTr },
                  isLoading: false,
                });
              });
            promises.push(promise);
          });
        }

        Promise.all(promises)
          .then(() => {
            if (shiftGraphDataTr && daterange && daterange.selectedShiftDays) {
              this.setState({
                data: { ...shiftGraphDataTr },
                isLoading: false,
              });
            }
            this.delayedFetchWidgetData();
          });

        break;
      case 'realtime':
        const realTimeData = data || {};
        const inactivity_after = get(this, 'props.settings.inactivity', 20);
        let realtime_daterange = {};

        realtime_daterange = {
          start: moment().subtract(inactivity_after, 'minutes'),
          end: moment(),
          label: 'custom',
        };

        if (widgetData) {
          widgetData.forEach((widget, idx) => {
            const metricCode = widgetData && widgetData[idx].metric && widgetData[idx].metric.code ? widgetData[idx].metric.code : null;

            if (metricCode !== 'status_realtime') {
              widget.group_by = '';
              widget.operation = 'raw';
              if (widget.metric.data_storage === 'productiondata') {
                const promise = getProductionData(
                  Object.assign(
                    this.getDatapointMeta(widget, realtime_daterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { shift },
                    { where },
                    { datapointLabel: widget.label },
                    { idx },
                    { globalFilters },
                  ),
                )
                  .then((res) => {
                    realTimeData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...realTimeData },
                      isLoading: false,
                      isFirstLoading: false,
                    });
                  });
                promises.push(promise);
              } else if (widget.metric.data_storage === 'dynamic') {
                const promise = getTransformerData(
                  Object.assign(
                    this.getDatapointMeta(widget, realtime_daterange),
                    { fetchEvents },
                    { adjusted_value },
                    { widgetTitle },
                    { productType },
                    { shift },
                    { where },
                    { datapointLabel: widget.label },
                    { idx },
                  ),
                )
                  .then((res) => {
                    realTimeData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...realTimeData },
                      isLoading: false,
                      isFirstLoading: false,
                    });
                  });
                promises.push(promise);
              } else {
                const promise = getData({ ...this.getDatapointMeta(widget, realtime_daterange), adjusted_value, where, shift, productType, widgetTitle, datapointLabel: widget.label, idx })
                  .then((res) => {
                    realTimeData[widget.id] = (res && res[Object.keys(res)]) || [];
                    this.setState({
                      data: { ...realTimeData },
                      isLoading: false,
                      isFirstLoading: false,
                    });
                  });
                promises.push(promise);
              }
            } else {
              this.setState({
                isLoading: false,
              });
            }
          });
        }

        Promise.all(promises)
          .then(() => {
            this.delayedFetchWidgetData();
          });
        break;
      default:
        return null;
    }
  }

  delayedFetchWidgetData = () => {
    const {
      type,
      meta: {
        interval,
      },
    } = this.props;

    if (type === 'realtime') {
      const url = new URL(window.location.href);
      if (!url.pathname.includes('/dashboards/')) {
        clearTimeout(this.widgetTimeout);
        return;
      }

      clearTimeout(this.widgetTimeout);

      this.widgetTimeout = setTimeout(() => {
        this.fetchWidgetData();
      }, 7 * 1000);
    } else {
      const url = new URL(window.location.href);
      if (!url.pathname.includes('/dashboards/')) {
        clearTimeout(this.widgetTimeout);
        return;
      }

      clearTimeout(this.widgetTimeout);

      if (interval === 0) {
        return;
      }

      this.widgetTimeout = setTimeout(() => {
        this.fetchWidgetData();
      }, interval * 1000);
    }
  }

  showTitlebar = () => {
    this.setState({
      overrideShowTitlebar: true,
    });
  }

  hideTitlebar = () => {
    this.setState({
      overrideShowTitlebar: false,
    });
  }

  // openCopyWidgetModal = () => {
  //   const {
  //     type,
  //     onCopy,
  //     dashboardId,
  //     id,
  //   } = this.props;

  //   if (type === 'sankey' || type === 'pie') {
  //     if (window.confirm('Are you sure you want to create a copy of this widget?') === true) {
  //       const settings = defaultSettings[type];
  //       onCopy({ dashboardId, widgetId: id, type, settings });
  //     } else {
  //       return '';
  //     }
  //   } else {
  //     this.setState({
  //       isCopyWidgetModalOpen: true,
  //     });
  //   }
  // }

  // closeCopyWidgetModal = () => {
  //   this.setState({
  //     isCopyWidgetModalOpen: false,
  //     errorType: false,
  //     selectedWidgetTypeCopy: null,
  //   });
  // }

  // widgetTypeChange = (e) => {
  //   this.setState({
  //     selectedWidgetTypeCopy: e.target.value,
  //     errorType: false,
  //   });
  // }

  handleShowConfirmationDialogCopy = () => {
    this.setState({ showConfirmationDialogCopy: true });
  }

  handleCopyWidgetSave = () => {
    const {
      onCopy,
      dashboardId,
      id,
      settings,
      type,
    } = this.props;

    onCopy({ dashboardId, widgetId: id, type, settings });
    this.setState({ showConfirmationDialogCopy: false });
  }

  handleShowConfirmationDialog = () => {
    this.setState({ showConfirmationDialog: true });
  }

  handleConfirmDelete = () => {
    const { onRemove, dashboardId, id } = this.props;
    onRemove({ dashboardId, widgetId: id });
    this.setState({ showConfirmationDialog: false });
  }

  renderChart() {
    const {
      type,
      height,
      size: {
        width,
      },
      xAxes,
      yAxes,
      meta,
      settings,
      widgetData,
      isResizing,
      setDateRange,
      company_short_code,
    } = this.props;

    const { isFirstLoading } = this.state;

    if (isResizing) {
      return (
        <WidgetPlaceholder
          height={height}
          type={type}
        />
      );
    }

    const { data, eventsData } = this.state;

    if (widgetData && widgetData.length) {
      const calculatedValueUsage = widgetData.find((wD) => wD.style.isCalculatedValueUsage);
      if (calculatedValueUsage) {
        const calculatedValues = widgetData.filter((wD) => wD.style.isCalculatedValue);
        if (calculatedValues && calculatedValues.length) {
          calculatedValueUsage.metric = calculatedValues[0].metric;
        }
      }
    }

    switch (type) {
      case 'line':
        return (
          <LineChart
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
            eventsData={eventsData}
          />
        );
      case 'step':
        return (
          <StepChart
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
            eventsData={eventsData}
          />
        );
      case 'scatter':
        return (
          <ScatterChart
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
            eventsData={eventsData}
          />
        );
      case 'composed':
        return (
          <ComposedChart
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
            eventsData={eventsData}
          />
        );
      case 'sankey':
        return (
          <SankeyChart
            data={data || {}}
            width={width}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
          />
        );
      case 'area':
        return (
          <AreaChart
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
          />
        );
      case 'percent':
        return (
          <PercentAreaChart
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
          />
        );
      case 'gauge':
        return (
          <Gauge
            data={data}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
          />
        );
      case 'histogram':
        return (
          <Histogram
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
          />
        );
      case 'gantt':
        return (
          <GanttChart
            data={data || {}}
            height={height}
            meta={meta}
            settings={settings}
            widgetData={widgetData}
            width={width}
            xAxes={xAxes}
            yAxes={yAxes}
            setDateRange={setDateRange}
            eventsData={eventsData}
          />
        );
      case 'map':
        return (
          <LocationMap
            data={data || {}}
            height={height}
            settings={settings}
            widgetData={widgetData}
            width={width}
          />
        );
      case 'pie':
        return (<PieChart
          data={data || {}}
          height={height}
          meta={meta}
          settings={settings}
          widgetData={widgetData}
          width={width}
          xAxes={xAxes}
          yAxes={yAxes}
        />);
      case 'bar':
        return (
          // eslint-disable-next-line react/no-unused-class-component-methods
          <div ref={(input) => { this.myInput = input; }}>
            <BarChart
              data={data || {}}
              height={height}
              meta={meta}
              settings={settings}
              widgetData={widgetData}
              width={width}
              xAxes={xAxes}
              yAxes={yAxes}
              setDateRange={setDateRange}
            />
          </div>
        );
      case 'scalar':
        return (
          <Scalar
            data={data}
            height={height}
            meta={meta}
            settings={settings}
            width={width}
            widgetData={widgetData}
            company_short_code={company_short_code}
          />
        );
      case 'table':
        return (
          <Table
            data={data}
            height={height}
            meta={meta}
            width={width}
            widgetData={widgetData}
            settings={settings}
          />
        );
      case 'tablerange':
        return (
          <TableRange
            data={data}
            widgetData={widgetData}
            height={height}
            meta={meta}
            width={width}
            settings={settings}
          />
        );
      case 'realtime':
        return (
          <RealtimeWidget
            data={data}
            height={height}
            meta={meta}
            width={width}
            widgetData={widgetData}
            settings={settings}
            isLoadingData={isFirstLoading}
          />
        );
      case 'orderstable':
        return (
          <OrdersTable
            data={data}
            height={height}
            meta={meta}
            width={width}
            widgetData={widgetData}
            settings={settings}
          />
        );
      case 'label':
        return (
          <Label
            height={height}
            width={width}
            settings={settings}
          />
        );
      default:
        return null;
    }
  }

  render() {
    const {
      id,
      isLocked,
      title,
      onRemove,
      onCopy,
      onEdit,
      settings,
      type,
      t,
    } = this.props;

    const { isLoading, overrideShowTitlebar, errorType } = this.state;

    const typeStyle = {};
    if (errorType) {
      typeStyle.borderColor = 'red';
    }

    let showTitlebar = true;
    if (settings && (type === 'scalar' || type === 'gauge') && Object.prototype.hasOwnProperty.call(settings, 'showTitlebar')) {
      showTitlebar = settings.showTitlebar;
    }
    if (overrideShowTitlebar) {
      showTitlebar = overrideShowTitlebar;
    }

    return (
      <div
        key={id}
        id={id}
        data-title={title}
        className="widget-container"
        onMouseEnter={this.showTitlebar}
        onMouseLeave={this.hideTitlebar}
        ref={this.widgetRef}
      >
        {/* <Modal
          isOpen={isCopyWidgetModalOpen}
          handleSave={this.handleCopyWidgetSave}
          handleClose={this.closeCopyWidgetModal}
          title={t('page_content.dashboards.copy_widget_modal.title')}
        >
          <div className="default-form">
            <table>
              <tbody>
                <tr>
                  <td className="label">
                    {t('page_content.dashboards.copy_widget_modal.widget_type')}
                  </td>
                  <td className="value">

                    {
                      (type === 'gauge' || type === 'scalar' || type === 'table') ?
                        <select onChange={this.widgetTypeChange} value={selectedWidgetTypeCopy} style={typeStyle}>
                          <option value="" selected disabled>{t('page_content.dashboards.copy_widget_modal.widget_type_placeholder')}</option>
                          <option value="gauge">Gauge</option>
                          <option value="scalar">Scalar</option>
                          <option value="table">Table</option>
                        </select> :
                        <select onChange={this.widgetTypeChange} value={selectedWidgetTypeCopy} style={typeStyle}>
                          <option value="" selected disabled>{t('page_content.dashboards.copy_widget_modal.widget_type_placeholder')}</option>
                          <option value="line">Line</option>
                          <option value="gantt">Gantt</option>
                          <option value="scatter">Scatter</option>
                          <option value="step">Step</option>
                          <option value="percent">Percent</option>
                          <option value="area">Area</option>
                          <option value="bar">Bar</option>
                          <option value="histogram">Histogram</option>
                        </select>
                    }
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Modal> */}
        {
          showTitlebar ?
            <header className={`widget-header ${isLocked ? '' : 'editable'}`}>
              <div
                id={settings && settings.appearance && settings.appearance.title && settings.appearance.title === 'right' ? 'right' : ''}
                className={`title ${overrideShowTitlebar ? 'hovered' : ''}`}
                style={{
                  textAlign: settings && settings.appearance && settings.appearance.title ? settings.appearance.title : 'left',
                  // marginLeft: settings && settings.appearance && settings.appearance.title && settings.appearance.title === 'center' ? '75px' : '',
                  // marginRight: settings && settings.appearance && settings.appearance.title && settings.appearance.title === 'right' ? '-75px' : '',
                  color: settings && settings.defaultTextColor ? settings.defaultTextColor : '#555',
                }}
              >
                {
                  isLoading &&
                  <div
                    className="loader"
                    style={{
                      border: '1px solid #aaa',
                      borderLeftColor: 'transparent',
                      height: '10px',
                      width: '10px',
                    }}
                  />
                }
                {this.getWidgetTitle()}
              </div>
              {
                !isLocked &&
                <div className={`controls ${overrideShowTitlebar ? 'hovered' : ''}`}>
                  {
                    typeof onRemove === typeof Function &&
                    <button
                      className="remove-btn"
                      onClick={this.handleShowConfirmationDialog}
                    >
                      <IconRemove
                        color="#364252"
                        height="14px"
                        width="14px"
                      />
                    </button>
                  }
                  {
                    typeof onCopy === typeof Function &&
                    <button
                      className="remove-btn"
                      onClick={this.handleShowConfirmationDialogCopy}
                    // onClick={this.openCopyWidgetModal}
                    >
                      <img src={copyImage} alt="+" style={{ height: '17px', width: '16px', marginBottom: '-1px' }} />
                    </button>
                  }
                  <button
                    className="edit-btn"
                    onClick={() => onEdit(id)}
                  >
                    <IconSettings
                      height="14px"
                      width="14px"
                    />
                  </button>
                </div>
              }
            </header> : ''
        }
        <ConfirmationModal
          customTitle={t('page_content.dashboards.delete_message')}
          showModal={this.state.showConfirmationDialog}
          handleCloseModal={() => this.setState({ showConfirmationDialog: false })}
          handleConfirmModal={this.handleConfirmDelete}
          type="warning"
        />
        <ConfirmationModal
          customTitle={t('page_content.dashboards.copy_message')}
          showModal={this.state.showConfirmationDialogCopy}
          handleCloseModal={() => this.setState({ showConfirmationDialogCopy: false })}
          handleConfirmModal={this.handleCopyWidgetSave}
        />
        {this.renderChart()}
      </div>
    );
  }
}

Widget.propTypes = {
  data: PropTypes.array,
  height: PropTypes.number.isRequired,
  title: PropTypes.string,
  type: PropTypes.oneOf(['line', 'sankey', 'area', 'bar', 'gauge', 'pie', 'map', 'gantt', 'percent', 'scalar', 'table', 'tablerange', 'realtime', 'orderstable', 'label', 'composed', 'histogram', 'scatter', 'step']).isRequired,
  xAxes: PropTypes.array,
  yAxes: PropTypes.array,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  isResizing: PropTypes.bool,
  setDateRange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLocked: PropTypes.bool,
  dashboardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  size: PropTypes.object.isRequired,
  settings: PropTypes.object,
  updateManualRefresh: PropTypes.func.isRequired,
  manualRefresh: PropTypes.any,
  meta: PropTypes.object,
  widgetData: PropTypes.any,
  globalFilters: PropTypes.object,
  company_short_code: PropTypes.any,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  widget_data: PropTypes.any,
  t: PropTypes.func,
};

Widget.defaultProps = {
  height: 90,
  width: 100,
  type: null,
  onRemove: () => { },
  onCopy: () => { },
  onEdit: () => { },
  meta: {
    interval: 5,
  },
  isResizing: false,
  setDateRange: () => { },
};

export default withTranslation()(Widget);
