import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import moment from 'moment';

import {
  ComposedChart as ReComposedChart,
  ReferenceArea,
  Tooltip,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Label,
  Area,
  Bar,
  Line,
  ReferenceLine,
  ReferenceDot,
} from 'recharts';
import { formatTypes } from '../../../constants';
import {
  generateTicks,
  formatTooltipDates,
  formatLabel,
  formatTicks,
  formatTooltip,
  domainGenerator,
} from '../helpers';

import { timeRangeEnum, timeRangeConfig } from '../../../DatePicker/constants';

const colorIndex = [
  '#1abc9c',
  '#2980b9',
  '#e67e22',
  '#e74c3c',
  '#9b59b6',
  '#2ecc71',
  '#34495e',
  '#8e44ad',
  '#2c3e50',
  '#7f8c8d',
  '#c0392b',
  '#d35400',
  '#f39c12',
];

let offsetData = [];

class ComposedChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refAreaLeft: '',
      refAreaRight: '',
      selectedId: null,
    };

    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  selectedLabel = (id) => {
    if (this.state.selectedId) {
      if (this.state.selectedId === id) {
        return false;
      }
      return true;
    }
    return false;
  };

  handleClick = (id) => {
    if (this.state.selectedId && this.state.selectedId === id) {
      this.setState({
        selectedId: null,
      });
    } else {
      this.setState({
        selectedId: id,
      });
    }
  }

  legendRender = (props) => {
    // eslint-disable-next-line
    const { payload } = props;
    return (
      <ul className="recharts-default-legend" style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
        {
          payload.map((entry, index) => (
            // <li key={`item-${index}`}>{entry.value}</li>

            <li key={`item-${index}`} className="recharts-legend-item legend-item-0" style={{ display: 'inline-block', marginRight: '10px', cursor: 'pointer' }} onClick={() => this.handleClick(entry.dataKey)}>
              <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
                <path stroke="none" fill={this.state.selectedId ? this.state.selectedId === entry.dataKey ? entry.color : 'gray' : entry.color} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
              </svg>
              <span className="recharts-legend-item-text">{entry.value}</span>
            </li>
          ))
        }
      </ul>
    );
  };

  prepareData(data = {}, widgetData = []) {
    const newData = [];

    Object.keys(data)
      .filter((key) => key !== 'events')
      .forEach((key) => {
        const currentWidgetData = widgetData.find((x) => String(x.id) === key);
        let valueKey = 'value';

        if (currentWidgetData) {
          const {
            style: {
              selectedField,
            },
          } = currentWidgetData;

          valueKey = selectedField || 'value';
        }

        data[key]
          .forEach((entry) => {
            let { time: timeString } = entry;
            if (!timeString) {
              timeString = entry.ts;
            }
            const timeValue = moment(timeString).valueOf();
            const value = entry[valueKey];

            const findIndex = newData.findIndex((x) => x.time === timeValue);
            if (findIndex < 0) {
              newData.push({ time: timeValue, [key]: value });
            } else {
              newData[findIndex][key] = value;
            }
          });
      });

    newData.sort((a, b) => a.time - b.time);

    return newData;
  }

  zoom = () => {
    let { refAreaLeft, refAreaRight } = this.state;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    this.props.setDateRange({
      label: 'custom',
      start: moment(refAreaLeft),
      end: moment(refAreaRight),
    });

    this.setState({
      refAreaLeft: '',
      refAreaRight: '',
    });
  }

  handleMouseMove = throttle((e) => {
    if (!e) {
      return;
    }
    const { refAreaLeft } = this.state;
    if (refAreaLeft) {
      this.setState({ refAreaRight: e.activeLabel });
    }
  }, 30);

  renderEventReferences = (eventsData = {}, yAxisId) => {
    const res = [];

    const resetEventDetails = (e, entry, formattedTime) => {
      const group = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}`);
      const text = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}-text`);
      if (group && text) {
        if (group.x === e.cx) {
          group.setAttribute('x', 1000);
          group.setAttribute('y', 1000);
          text.setAttribute('x', 1000);
          text.setAttribute('y', 1000);
          text.setAttribute('fill', 'transparent');
        } else {
          group.setAttribute('x', e.cx);
          group.setAttribute('y', e.cy);
          text.setAttribute('x', e.cx);
          text.setAttribute('y', e.cy);
          text.setAttribute('fill', '#000000');
        }
      }
    };

    Object.keys(eventsData).forEach((metricId, index) => {
      eventsData[metricId].forEach((entry) => {
        const formattedTime = moment(entry.time).valueOf();
        let color = colorIndex[index % (colorIndex.length - 1)];
        if (entry.log_level === 'warning') {
          color = 'yellow';
        } else if (entry.log_level === 'error') {
          color = 'red';
        }
        res.push(<ReferenceDot
          yAxisId={yAxisId}
          x={formattedTime}
          y={entry.threshold || 0}
          isFront
          stroke="none"
          fill={color}
          label={
            <g id={`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}`} x={1000} y={1000} onClick={(e) => resetEventDetails(e, entry, formattedTime)}>
              <text fontWeight="bold" fontSize="13" fill="transparent" id={`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}-text`} textAnchor="middle" dominantBaseline="central">{entry.value}</text>
            </g>
          }
          onClick={(e) => {
            const group = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}`);
            const text = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}-text`);
            if (group.x === e.cx) {
              group.setAttribute('x', 1000);
              group.setAttribute('y', 1000);
              text.setAttribute('x', 1000);
              text.setAttribute('y', 1000);
              text.setAttribute('fill', 'transparent');
            } else {
              group.setAttribute('x', e.cx);
              group.setAttribute('y', e.cy);
              text.setAttribute('x', e.cx);
              text.setAttribute('y', e.cy);
              text.setAttribute('fill', '#000000');
            }
          }}
        />);
      });
    });
    return res;
  }

  render() {
    const {
      refAreaLeft,
      refAreaRight,
    } = this.state;

    const {
      height,
      data,
      settings,
      width,
      widgetData,
      enableZoom,
      eventsData,
      meta: {
        format,
        daterange: {
          start,
          end,
          label,
        },
        range,
        unit,
      },
    } = this.props;

    if (!settings) {
      return <p>Composed chart</p>;
    }

    let fetchEvents = null;
    if (settings.legend && settings.legend.events) {
      fetchEvents = settings.legend.events;
    }
    let minValue = null;
    let maxValue = null;

    const graphData = this.prepareData(data, widgetData);

    if (settings && settings.axis) {
      const axis = settings.axis;

      if (graphData && settings && settings.axis && settings.axis.addOffset) {
        const widgetsId = widgetData.filter((wD) => !wD.style.isCalculatedValue).map((element) => element.id);
        if (widgetsId.length === 1) {
          for (let i = 0; i < widgetsId.length; i++) {
            const widgetId = widgetsId[i];
            const result = graphData.map((value) => value[widgetId]);
            const min = Math.min(...result);
            const decimalPlaces = settings.axis.y1.decimal_places ? settings.axis.y1.decimal_places : 2;
            offsetData = graphData.map((o) => ({ ...o, [widgetId]: parseFloat((o[widgetId] - min).toFixed(decimalPlaces)) }));
          }
        }
        if (widgetsId.length === 2) {
          const widgetId0 = widgetsId[0];
          const result0 = graphData.map((value) => value[widgetId0]);
          const min0 = Math.min(...result0);
          const widgetId1 = widgetsId[1];
          const result1 = graphData.map((value) => value[widgetId1]);
          const min1 = Math.min(...result1);
          const decimalPlaces = settings.axis.y1.decimal_places ? settings.axis.y1.decimal_places : 2;
          offsetData = graphData.map((o) => ({ ...o, [widgetId0]: parseFloat((o[widgetId0] - min0).toFixed(decimalPlaces)), [widgetId1]: parseFloat((o[widgetId1] - min1).toFixed(decimalPlaces)) }));
        }
        if (widgetsId.length === 3) {
          const widgetId0 = widgetsId[0];
          const result0 = graphData.map((value) => value[widgetId0]);
          const min0 = Math.min(...result0);
          const widgetId1 = widgetsId[1];
          const result1 = graphData.map((value) => value[widgetId1]);
          const min1 = Math.min(...result1);
          const widgetId2 = widgetsId[2];
          const result2 = graphData.map((value) => value[widgetId2]);
          const min2 = Math.min(...result2);
          const decimalPlaces = settings.axis.y1.decimal_places ? settings.axis.y1.decimal_places : 2;
          offsetData = graphData.map((o) => ({ ...o, [widgetId0]: parseFloat((o[widgetId0] - min0).toFixed(decimalPlaces)), [widgetId1]: parseFloat((o[widgetId1] - min1).toFixed(decimalPlaces)), [widgetId2]: parseFloat((o[widgetId2] - min2).toFixed(decimalPlaces)) }));
        }
      }

      if (axis.y1 && axis.y1.max !== null && axis.y1.min !== null) {
        if (axis.y1.min % 1 === 0) {
          minValue = parseInt(axis.y1.min, 10);
          maxValue = parseInt(axis.y1.max, 10);
        } else {
          minValue = parseFloat(axis.y1.min);
          maxValue = parseFloat(axis.y1.max);
        }
      }

      if (axis.y && axis.y.format === 'float') {
        if (widgetData) {
          for (let j = 0; j < widgetData.length; j += 1) {
            if (widgetData[j] && widgetData[j].id) {
              const key = widgetData[j].id;
              const decimalPlaces = settings.axis.y.decimal_places ? settings.axis.y.decimal_places : 2;
              if (decimalPlaces) {
                for (let i = 0; i < graphData.length; i += 1) {
                  if (graphData[i][key]) {
                    graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
                  }
                }
              }
            }
          }
        }
      } else if (axis.y1 && axis.y1.format === 'float') {
        if (widgetData) {
          for (let j = 0; j < widgetData.length; j += 1) {
            if (widgetData[j] && widgetData[j].id) {
              const key = widgetData[j].id;
              const decimalPlaces = settings.axis.y1.decimal_places ? settings.axis.y1.decimal_places : 2;
              if (decimalPlaces) {
                for (let i = 0; i < graphData.length; i += 1) {
                  if (graphData[i][key]) {
                    graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
                  }
                }
              }
            }
          }
        }
      } else if (axis.y2 && axis.y2.format === 'float') {
        if (widgetData) {
          for (let j = 0; j < widgetData.length; j += 1) {
            if (widgetData[j] && widgetData[j].id) {
              const key = widgetData[j].id;
              const decimalPlaces = settings.axis.y2.decimal_places ? settings.axis.y2.decimal_places : 2;
              if (decimalPlaces) {
                for (let i = 0; i < graphData.length; i += 1) {
                  if (graphData[i][key]) {
                    graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
                  }
                }
              }
            }
          }
        }
      } else if (axis.y3 && axis.y3.format === 'float') {
        if (widgetData) {
          for (let j = 0; j < widgetData.length; j += 1) {
            if (widgetData[j] && widgetData[j].id) {
              const key = widgetData[j].id;
              const decimalPlaces = settings.axis.y3.decimal_places ? settings.axis.y3.decimal_places : 2;
              if (decimalPlaces) {
                for (let i = 0; i < graphData.length; i += 1) {
                  if (graphData[i][key]) {
                    graphData[i][key] = parseFloat(graphData[i][key].toFixed(decimalPlaces));
                  }
                }
              }
            }
          }
        }
      }
    }

    if (settings.annotation && settings.annotation.value) {
      for (let i = 0; i < graphData.length; i += 1) {
        graphData[i].annotationValue = parseFloat(settings.annotation.value, 10);
      }
    }

    let to = end;
    let from = start;
    const rangeLabel = label;

    if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
      to = timeRangeConfig()[rangeLabel].endDate;
      from = timeRangeConfig()[rangeLabel].startDate;
    }

    const timeRangeStart = from.valueOf();
    const timeRangeEnd = to.valueOf();
    const timeRange = range
      ? [range[0].valueOf(), range[1].valueOf()]
      : [timeRangeStart, timeRangeEnd];

    let leftYAxisId;
    let annotationYAxisId;

    const valueTypeYAxisMapping = {};

    return (
      <ReComposedChart
        data={(settings && settings.axis && settings.axis.addOffset) ? offsetData : graphData}
        height={height}
        margin={{ top: 25, right: 40, left: 5, bottom: 5 }}
        syncId="shared"
        syncMethod="value"
        width={width}
        onMouseDown={enableZoom && ((e) => {
          this.setState({ refAreaLeft: e.activeLabel });
        })}
        onMouseMove={enableZoom && this.handleMouseMove}
        onMouseUp={enableZoom && this.zoom}
        style={{ userSelect: 'none' }}
      >
        {
          (settings && settings.axis && settings.axis.x && settings.axis.x.show) &&
          <XAxis
            dataKey="time"
            domain={timeRange}
            type="number"
            tickFormatter={(tick) => formatTicks(tick, formatTypes.date, { dateFrom: timeRange[0], dateTo: timeRange[1] })}
            tick={{ fontSize: 10 }}
            ticks={generateTicks(timeRangeStart, timeRangeEnd, width)}
            name=""
            scale="time"
            allowDataOverflow
            interval="preserveStartEnd"
          />
        }
        {
          widgetData.filter((wD) => !wD.style.isCalculatedValue).map(({
            scale,
            type,
            metric,
          }, index) => {
            if (!metric) return '';
            // axes settings are for left and right orientitation
            // y1 === left
            // y2 === right
            const currentAxis = settings.axis.y1;

            if (metric && metric.value_type) {
              if (!Object.prototype.hasOwnProperty.call(valueTypeYAxisMapping, metric.value_type.id)) {
                valueTypeYAxisMapping[metric.value_type.id] = index;
              }
            }

            if (metric && metric.data_storage === 'productiondata') {
              annotationYAxisId = 'yAxis-productiondata';
              return (
                <YAxis
                  hide={!currentAxis.show}
                  domain={minValue !== null && maxValue !== null ? [minValue, maxValue] : currentAxis.domain || domainGenerator(settings.axis.addOffset ? offsetData : graphData)}
                  key={`yAxis-${index}`}
                  yAxisId="yAxis-productiondata"
                  name={currentAxis.label}
                  orientation={settings.axis.side ? settings.axis.side : 'left'}
                  scale={scale || 'linear'}
                  tick={{ fontSize: 10 }}
                  tickFormatter={(value) => formatTicks(value, currentAxis.format || format, null, settings.axis.y1.decimal_places || 2)}
                  type={type || 'number'}
                  allowDataOverflow={
                    // eslint-disable-next-line no-unneeded-ternary
                    minValue !== null && maxValue !== null ? true : false
                  }
                  width={90}
                >
                  <Label
                    angle={-90}
                    fontSize="12px"
                    position={settings.axis.side ? (settings.axis.side === 'left' ? 'insideLeft' : 'insideRight') : 'insideLeft'}
                    fill="#777"
                    style={{ textAnchor: 'middle' }}
                    value={currentAxis.label || (metric && metric.value_type && metric.value_type.unit)}
                  />
                </YAxis>
              );
            }

            if (metric && metric.value_type && valueTypeYAxisMapping[metric.value_type.id] !== index) {
              return '';
            }

            leftYAxisId = `yAxis-${index}`;
            annotationYAxisId = `yAxis-${index}`;

            if (Object.keys(valueTypeYAxisMapping).length > 1 && settings && settings.axis && settings.axis.oneYAxis) {
              return (
                <YAxis
                  hide={!currentAxis.show}
                  domain={(minValue !== null && maxValue !== null) ? [minValue, maxValue] : currentAxis.domain || domainGenerator(settings.axis.addOffset ? offsetData : graphData)}
                  key={`yAxis-${index}`}
                  yAxisId={`yAxis-${index}`}
                  name={currentAxis.label}
                  orientation={settings.axis.side ? settings.axis.side : 'left'}
                  scale={scale || 'linear'}
                  tick={{ fontSize: 10 }}
                  tickFormatter={(value) => formatTicks(value, currentAxis.format || format, null, settings.axis.y1.decimal_places || 2)}
                  type={type || 'number'}
                  allowDataOverflow={
                    // eslint-disable-next-line no-unneeded-ternary
                    (minValue !== null && maxValue !== null) ? true : false
                  }
                  width={90}
                >
                  <Label
                    angle={-90}
                    fontSize="12px"
                    position={settings.axis.side ? (settings.axis.side === 'left' ? 'insideLeft' : 'insideRight') : 'insideLeft'}
                    fill="#777"
                    style={{ textAnchor: 'middle' }}
                    value={currentAxis.label || (metric && metric.value_type && metric.value_type.unit)}
                  />
                </YAxis>
              );
            }

            const yAxisIDPart = metric ? valueTypeYAxisMapping[metric.value_type.id] : null;
            annotationYAxisId = `yAxis-${yAxisIDPart}`;

            return (
              <YAxis
                hide={!currentAxis.show}
                domain={(minValue !== null && maxValue !== null) ? [minValue, maxValue] : currentAxis.domain || domainGenerator(settings.axis.addOffset ? offsetData : graphData)}
                key={`yAxis-${yAxisIDPart}`}
                yAxisId={`yAxis-${yAxisIDPart}`}
                name={currentAxis.label}
                orientation={settings.axis.side ? settings.axis.side : 'left'}
                scale={scale || 'linear'}
                tick={{ fontSize: 10 }}
                tickFormatter={(value) => formatTicks(value, currentAxis.format || format, null, settings.axis.y1.decimal_places || 2)}
                type={type || 'number'}
                allowDataOverflow={
                  // eslint-disable-next-line no-unneeded-ternary
                  (minValue !== null && maxValue !== null) ? true : false
                }
                width={90}
              >
                <Label
                  angle={-90}
                  fontSize="12px"
                  position={settings.axis.side ? (settings.axis.side === 'left' ? 'insideLeft' : 'insideRight') : 'insideLeft'}
                  fill="#777"
                  style={{ textAnchor: 'middle' }}
                  value={currentAxis.label || (metric && metric.value_type && metric.value_type.unit)}
                />
              </YAxis>
            );
          })
        }
        {
          (settings && settings.appearance && settings.appearance.grid) &&
          <CartesianGrid
            strokeDasharray="5 5"
          />
        }
        {
          (refAreaLeft && refAreaRight) ?
            <YAxis
              hide
              key="yAxis-refarea"
              yAxisId="yAxis-refarea"
              name=""
              orientation={settings.axis.side ? settings.axis.side : 'left'}
              tick={{ fontSize: 10 }}
              allowDataOverflow={
                // eslint-disable-next-line no-unneeded-ternary
                (minValue !== null && maxValue !== null) ? true : false
              }
              width={90}
            /> : ''
        }
        {
          (settings && settings.appearance && settings.appearance.tooltip) &&
          <Tooltip
            contentStyle={{ fontSize: 12 }}
            formatter={(value) => `${formatTooltip(value)}${unit || ''}`}
            labelFormatter={(l) => formatTooltipDates(l, { dateFrom: timeRange[0], dateTo: timeRange[1] })}
            labelStyle={{ fontSize: 12 }}
          />
        }
        {
          (settings && settings.legend && settings.legend.show) &&
          <Legend
            content={this.legendRender}
          />
        }
        {
          widgetData.sort((a, b) => { return a.id - b.id; }).filter((wD) => !wD.style.isCalculatedValue).map(({
            label: widgetLabel,
            style,
            metric,
            id,
          }, index) => {
            if (!metric) return '';
            const lineStyle = style || {};

            const {
              lineType,
            } = lineStyle;

            if (metric && metric.data_storage === 'productiondata') {
              if (style && style.datapointType === 'area') {
                return (
                  <Area
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dataKey={id}
                    yAxisId="yAxis-productiondata"
                    stroke={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    fill={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    fillOpacity={(style && style.color && style.color.a) ? style.color.a : 0.5}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    hide={!!this.selectedLabel(id)}
                  />
                );
              }
              if (style && style.datapointType === 'bar') {
                return (
                  <Bar
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dataKey={id}
                    stackId="data"
                    yAxisId="yAxis-productiondata"
                    fill={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    fillOpacity={(style && style.color && style.color.a) ? style.color.a : 1}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    hide={!!this.selectedLabel(id)}
                  />);
              }
              if (style && style.datapointType === 'line') {
                return (
                  <Line
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dot={false}
                    yAxisId="yAxis-productiondata"
                    type="linear"
                    dataKey={id}
                    connectNulls
                    stroke={(style && style.color && style.color.r && style.color.g && style.color.b && style.color.a) ? `rgba(${style.color.r}, ${style.color.g}, ${style.color.b}, ${style.color.a})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    activeDot={{ r: 5 }}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    strokeDasharray={lineType === 'dashed' ? '1 1' : null}
                    hide={!!this.selectedLabel(id)}
                  />);
              }
              if (!style.datapointType) {
                return (
                  <Line
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dot={false}
                    yAxisId="yAxis-productiondata"
                    type="linear"
                    dataKey={id}
                    connectNulls
                    stroke={(style && style.color && style.color.r && style.color.g && style.color.b && style.color.a) ? `rgba(${style.color.r}, ${style.color.g}, ${style.color.b}, ${style.color.a})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    activeDot={{ r: 5 }}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    strokeDasharray={lineType === 'dashed' ? '1 1' : null}
                    hide={!!this.selectedLabel(id)}
                  />);
              }
            }

            if (metric && metric.value_type) {
              if (Object.prototype.hasOwnProperty.call(valueTypeYAxisMapping, metric.value_type.id)) {
                leftYAxisId = `yAxis-${valueTypeYAxisMapping[metric.value_type.id]}`;
              }
            } else {
              leftYAxisId = `yAxis-${index}`;
            }

            if (Object.keys(valueTypeYAxisMapping).length > 1 && settings && settings.axis && settings.axis.oneYAxis) {
              leftYAxisId = 'yAxis-0';
              if (style && style.datapointType === 'area') {
                return (
                  <Area
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dataKey={id}
                    yAxisId={leftYAxisId}
                    stroke={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    fill={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    fillOpacity={(style && style.color && style.color.a) ? style.color.a : 0.5}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    hide={!!this.selectedLabel(id)}
                  />
                );
              }
              if (style && style.datapointType === 'bar') {
                return (
                  <Bar
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dataKey={id}
                    stackId="data"
                    yAxisId={leftYAxisId}
                    fill={(style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    fillOpacity={(style && style.color && style.color.a) ? style.color.a : 1}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    hide={!!this.selectedLabel(id)}
                  />);
              }
              if (style && style.datapointType === 'line') {
                return (
                  <Line
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dot={false}
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={id}
                    connectNulls
                    stroke={(style && style.color && style.color.r && style.color.g && style.color.b && style.color.a) ? `rgba(${style.color.r}, ${style.color.g}, ${style.color.b}, ${style.color.a})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    activeDot={{ r: 5 }}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    strokeDasharray={lineType === 'dashed' ? '1 1' : null}
                    hide={!!this.selectedLabel(id)}
                  />);
              }
              if (!style.datapointType) {
                return (
                  <Line
                    key={`line-widget-data-${id}`}
                    isAnimationActive={false}
                    dot={false}
                    yAxisId={leftYAxisId}
                    type="linear"
                    dataKey={id}
                    connectNulls
                    stroke={(style && style.color && style.color.r && style.color.g && style.color.b && style.color.a) ? `rgba(${style.color.r}, ${style.color.g}, ${style.color.b}, ${style.color.a})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                    activeDot={{ r: 5 }}
                    legendType="rect"
                    name={widgetLabel || formatLabel(metric)}
                    strokeDasharray={lineType === 'dashed' ? '1 1' : null}
                    hide={!!this.selectedLabel(id)}
                  />);
              }
            }

            return (
              <Line
                key={`line-widget-data-${id}`}
                isAnimationActive={false}
                dot={false}
                yAxisId={leftYAxisId}
                type="linear"
                dataKey={id}
                connectNulls
                stroke={(style && style.color && style.color.r && style.color.g && style.color.b && style.color.a) ? `rgba(${style.color.r}, ${style.color.g}, ${style.color.b}, ${style.color.a})` : style && style.isCalculatedValueUsage ? '#2ecc71' : colorIndex[index]}
                activeDot={{ r: 5 }}
                legendType="rect"
                name={widgetLabel || formatLabel(metric)}
                strokeDasharray={lineType === 'dashed' ? '1 1' : null}
                hide={!!this.selectedLabel(id)}
              />
            );
          })
        }
        {
          settings && settings.annotation && settings.annotation.value && annotationYAxisId ?
            <ReferenceLine
              key="line-widget-data-annotation"
              yAxisId={annotationYAxisId}
              y={parseFloat(settings.annotation.value)}
              stroke={settings.annotation.color || '#3182bd'}
              strokeWidth={settings.annotation.thickness || 1}
              strokeDasharray={settings.annotation.dashed ? '5 5' : '10000'}
            >
              <Label value={settings.annotation.value || 'Threshold'} position="right" style={{ fontSize: 11 }} fill="#666" />
            </ReferenceLine> : ''
        }
        {
          (refAreaLeft && refAreaRight)
            ? (
              <ReferenceArea
                yAxisId="yAxis-refarea"
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            )
            : null
        }
        {
          fetchEvents &&
          this.renderEventReferences(eventsData, leftYAxisId)
        }
      </ReComposedChart>
    );
  }
}

ComposedChart.defaultProps = {
  data: {},
  widgetData: [],
  setDateRange: () => { },
  enableZoom: true,
  settings: {},
};

ComposedChart.propTypes = {
  data: PropTypes.object,
  height: PropTypes.number,
  width: PropTypes.number,
  widgetData: PropTypes.array,
  setDateRange: PropTypes.func.isRequired,
  settings: PropTypes.object,
  meta: PropTypes.object.isRequired,
  enableZoom: PropTypes.bool,
  eventsData: PropTypes.object.isRequired,
};

export default ComposedChart;
