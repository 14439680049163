import api from 'helpers/api';
import { Notification } from 'shared';

export const getAssets = async (companyId, locationId) => {
  try {
    const res = await api
      .get(`/api/v1/industry/assets/?company=${companyId}&location=${locationId}&fat=1`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching assets', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getShifts = async (locationId) => {
  try {
    const res = await api
      .get(`/api/v1/shifts/shifts/?location=${locationId}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching shifts', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getTimetable = async (locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/shifts/timetable/?location=${locationId}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data || (Array.isArray(res.data)) === false) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching timetable', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getShiftTimes = async (locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/shifts/shift_times/?location=${locationId}&order_by=-date${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching shift timings', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
export const saveShiftTime = (data, locationId) => {
  return api.post(`/api/v1/shifts/shift_times/?location=${locationId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Shift Time was successfully created.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
export const saveShiftTimeWithPromise = (data) => {
  return new Promise((resolve, reject) => {
    api.post(`/api/v1/shifts/shift_times/?location=${data.location}`, data)
      .then((res) => {
        if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
          throw new Error('');
        }
        Notification('success', 'Save successful', 'Shift Time was successfully created.');
        resolve();
      })
      .catch((error) => {
        Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
        reject();
      });
  });
};
export const editShiftTime = (id, data, locationId) => {
  return api.put(`/api/v1/shifts/shift_times/${id}/?location=${locationId}`, data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Save successful', 'Shift Time was successfully updated.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};
export const deleteShiftTime = (id, locationId) => {
  return api.delete(`/api/v1/shifts/shift_times/${id}/?location=${locationId}`)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      } return Notification('success', 'Delete successful', 'Shift was successfuly deleted.');
    })
    .catch((error) => {
      return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
    });
};

export const deleteDefaultShiftTime = (data, returnNotification = true) => {
  return api.post('/api/v1/shifts/delete_default/', data)
    .then((res) => {
      if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500) {
        throw new Error('');
      }

      if (returnNotification) {
        return Notification('success', 'Save successful', 'Shift Time was successfully deleted.');
      }
    })
    .catch((error) => {
      if (returnNotification) {
        return Notification('error', 'An error occurred', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
      }
    });
};
