import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { IconRemove, IconEdit } from 'shared/Icons';
import { Table, TableButtons, Button, ConfirmationModal } from 'shared';

import { deleteQualification, getQualifications, getPaginatedQualifications } from '../../../actions';
import '../../../styles.scss';

import QualificationsModal from './QualificationsModal';

const QualificationsTable = ({ t, companyId, userHaveFullAccess }) => {
  const [qualifications, setQualifications] = useState({
    data: [],
    next: null,
    previous: null,
    count: null,
    isLoading: true,
  });
  const [qualificationsFilters, setQualificationsFilter] = useState({
    selectedAscDesc: 'asc',
    selectedSort: 'name',
  });

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);
  const [modalData, setModalData] = useState({
    isOpen: false,
    selectedItem: null,
  });

  const fetchQualifications = () => {
    setQualifications((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = qualificationsFilters?.selectedAscDesc === 'desc' ? '-' : '';
    const apiFilters = `&limit=15&order_by=${asc}${qualificationsFilters?.selectedSort}`;

    getQualifications(companyId, apiFilters)
      .then((res) => {
        setQualifications({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setQualifications((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  const fetchPaginatedQualifications = (url) => {
    setQualifications((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    getPaginatedQualifications(url)
      .then((res) => {
        setQualifications({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => {
        setQualifications((prevState) => ({
          ...prevState,
          isLoading: false,
        }));
      });
  };

  useEffect(() => {
    fetchQualifications();
  }, [companyId, qualificationsFilters]);

  const handleShowConfirmationDialog = (row, e) => {
    e.stopPropagation();
    setShowConfirmationDialog(true);
    setDeleteData(row);
  };

  const handleDeleteItem = async () => {
    await deleteQualification(deleteData?.original?.id, companyId);
    setShowConfirmationDialog(false);
    fetchQualifications();
  };

  const handleSorting = (sortData) => {
    const sortKey = sortData.id;

    setQualificationsFilter((prevState) => ({
      ...prevState,
      selectedAscDesc: sortData.desc ? 'desc' : 'asc',
      selectedSort: sortKey,
    }));
  };

  const handleTableRowClick = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleAddNewQualification = () => {
    setModalData({
      isOpen: true,
      selectedItem: null,
    });
  };

  const handleEditQualification = (row) => {
    setModalData({
      isOpen: true,
      selectedItem: row,
    });
  };

  const handleCloseModal = () => {
    setModalData({
      isOpen: false,
      selectedItem: null,
    });
  };

  const closeModalAndRefetch = () => {
    handleCloseModal();
    fetchQualifications();
  };

  return (
    <div className="qualifications-container">
      <div className="qualifications-container__table">
        <h3>{t('page_content.human_resources.workers.qualifications')}</h3>

        <div className="qualifications-container__action-buttons">
        <Button
          type="add"
          disabled={!userHaveFullAccess}
          onClick={handleAddNewQualification}
        >
          {t('page_content.human_resources.workers.add_new_qualification')}
        </Button>
        </div>

        <Table
          style={{ userSelect: 'text' }}
          columns={[
            {
              Header: () => <span>{t('page_content.human_resources.workers.name')}</span>,
              accessor: 'name',
            },
            {
              Header: () => '',
              Cell: (row) => (
                    <Button
                      disabled={!userHaveFullAccess}
                      onClick={() => handleEditQualification(row)}
                    >
                      <IconEdit
                        height="14px"
                        width="14px"
                        fill="#4285F4"
                      />
                    </Button>),
              width: 70,
              sortable: false,
              style: {
                cursor: 'default',
              },
            },
            {
              Header: () => '',
              Cell: (row) => (
                <Button
                  type="delete"
                  disabled={!userHaveFullAccess}
                  onClick={(e) => handleShowConfirmationDialog(row, e)}
                >
                  <IconRemove width="12px" height="12px" />
                </Button>
              ),
              width: 70,
              sortable: false,
            },
          ]}
          minRows={0}
          noDataText=""
          selectedRow={null}
          defaultPageSize={100}
          showPagination={false}
          data={qualifications.data || []}
          loading={qualifications.isLoading}
          defaultSorted={[{ id: 'name', desc: false }]}
          onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
          handleClick={(rowInfo) => handleTableRowClick(get(rowInfo, 'original'))}
        />
        <div>
          <span style={{ float: 'right' }}>
            <TableButtons
              next={qualifications.next}
              count={qualifications.count}
              previous={qualifications.previous}
              fetchFunction={fetchPaginatedQualifications}
            />
          </span>
        </div>
      </div>

      {modalData?.isOpen &&
      <QualificationsModal
        isOpen={modalData?.isOpen}
        handleCloseModal={handleCloseModal}
        initialValues={modalData?.selectedItem}
        closeModalAndRefetch={closeModalAndRefetch}
      />}

      <ConfirmationModal
        type="warning"
        showModal={showConfirmationDialog}
        handleConfirmModal={handleDeleteItem}
        itemName={`${get(deleteData, 'original.name')}`}
        handleCloseModal={() => setShowConfirmationDialog(false)}
      />
    </div>
  );
};

QualificationsTable.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default withTranslation()(QualificationsTable);
