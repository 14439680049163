/* eslint-disable react/no-access-state-in-setstate */
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { SketchPicker } from 'react-color';
import { Button } from 'shared';
import { formatTypes, usingVariablesTooltip } from 'shared/constants';
import { IconRemove, IconInfo } from 'shared/Icons';
import DatapointForm from '../DatapointForm';
import CalculatedValueForm from '../CalculatedValueForm';
import CalculatedValueUsageForm from '../CalculatedValueUsageForm';
import { defaultSettings } from '../constants';
import '../styles.scss';

const colorIndex = [
  '#1abc9c',
  '#2980b9',
  '#e67e22',
  '#e74c3c',
  '#9b59b6',
  '#2ecc71',
  '#34495e',
  '#8e44ad',
  '#2c3e50',
  '#7f8c8d',
  '#c0392b',
  '#d35400',
  '#f39c12',
];

class LineChartForm extends Component {
  constructor(props) {
    super(props);

    const { config } = props;

    // hack for existing line charts created with
    // a different settings object
    if (props.config.settings && !props.config.settings.annotation) {
      props.config.settings.annotation = {
        value: defaultSettings.line.annotation.value,
        name: defaultSettings.line.annotation.name,
        color: defaultSettings.line.annotation.color,
        thickness: defaultSettings.line.annotation.thickness,
        dashed: defaultSettings.line.annotation.dashed,
      };
    }

    const widget_data = props.config.widget_data.map((item) => { item.randId = Math.random(); return item; });
    const widgetData = widget_data.filter((wD) => !wD.style.isCalculatedValue && !wD.style.isCalculatedValueUsage);
    const calculatedWidgetData = widget_data.filter((wD) => wD.style.isCalculatedValue).map((item) => { item.randId = Math.random(); return item; }) || [];
    const calculatedUsageWidgetData = widget_data.find((wD) => wD.style.isCalculatedValueUsage);

    this.state = {
      id: config.id,
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings: config.settings || defaultSettings.line,
      title: config.title || '',
      type: config.type,
      showDecimal: false,
    };
  }

  getValueType = () => {
    const {
      widgetData,
    } = this.state;
    const values = [];

    const filteredwidgetData = widgetData.filter((e) => {
      return e.metric !== null && e.metric.value_type && e.metric.value_type.id && e.metric.value_type.id !== undefined;
    });

    filteredwidgetData.forEach((i) => {
      values.push(i.metric.value_type.id);
    });

    return (values.every((val, i, arr) => val === arr[0]));
  }

  addWidgetDataItem = () => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData.push({
      randId: Math.random(),
      drone: null,
      asset: null,
      metric: null,
      field: null,
      operation: 'avg',
      group_by: 'auto',
      style: {
        gapFill: false,
        selectedField: null,
      },
    });
    this.setState({ widgetData });
  }

  addCalculatedWidgetDataItem = () => {
    const calculatedWidgetData = this.state.calculatedWidgetData.slice(0);
    calculatedWidgetData.push({
      randId: Math.random(),
      metric: null,
      field: null,
      style: {
        selectedField: null,
        alias: '',
        isCalculatedValue: true,
      },
      group_by: 'auto',
      operation: 'avg',
    });

    const calculatedValueUsageWidgetDataItemExists = calculatedWidgetData.find((wD) => wD.style.isCalculatedValueUsage);
    if (!calculatedValueUsageWidgetDataItemExists) {
      this.setState({
        calculatedWidgetData,
        calculatedUsageWidgetData: {
          metric: null,
          field: null,
          style: {
            selectedField: null,
            alias: '',
            isCalculatedValueUsage: true,
          },
          group_by: 'auto',
          operation: 'avg',
        },
      });
    } else {
      this.setState({ calculatedWidgetData });
    }
  }

  updateWidgetDataItem = (numberInArray, widgetDataItem) => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData[numberInArray] = widgetDataItem;
    this.setState({
      widgetData,
    });
  }

  updateCalculatedWidgetDataItem = (numberInArray, widgetDataItem) => {
    const calculatedWidgetData = this.state.calculatedWidgetData.slice(0);
    calculatedWidgetData[numberInArray] = widgetDataItem;
    this.setState({
      calculatedWidgetData,
    });
  }

  updateCalculatedUsageWidgetDataItem = (calculatedUsageWidgetData) => {
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateAdjustedValue = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.adjusted_value)) {
      settings.adjusted_value = [];
    }
    const existingValue = settings.adjusted_value.find((aV) => aV.metricId === metricId && aV.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.adjusted_value.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateShiftFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.shift)) {
      settings.shift = [];
    }
    const existingValue = settings.shift.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.shift.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateProductTypeFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.productType)) {
      settings.productType = [];
    }
    const existingValue = settings.productType.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.productType.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilter = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.where)) {
      settings.where = [];
    }
    const existingValue = settings.where.find((w) => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.where.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateGapFill = (val, metricId, indexData) => {
    const widgetData = this.state.widgetData.slice(0);
    widgetData[indexData].style.gapFill = val;
    this.setState({
      widgetData,
    });
    /*
    const { settings } = this.state;
    if (!settings.gapFill || !Array.isArray(settings.gapFill)) {
      settings.gapFill = [];
    }

    const existingValue = settings.gapFill.find(w => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.gapFill.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
    */
  }

  removeWidgetDataItem = (elementRandomId) => {
    const widgetData = this.state.widgetData.slice(0);
    const filteredWidgetData = widgetData.filter((widgetItem) => {
      return widgetItem.randId !== elementRandomId;
    });
    // widgetData.splice(numberInArray, 1);
    this.setState({
      widgetData: filteredWidgetData,
    });
  }

  removeCalculatedWidgetDataItem = (elementRandomId) => {
    const {
      calculatedWidgetData,
    } = this.state;

    const newCalculatedWidgetData = calculatedWidgetData.slice(0);
    const filteredCalculatedWidgetData = newCalculatedWidgetData.filter((widgetItem) => {
      return widgetItem.randId !== elementRandomId;
    });
    // newCalculatedWidgetData.splice(numberInArray, 1);

    if (!filteredCalculatedWidgetData || !filteredCalculatedWidgetData.length) {
      this.setState({
        calculatedUsageWidgetData: null,
        calculatedWidgetData: filteredCalculatedWidgetData,
      });
    } else {
      this.setState({
        calculatedWidgetData: filteredCalculatedWidgetData,
      });
    }
  }

  saveWidget = () => {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
    } = this.state;

    let calcMetricsOK = true;
    if (calculatedWidgetData && calculatedWidgetData.length) {
      calculatedWidgetData.forEach((cWD) => {
        if (!cWD.metric) {
          calcMetricsOK = false;
        }
      });
    }

    if (!calcMetricsOK) {
      alert('All calculated values must have a selected source and metric.');
      return;
    }

    const widget_data = widgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    const cleanedCalculatedWidgetData = calculatedWidgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    if (calculatedUsageWidgetData) {
      if (Object.prototype.hasOwnProperty.call(calculatedUsageWidgetData, 'field')) {
        delete calculatedUsageWidgetData.field;
      }
      delete calculatedUsageWidgetData.metric;

      widget_data.push(calculatedUsageWidgetData);
    }

    const data = {
      widget_data: widget_data.concat(cleanedCalculatedWidgetData),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  updateAdjustedValueForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.adjusted_value = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateShiftFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.shift = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateProductTypeFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.productType = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateWhereFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.where = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateGapFillForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.gapFill = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateColor = (color, widgetDataItem) => {
    const newWidgetData = this.state.widgetData.slice(0);

    if (!widgetDataItem.style.isCalculatedValue && !widgetDataItem.style.isCalculatedValueUsage) {
      const objIndex = newWidgetData.findIndex(((obj) => obj.id === widgetDataItem.id));
      newWidgetData[objIndex].style.color = color.rgb;

      this.setState({
        widgetData: newWidgetData,
      });
    } else {
      this.updateCalculatedColor(color);
    }
  }

  updateCalculatedColor = (color) => {
    const newWidgetData = this.state.calculatedUsageWidgetData;

    newWidgetData.style.color = color.rgb;

    this.setState({
      calculatedUsageWidgetData: newWidgetData,
    });
  }

  hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
      a: 1.0,
    } : null;
  }

  updateDepartmentFilter = (value, metricId, indexData) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.department)) {
        settings.department = settings.department.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.department)) {
      settings.department = [];
    }
    const existingValue = settings.department.find((w) => w.id === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.department.push({ value, id: metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWorkOperationsFilter = (value, metricId, indexData) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.workOperation)) {
      settings.workOperation = [];
    }
    const existingValue = settings.workOperation.find((w) => w.id === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.workOperation.push({ value, id: metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  render() {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings,
      title,
      showDecimal,
      type,
    } = this.state;

    const colorWd = [...widgetData, calculatedUsageWidgetData].filter((el) => {
      return el != null;
    });

    const { locationId, t } = this.props;
    const decimalPlaces = [1, 2, 3, 4];
    const sides = ['left', 'right'];
    const titleSides = ['left', 'center', 'right'];

    let isValueTypeEqual = true;

    if (widgetData && this.props && this.props.config && this.props.config.widget_data[0] && this.props.config.widget_data[0].metric && this.props.config.widget_data[0].metric.value_type && this.props.config.widget_data[0].metric.value_type.id) {
      isValueTypeEqual = this.getValueType(widgetData);
    }

    const filteredFormatTypes = { ...formatTypes };

    delete filteredFormatTypes.date;

    return (
      <div className="default-form">
        <table className="widget-title">
          <tr>
            <td>
              <label htmlFor="widget-title">
                {t('page_content.dashboards.edit_widget_modal.widget_title')}
              </label>
            </td>
            <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <input
                id="widget-title"
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                type="text"
                value={title}
              />
              <Tooltip
                id="tooltip-zoom"
                trigger={['hover']}
                placement="left"
                overlay={usingVariablesTooltip(t)}
                overlayClassName="where-filter-tooltip"
              >
                <span aria-describedby="tooltip-zoom">
                  <IconInfo color="#2e86de" height="15px" width="17px" />
                </span>
              </Tooltip>
            </td>
          </tr>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.calculated_values')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.axes')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.legend')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.appearance')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.annotation')}
            </Tab>
          </TabList>
          <TabPanel>
            {
              widgetData.map((widgetDataItem, i) => (
                <div key={`${widgetDataItem.randId}`} data-reactid={`${widgetDataItem.randId}`} className="datapoint-container">
                  <DatapointForm
                    type={type}
                    key={`widget-data-item-${widgetDataItem.randId}`}
                    id={i}
                    indexData={i}
                    locationId={locationId}
                    datapoint={widgetDataItem}
                    onChange={(val) => this.updateWidgetDataItem(i, val)}
                    adjustedValue={Array.isArray(settings.adjusted_value) ? settings.adjusted_value.find((aV) => { if (widgetDataItem.metric) { return aV.metricId === widgetDataItem.metric.id && aV.indexData === i; } return false; }) : null}
                    updateAdjustedValue={(val, metricId, indexData) => this.updateAdjustedValue(val, metricId, indexData)}
                    shift={Array.isArray(settings.shift) ? settings.shift.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateShiftFilter={(val, id, indexData) => this.updateShiftFilter(val, id, indexData)}
                    productType={Array.isArray(settings.productType) ? settings.productType.find((w) => { if (widgetDataItem.metric) { return (w.id === widgetDataItem.metric.id && w.indexData === i); } return false; }) : null}
                    updateProductTypeFilter={(val, id, indexData) => this.updateProductTypeFilter(val, id, indexData)}
                    where={Array.isArray(settings.where) ? settings.where.find((w) => { if (widgetDataItem.metric) { return w.metricId === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateWhereFilter={(val, metricId, indexData) => this.updateWhereFilter(val, metricId, indexData)}
                    /* gapFill={Array.isArray(settings.gapFill) ? settings.gapFill.find((w) => { if (widgetDataItem.metric) { return (w.metricId === widgetDataItem.metric.id && w.indexData === i); } return false; }) : null} */
                    gapFill={widgetDataItem.style.gapFill || false}
                    updateGapFill={(val, metricId, indexData) => this.updateGapFill(val, metricId, indexData)}
                    updateDepartmentFilter={(value, metricId, indexData) => this.updateDepartmentFilter(value, metricId, indexData)}
                    department={Array.isArray(settings.department) ? settings.department.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateWorkOperationsFilter={(value, metricId, indexData) => this.updateWorkOperationsFilter(value, metricId, indexData)}
                    workOperation={Array.isArray(settings.workOperation) ? settings.workOperation.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                  />
                  <Button
                    onClick={() => this.removeWidgetDataItem(widgetDataItem.randId)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              widgetData.length < 10 &&
              <Button
                onClick={this.addWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.datapoints_tab.new_datapoint_button')}
              </Button>
            }
          </TabPanel>
          <TabPanel>
            {
              calculatedWidgetData.map((widgetDataItem, i) => (
                <div key={`${widgetDataItem.randId}`} data-reactid={`${widgetDataItem.randId}`} className="datapoint-container">
                  <CalculatedValueForm
                    key={`calc-val-${widgetDataItem.randId}`}
                    datapoint={widgetDataItem}
                    onChange={(item) => this.updateCalculatedWidgetDataItem(i, item)}
                    locationId={locationId}
                    alias={widgetDataItem.style.alias}
                  />
                  <Button
                    onClick={() => this.removeCalculatedWidgetDataItem(widgetDataItem.randId)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              calculatedWidgetData.length <= 15 &&
              <Button
                onClick={this.addCalculatedWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.new_calculated_value_button')}
              </Button>
            }
            {
              calculatedWidgetData.length > 0 &&
              <CalculatedValueUsageForm
                datapoint={calculatedUsageWidgetData}
                onChange={(item) => this.updateCalculatedUsageWidgetDataItem(item)}
                locationId={locationId}
                adjustedValue={calculatedUsageWidgetData.style.adjusted_value}
                updateAdjustedValue={(val) => this.updateAdjustedValueForCalc(val)}
                where={calculatedUsageWidgetData.style.where}
                updateWhereFilter={(val) => this.updateWhereFilterForCalc(val)}
                shift={calculatedUsageWidgetData.style.shift}
                updateShiftFilter={(val) => this.updateShiftFilterForCalc(val)}
                productType={calculatedUsageWidgetData.style.productType}
                updateProductTypeFilter={(val) => this.updateProductTypeFilterForCalc(val)}
                gapFill={calculatedUsageWidgetData.style.gapFill}
                updateGapFill={(val) => this.updateGapFillForCalc(val)}
              />
            }
          </TabPanel>
          <TabPanel>
            <h4>
              {t('page_content.dashboards.edit_widget_modal.axes_tab.x_axis')}
            </h4>
            <table>
              <tbody>
                <tr>
                  <td className="label">
                    <label htmlFor="show">
                      {t('page_content.dashboards.edit_widget_modal.axes_tab.show')}
                    </label>
                  </td>
                  <td className="input">
                    <input
                      id="show"
                      checked={settings.axis.x.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          axis: {
                            x: {
                              show: {
                                $set: !settings.axis.x.show,
                              },
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <h4>
              {t('page_content.dashboards.edit_widget_modal.axes_tab.y_axis')}
            </h4>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      {t('page_content.dashboards.edit_widget_modal.axes_tab.side')}
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            axis: {
                              side: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={sides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings.axis.side ? sides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.axis.side) : null}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="show2">
                      {t('page_content.dashboards.edit_widget_modal.axes_tab.show')}
                    </label>
                  </td>
                  <td className="input">
                    <input
                      id="show2"
                      checked={settings.axis.y1.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          axis: {
                            y1: {
                              show: {
                                $set: !settings.axis.y1.show,
                              },
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="offset">
                      {t('page_content.dashboards.edit_widget_modal.axes_tab.add_offset')}
                    </label>
                  </td>
                  <td className="input">
                    <input
                      id="oneYAxis"
                      checked={settings.axis.addOffset}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          axis: {
                            addOffset: {
                              $set: !settings.axis.addOffset,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                {!isValueTypeEqual &&
                  <tr>
                    <td className="label">
                      <label htmlFor="oneYAxis">
                        {t('page_content.dashboards.edit_widget_modal.axes_tab.use_one_y_axis')}
                      </label>
                    </td>
                    <td className="input">
                      <input
                        id="oneYAxis"
                        checked={settings.axis.oneYAxis}
                        onChange={() => this.setState({
                          settings: update(settings, {
                            axis: {
                              oneYAxis: {
                                $set: !settings.axis.oneYAxis,
                              },
                            },
                          }),
                        })}
                        type="checkbox"
                      />
                    </td>
                  </tr>}
                {
                  settings.axis.y1.show &&
                  <Fragment>
                    <tr>
                      <td className="label">
                        <label htmlFor="min">
                          {t('page_content.dashboards.edit_widget_modal.axes_tab.min_value')}
                        </label>
                      </td>
                      <td className="input">
                        <input
                          className="small"
                          id="min"
                          onChange={(e) => this.setState({
                            settings: update(settings, {
                              axis: {
                                y1: {
                                  min: {
                                    $set: e.target.value,
                                  },
                                },
                              },
                            }),
                          })}
                          type="text"
                          value={settings.axis.y1.min}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label htmlFor="max">
                          {t('page_content.dashboards.edit_widget_modal.axes_tab.max_value')}
                        </label>
                      </td>
                      <td className="input">
                        <input
                          className="small"
                          id="max"
                          onChange={(e) => this.setState({
                            settings: update(settings, {
                              axis: {
                                y1: {
                                  max: {
                                    $set: e.target.value,
                                  },
                                },
                              },
                            }),
                          })}
                          type="text"
                          value={settings.axis.y1.max}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label htmlFor="label">
                          {t('page_content.dashboards.edit_widget_modal.axes_tab.label')}
                        </label>
                      </td>
                      <td className="input">
                        <input
                          id="label"
                          onChange={(e) => this.setState({
                            settings: update(settings, {
                              axis: {
                                y1: {
                                  label: {
                                    $set: e.target.value,
                                  },
                                },
                              },
                            }),
                          })}
                          type="text"
                          value={settings.axis.y1.label}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="label">
                        <label htmlFor="formatting">
                          {t('page_content.dashboards.edit_widget_modal.axes_tab.value_formatting')}
                        </label>
                      </td>
                      <td className="input">
                        <Select
                          id="formatting"
                          className="AscaliaSelect"
                          onChange={(v) => {
                            if (!v) return;
                            let showDec = false;
                            const value = v.value;
                            if (value !== 'float') {
                              this.setState({
                                settings: update(settings, {
                                  axis: {
                                    y1: {
                                      decimal_places: {
                                        $set: null,
                                      },
                                    },
                                  },
                                }),
                                showDecimal: showDec,
                              });
                            } else {
                              showDec = true;
                            }
                            this.setState({
                              settings: update(settings, {
                                axis: {
                                  y1: {
                                    format: {
                                      $set: value,
                                    },
                                  },
                                },
                              }),
                              showDecimal: showDec,
                            });
                          }}
                          options={Object.values(filteredFormatTypes).map((formatType) => ({
                            label: formatType,
                            value: formatType,
                          }))}
                          value={settings.axis.y1.format ? Object.values(filteredFormatTypes).map((formatType) => ({
                            label: formatType,
                            value: formatType,
                          })).find((fT) => fT.value === settings.axis.y1.format) : null}
                        />
                      </td>
                    </tr>
                    {
                      settings.axis.y1.format === 'float' || showDecimal ?
                        <tr>
                          <td className="label">
                            <label htmlFor="decimal_places">
                              {t('page_content.dashboards.edit_widget_modal.axes_tab.decimal_places')}
                            </label>
                          </td>
                          <td className="input">
                            <Select
                              id="decimal_places"
                              className="AscaliaSelect"
                              onChange={(v) => {
                                const value = v.value;
                                this.setState({
                                  settings: update(settings, {
                                    axis: {
                                      y1: {
                                        decimal_places: {
                                          $set: value,
                                        },
                                      },
                                    },
                                  }),
                                });
                              }}
                              options={decimalPlaces.map((dP) => ({
                                label: dP,
                                value: dP,
                              }))}
                              value={settings.axis.y1.decimal_places ? decimalPlaces.map((dP) => ({
                                label: dP,
                                value: dP,
                              })).find((dP) => dP.value === settings.axis.y1.decimal_places) : null}
                            />
                          </td>
                        </tr> : ''
                    }
                  </Fragment>
                }
              </tbody>
            </table>
            {
              false &&
              <Fragment>
                <h4>
                  Right Y Axis
                </h4>
                <table>
                  <tbody>
                    <tr>
                      <td className="label">
                        <label htmlFor="show3">
                          Show
                        </label>
                      </td>
                      <td className="input">
                        <input
                          id="show3"
                          checked={settings.axis.y2.show}
                          onChange={() => this.setState({
                            settings: update(settings, {
                              axis: {
                                y2: {
                                  show: {
                                    $set: !settings.axis.y2.show,
                                  },
                                },
                              },
                            }),
                          })}
                          type="checkbox"
                        />
                      </td>
                    </tr>
                    {
                      settings.axis.y2.show &&
                      <Fragment>
                        <tr>
                          <td className="label">
                            <label htmlFor="y2-min">
                              Min value
                            </label>
                          </td>
                          <td className="input">
                            <input
                              id="y2-min"
                              onChange={(e) => this.setState({
                                settings: update(settings, {
                                  axis: {
                                    y2: {
                                      min: {
                                        $set: e.target.value,
                                      },
                                    },
                                  },
                                }),
                              })}
                              type="text"
                              value={settings.axis.y2.min}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td className="label">
                            <label htmlFor="y2-max">
                              Max value
                            </label>
                          </td>
                          <td className="input">
                            <input
                              id="y2-max"
                              onChange={(e) => this.setState({
                                settings: update(settings, {
                                  axis: {
                                    y2: {
                                      max: {
                                        $set: e.target.value,
                                      },
                                    },
                                  },
                                }),
                              })}
                              type="text"
                              value={settings.axis.y2.max}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label htmlFor="y2-label">
                              Label
                            </label>
                          </td>
                          <td>
                            <input
                              id="y2-label"
                              onChange={(e) => this.setState({
                                settings: update(settings, {
                                  axis: {
                                    y2: {
                                      label: {
                                        $set: e.target.value,
                                      },
                                    },
                                  },
                                }),
                              })}
                              type="text"
                              value={settings.axis.y2.label}
                            />
                          </td>
                        </tr>
                      </Fragment>
                    }
                  </tbody>
                </table>
              </Fragment>
            }
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.legend_tab.show_legend')}
                  </td>
                  <td>
                    <input
                      checked={settings.legend.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          legend: {
                            show: {
                              $set: !settings.legend.show,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.legend_tab.show_events')}
                  </td>
                  <td>
                    <input
                      checked={settings.legend.events}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          legend: {
                            events: {
                              $set: !settings.legend.events,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_grid')}
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.grid}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            grid: {
                              $set: !settings.appearance.grid,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_tooltip')}
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.tooltip}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            tooltip: {
                              $set: !settings.appearance.tooltip,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.align_title_text')}
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            appearance: {
                              title: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings.appearance.title ? titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.appearance.title) : null}
                    />
                  </td>
                </tr>
                {
                  (colorWd && colorWd.length !== 0) ?
                    <tr>
                      <td>
                        {t('page_content.dashboards.edit_widget_modal.appearance_tab.datapoints')}
                      </td>
                      {colorWd.sort((a, b) => { return a.id - b.id; }).map((widgetDataItem, index) => (
                        <tr>
                          <td style={{ width: '50%' }}>{widgetDataItem.label}</td>
                          <td>
                            <SketchPicker
                              color={
                                (widgetDataItem && widgetDataItem.style && widgetDataItem.style.color) ? widgetDataItem.style.color :
                                  widgetDataItem.style && widgetDataItem.style.isCalculatedValueUsage ? '#2ecc71' :
                                    this.hexToRgb(colorIndex[index])
                              }
                              onChangeComplete={(color) => this.updateColor(color, widgetDataItem)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tr> : ''
                }
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.annotation_tab.name')}
                  </td>
                  <td>
                    <input
                      value={settings.annotation ? settings.annotation.name : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            name: {
                              $set: e.target.value,
                            },
                          },
                        }),
                      })}
                      type="text"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.annotation_tab.value')}
                  </td>
                  <td>
                    <input
                      value={settings.annotation ? settings.annotation.value : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            value: {
                              $set: e.target.value,
                            },
                          },
                        }),
                      })}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.annotation_tab.line_color')}
                  </td>
                  <td>
                    <SketchPicker
                      color={settings.annotation ? settings.annotation.color : '#3182bd'}
                      onChangeComplete={(color) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            color: {
                              $set: color.hex,
                            },
                          },
                        }),
                      })}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.annotation_tab.line_thickness')}
                  </td>
                  <td>
                    <input
                      value={settings.annotation ? settings.annotation.thickness : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            thickness: {
                              $set: e.target.value,
                            },
                          },
                        }),
                      })}
                      type="number"
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.annotation_tab.dashed_line')}
                  </td>
                  <td>
                    <input
                      checked={settings.annotation ? settings.annotation.dashed : null}
                      onChange={(e) => this.setState({
                        settings: update(settings, {
                          annotation: {
                            dashed: {
                              $set: e.target.checked,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
            disabled={!widgetData.length && !calculatedWidgetData.length}
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

LineChartForm.propTypes = {
  widget_data: PropTypes.array,
  locationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(LineChartForm);
