import React, { useRef } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import { numberSeparatorFormat } from 'industry/helpers';
import { IconWarning } from '../../Icons';
import { colors } from '../../colors';
import WidgetPlaceholder from '../WidgetPlaceholder';
import './styles.scss';

const Scalar = ({ data, height, width, settings, widgetData = [], company_short_code }) => {
  const widgetRef = useRef(null);

  let widgetConfig = null;

  if (widgetData && widgetData.length > 1) {
    if (widgetData.some((wD) => wD.style.isCalculatedValueUsage)) {
      widgetConfig = widgetData.filter((wD) => !wD.style.isCalculatedValue && wD.style.isCalculatedValueUsage)[0];
    } else {
      widgetConfig = widgetData.filter((wD) => !wD.style.isCalculatedValue && !wD.style.isCalculatedValueUsage)[0];
    }
  } else {
    widgetConfig = widgetData.filter((wD) => !wD.style.isCalculatedValue && !wD.style.isCalculatedValueUsage)[0];
  }

  if (!widgetConfig) {
    return <WidgetPlaceholder height={height} type="scalar" />;
  }
  const style = widgetConfig.style || {};
  const field = style.selectedField || 'value';
  let value = data
    ? data[field]
      ? parseFloat(data[field]).toFixed(company_short_code && company_short_code === 'intis' ? 3 : 2)
      : null
    : null;
  const unit = settings && settings.unit_text ? settings.unit_text : '';
  const label = widgetConfig.label;

  let backgroundColor = '#ffffff';
  let textColor = '#555';

  let labelText = '';

  let showLimitIcon = false;

  if (settings) {
    if (widgetConfig) {
      if (settings.format === 'float') {
        const decimalPlaces = settings.decimal_places ? settings.decimal_places : 2;
        if (decimalPlaces && value) {
          value = parseFloat(value).toFixed(decimalPlaces);
        }
      } else if (settings.format === 'integer') {
        value = Math.round(value);
      }
    }

    if (settings.defaultBackgroundColor) {
      backgroundColor = settings.defaultBackgroundColor;
    }

    if (settings.defaultTextColor) {
      textColor = settings.defaultTextColor;
    }

    if (company_short_code && company_short_code === 'intis') {
      if ((value || value === 0) && typeof parseInt(value, 10) === 'number') {
        if (settings.threshold) {
          const threshold = settings.threshold.split(',');
          const threshold_labels = settings && settings.threshold_labels && settings.threshold_labels.length ? settings.threshold_labels.split(',').filter((t) => !!t) : null;
          if (threshold && threshold.length) {
            if (threshold[0]) {
              const t1 = parseFloat(threshold[0]).toFixed(2);
              if ((t1 || t1 === 0) && typeof parseInt(t1, 10) === 'number' && Number(value) <= Number(t1)) {
                backgroundColor = settings.thresholdColors[0];
                if (settings && settings.customLabels && settings.threshold_labels && settings.threshold_labels[0]) {
                  labelText = threshold_labels[0];
                }
              }
            }
            if (threshold[1] && threshold.length > 2) {
              const t1 = parseFloat(threshold[0]).toFixed(2);
              const t3 = parseFloat(threshold[2]).toFixed(2);
              if ((t1 || t1 === 0) && typeof parseInt(t1, 10) === 'number' && Number(value) > Number(t1) && Number(value) < Number(t3)) {
                backgroundColor = settings.thresholdColors[1];
                if (settings && settings.customLabels && settings.threshold_labels && settings.threshold_labels[1]) {
                  labelText = threshold_labels[1];
                }
              }
            }
            if (threshold[2]) {
              const t3 = parseFloat(threshold[2]).toFixed(2);
              if ((t3 || t3 === 0) && typeof parseInt(t3, 10) === 'number' && Number(value) >= Number(t3)) {
                backgroundColor = settings.thresholdColors[2];
                if (settings && settings.customLabels && settings.threshold_labels && settings.threshold_labels[2]) {
                  labelText = threshold_labels[2];
                }
              }
            }
          }
        }
        if (settings.limitToMinMax !== 'undefined' && settings.limitToMinMax === true) {
          value = (settings && settings.format === 'float')
            ? Math.min(Math.max(value, settings.min), settings.max).toFixed(2)
            : Math.min(Math.max(value, settings.min), settings.max);
          showLimitIcon = true;
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (value && typeof parseInt(value, 10) === 'number') {
        if (settings.threshold) {
          const threshold = settings.threshold.split(',');
          const threshold_labels = settings && settings.threshold_labels && settings.threshold_labels.length ? settings.threshold_labels.split(',').filter((t) => !!t) : null;
          if (threshold && threshold.length) {
            if (threshold[0]) {
              const t1 = parseInt(threshold[0], 10);
              if (t1 && typeof t1 === 'number' && parseInt(value, 10) > t1) {
                backgroundColor = settings.thresholdColors[0];
                if (settings && settings.customLabels && settings.threshold_labels && settings.threshold_labels[0]) {
                  labelText = threshold_labels[0];
                }
              }
            }
            if (threshold[1]) {
              const t2 = parseInt(threshold[1], 10);
              if (t2 && typeof t2 === 'number' && parseInt(value, 10) > t2) {
                backgroundColor = settings.thresholdColors[1];
                if (settings && settings.customLabels && settings.threshold_labels && settings.threshold_labels[1]) {
                  labelText = threshold_labels[1];
                }
              }
            }
            if (threshold[2]) {
              const t3 = parseInt(threshold[2], 10);
              if (t3 && typeof t3 === 'number' && parseInt(value, 10) > t3) {
                backgroundColor = settings.thresholdColors[2];
                if (settings && settings.customLabels && settings.threshold_labels && settings.threshold_labels[2]) {
                  labelText = threshold_labels[2];
                }
              }
            }
          }
        }
        if (settings.limitToMinMax !== 'undefined' && settings.limitToMinMax === true) {
          value = (settings && settings.format === 'float')
            ? Math.min(Math.max(value, settings.min), settings.max).toFixed(2)
            : Math.min(Math.max(value, settings.min), settings.max);
          showLimitIcon = true;
        }
      }
    }
  }

  const noFormatDecimalPlaces = company_short_code && company_short_code === 'intis' ? 3 : 2;
  const decimalPlaces = settings && settings.format === 'float' ? settings.decimal_places ? settings.decimal_places : 2 : settings && settings.format === 'integer' ? 0 : noFormatDecimalPlaces;

  value = settings && settings.format === 'time' ? value : numberSeparatorFormat('hr', value, decimalPlaces, decimalPlaces, true);

  let valuePart1 = value;
  let valuePart2 = '';

  if (settings && settings.format === 'float') {
    valuePart1 = value ? value.split(',')[0] : 'N/A';
    valuePart2 = value ? `,${value.split(',')[1]}` : '';
  } else if (settings && settings.format === 'time') {
    if (value) {
      value = parseFloat(value);

      const hh = Math.trunc(value);
      const mm = Math.round((value - Math.trunc(value)) * 60);

      valuePart1 = hh.toString().padStart(2, '0');
      valuePart2 = `:${mm.toString().padStart(2, '0')}`;
    } else {
      valuePart1 = '-';
    }
  } else if (!(value || value === 0)) {
    valuePart1 = 'N/A';
  }

  let smallLabel = false;
  if (data !== null && widgetRef.current !== null) {
    smallLabel = widgetRef.current.clientWidth <= 180 && widgetRef.current.clientHeight <= 130;
  }

  const part1 = settings.customLabels ? labelText : valuePart1;
  const part2 = settings.customLabels ? '' : valuePart2;

  const lengthOfPart1 = part1.length;
  const lengthOfPart2 = part2.length;

  const characterWidth = (lengthOfPart1 + lengthOfPart2) > 8 ? 6.5 : 4;
  let fontSize = width / characterWidth;

  if (fontSize >= 50 && height <= 70 && height > 40) {
    fontSize = 40;
  } else if (fontSize >= 30 && height <= 40) {
    fontSize = 13;
  } else if (fontSize > 70) {
    fontSize = 70;
  } else if (fontSize < 13) {
    fontSize = 13;
  }

  const fontSizeUnitMin = 10;
  const fontSizeUnitFn = -20;
  const fontSizeUnitMax = 30;
  const fontSizeUnit = Math.min(Math.max(fontSize + fontSizeUnitFn, fontSizeUnitMin), fontSizeUnitMax);

  return (
    <div className="scalar-widget" ref={widgetRef} style={{ height: `${height}px`, background: backgroundColor }}>
      {showLimitIcon === true && <span className="scalar-widget__limit-icon">
        <Tooltip
          id="tooltip-limit-scalar"
          trigger={['hover']}
          placement="bottom"
          overlay={<span>Please check shift times for more accurate results.</span>}
          overlayClassName="scalar-widget__tooltip"
        >
          <span aria-describedby="tooltip-limit-scalar">
            <IconWarning color={colors.red} height="16px" width="16px" />
          </span>
        </Tooltip>
      </span>}
      <span className="value" style={{ fontSize }}>
        <span
          style={{
            fontSize: settings.customLabels ? 'min(2em, 40px)' : fontSize,
            color: textColor,
          }}
        >
          {settings.customLabels ? labelText : valuePart1}
        </span>
        <span
          className="decimal"
          style={{
            fontSize,
            color: textColor,
          }}
        >
          {settings.customLabels ? '' : valuePart2}
        </span>
        {
          get(settings, 'showUnit') &&
          <div
            className="unit"
            style={{
              fontSize: fontSizeUnit,
              color: textColor,
            }}
          >
            {unit || ''}
          </div>
        }
      </span>
      {
        settings.customLabels ?
          <span className="label_value" style={{ color: textColor }}>
            {`${valuePart1}${valuePart2}`}
          </span> :
          get(settings, 'showLabel') &&
          <span className={`label${smallLabel ? '_small' : ''}`} style={{ color: textColor }}>
            {settings.customLabels ? labelText : label}
          </span>
      }
    </div>
  );
};

Scalar.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.number,
  width: PropTypes.number,
  settings: PropTypes.any,
  widgetData: PropTypes.array,
  company_short_code: PropTypes.any,
};

export default Scalar;
