import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { defaultDateFormat, defaultISODateFormat } from 'shared/constants';
import { Table, Button, TableButtons, ConfirmationModal } from 'shared';
import { selectModalStyles, selectStyles } from 'styles/modules/reactSelect';
import { getLocale } from 'shared/DatePicker/constants';
import '../../../styles.scss';

import {
  getAbsence,
  addAbsence,
  editAbsence,
  deleteAbsence,
  getWorkerVacations,
  editWorkerVacations,
  getPaginatedAbsence,
} from '../actions';

const Absences = ({ t, isReadOnly, currentUser, workerId, companyId, workerAbsencesAndVacations, updateAbsenceAndVacationDays }) => {
  const absenceOptions = [
    {
      name: t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.vacation'),
      id: 'vacation',
    },
    {
      name: t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.sick-leave'),
      id: 'sick-leave',
    },
  ];
  const [absenceTable, setAbsenceTable] = useState({
    data: [],
    isLoading: true,
    count: 0,
    next: null,
    previous: null,
  });
  const [sortingAndFiltering, setSortingAndFiltering] = useState({
    selectedSort: 'updated_at',
    selectedAscDesc: 'desc',
    selectedAbsenceOption: absenceOptions[0],
  });
  const [absenceTablePage, setAbsenceTablePage] = useState(0);
  const [absenceTableModalData, setAbsenceTableModalData] = useState({});
  const [absenceModalError, setAbsenceModalError] = useState('');

  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [deleteData, setDeleteData] = useState(null);

  const fetchAbsences = async () => {
    setAbsenceTable((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    const asc = sortingAndFiltering?.selectedAscDesc === 'desc' ? '-' : '';
    let apiFilters = `&limit=15&order_by=${asc}${sortingAndFiltering?.selectedSort}`;

    if (sortingAndFiltering?.selectedAbsenceOption?.id) {
      apiFilters += `&absence_type=${sortingAndFiltering?.selectedAbsenceOption?.id}`;
    }

    await getAbsence(companyId, workerId, apiFilters)
      .then((res) => {
        setAbsenceTable({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setAbsenceTable((prevState) => ({ ...prevState, isLoading: false })));
  };

  const fetchPaginatedAbsences = async (url) => {
    setAbsenceTable((prevState) => ({
      ...prevState,
      isLoading: true,
    }));

    await getPaginatedAbsence(url)
      .then((res) => {
        setAbsenceTable({
          data: get(res, 'data.results', []),
          next: get(res, 'data.next', null),
          previous: get(res, 'data.previous', null),
          count: get(res, 'data.count', 0),
          isLoading: false,
        });
      })
      .catch(() => setAbsenceTable((prevState) => ({ ...prevState, isLoading: false })));
  };

  useEffect(() => {
    fetchAbsences();
  }, [sortingAndFiltering]);

  const handleSorting = (newSorted) => {
    setSortingAndFiltering((prevState) => ({
      ...prevState,
      selectedSort: newSorted.id,
      selectedAscDesc: newSorted.desc ? 'desc' : 'asc',
    }));
  };

  const handleFilterChange = (key, value) => {
    setSortingAndFiltering((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleAddAbsence = () => {
    setAbsenceTablePage(1);
    setAbsenceTableModalData({
      absence_type: absenceOptions[0].id,
    });
  };

  const handleBackToAbsences = () => {
    setAbsenceTableModalData({});
    setAbsenceTablePage(0);
  };

  const handleAbsenceModalChange = (key, value) => {
    setAbsenceTableModalData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSaveAbsence = async () => {
    const data = {
      absence_type: absenceTableModalData.absence_type,
      start_date: moment(absenceTableModalData.start_date).format(defaultISODateFormat),
      end_date: moment(absenceTableModalData.end_date).format(defaultISODateFormat),
      number_of_days: Number(absenceTableModalData.number_of_days),
      worker: workerId,
      user: currentUser.id,
    };

    await getWorkerVacations(companyId, workerId, `&year=${moment(data.start_date, defaultISODateFormat).format('YYYY')}&order_by=-updated_at`)
      .then(async (res) => {
        const workerVacation = get(res, 'data.results[0]', {});
        const usedDays = Number(get(res, 'data.results[0].used_days', 0));
        const assignedDays = Number(get(res, 'data.results[0].assigned_days', 0));

        if (!workerVacation?.id && data?.absence_type === 'vacation' && absenceTableModalData?.id) {
          setAbsenceModalError('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.worker_no_vacation_days');
          return;
        }

        if (absenceTableModalData?.absence_type === 'vacation') {
          if (!Number.isNaN(usedDays) && !Number.isNaN(data.number_of_days) && !Number.isNaN(assignedDays)) {
            if (absenceTableModalData?.id) {
              const oldData = absenceTable?.data.find((a) => a.id === absenceTableModalData?.id);
              const oldNumberOfDays = Number(oldData?.number_of_days) || 0;

              if (data?.number_of_days !== oldNumberOfDays) {
                const newUsedDays = usedDays + data.number_of_days - oldNumberOfDays;

                if (newUsedDays > assignedDays) {
                  setAbsenceModalError('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.exceeds_vacation_days');
                  return;
                }

                await editAbsence(companyId, absenceTableModalData?.id, data)
                  .then(async () => {
                    await editWorkerVacations(companyId, workerVacation?.id, { ...workerVacation, worker: workerVacation?.worker?.id, used_days: newUsedDays })
                      .then(() => {
                        fetchAbsences();
                        setAbsenceTableModalData({});
                        setAbsenceTablePage(0);
                      })
                      .catch(() => setAbsenceModalError('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.error_updating_vacation'));
                  });
              }
            } else if (!absenceTableModalData?.id && usedDays + data.number_of_days > assignedDays) {
              setAbsenceModalError('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.exceeds_vacation_days');
            } else if (!absenceTableModalData?.id && usedDays + data.number_of_days <= assignedDays) {
              await addAbsence(companyId, data)
                .then(async () => {
                  await editWorkerVacations(companyId, workerVacation?.id, { ...workerVacation, worker: workerVacation?.worker?.id, used_days: usedDays + data.number_of_days })
                    .then(() => {
                      fetchAbsences();
                      setAbsenceTableModalData({});
                      setAbsenceTablePage(0);
                    })
                    .catch(() => setAbsenceModalError('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.error_updating_vacation'));
                });
            }
          }
        } else if (absenceTableModalData?.absence_type === 'sick-leave') {
          if (absenceTableModalData?.id) {
            await editAbsence(companyId, absenceTableModalData?.id, data)
              .then(() => {
                fetchAbsences();
                setAbsenceTableModalData({});
                setAbsenceTablePage(0);
              });
          } else {
            await addAbsence(companyId, data)
              .then(() => {
                fetchAbsences();
                setAbsenceTableModalData({});
                setAbsenceTablePage(0);
              });
          }
        }
      });
    updateAbsenceAndVacationDays();
  };

  const handleShowConfirmationModal = (row) => {
    setDeleteData(row);
    setShowConfirmationDialog(true);
  };

  const handleDelete = async () => {
    await deleteAbsence(companyId, deleteData?.id)
      .then(async () => {
        if (deleteData?.absence_type === 'vacation') {
          await getWorkerVacations(companyId, workerId, `&year=${moment(deleteData?.start_date, defaultISODateFormat).format('YYYY')}&order_by=-updated_at`)
            .then(async (res) => {
              const workerVacation = get(res, 'data.results[0]', {});
              const usedDays = Number(get(res, 'data.results[0].used_days', 0));

              const newUsedDays = usedDays - deleteData?.number_of_days;

              await editWorkerVacations(companyId, workerVacation?.id, { ...workerVacation, worker: workerVacation?.worker?.id, used_days: newUsedDays })
                .catch(() => { setAbsenceModalError('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.error_updating_vacation'); });
            });
        }
        setDeleteData(null);
        setShowConfirmationDialog(false);
        fetchAbsences();
        updateAbsenceAndVacationDays();
      });
  };

  const handleTableRowClick = (row) => {
    setAbsenceTableModalData(row);
    setAbsenceTablePage(1);
  };

  const tableColumnConfig = [
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.start_date')}</span>,
      accessor: 'start_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.end_date')}</span>,
      accessor: 'end_date',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.number_of_days')}</span>,
      accessor: 'number_of_days',
      Cell: (row) => (row?.value || '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.created_at')}</span>,
      accessor: 'created_at',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
    {
      Header: () => <span>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.updated_at')}</span>,
      accessor: 'updated_at',
      Cell: (row) => (row?.value ? moment(row.value).format(defaultDateFormat) : '-'),
      style: { cursor: 'default' },
    },
  ];

  return (
    <div className="worker_details_absence__table">
    {
      absenceTablePage === 0 ?
        <>
          <div className="worker_details_absence__table__filters">
            <Select
              options={absenceOptions}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isSearchable
              placeholder={t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.select_absence_type')}
              onChange={(opt) => handleFilterChange('selectedAbsenceOption', opt)}
              value={sortingAndFiltering?.selectedAbsenceOption || ''}
              styles={selectStyles}
            />
            <div className="worker_details_absence__table__filters__add">
              <Button type="add" onClick={handleAddAbsence} disabled={isReadOnly}>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.add_absence')}</Button>
            </div>
          </div>
          {
            sortingAndFiltering?.selectedAbsenceOption?.id === 'vacation' &&
              <div className="worker_details_absence__table__display_days">
                  <div>{`${t('page_content.workforce_management.worker_details.worker_absence_vacation_card.remaining_total_vacation')}: ${workerAbsencesAndVacations?.usedDays || 0}/${workerAbsencesAndVacations?.assignedDays || 0}`}</div>
              </div>
          }
          {
            sortingAndFiltering?.selectedAbsenceOption?.id === 'sick-leave' &&
              <div className="worker_details_absence__table__display_days">
                  <div>{`${t('page_content.workforce_management.worker_details.worker_absence_vacation_card.sick_leave_last_three_years')}: ${workerAbsencesAndVacations?.absencesCount || 0}`}</div>
              </div>
          }
          <Table
            style={{ userSelect: 'text' }}
            columns={tableColumnConfig}
            data={absenceTable?.data || []}
            defaultPageSize={15}
            loading={absenceTable.isLoading}
            minRows={0}
            noDataText={t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.no_data_found')}
            enableEdit
            enableDelete
            onEdit={(row) => handleTableRowClick(row)}
            onDelete={(row) => handleShowConfirmationModal(row)}
            isActionsDisabled={isReadOnly}
            defaultSorted={[{ id: 'updated_at', desc: true }]}
            onSortedChange={(newSorted) => { handleSorting(newSorted[0]); }}
          />
          <TableButtons
            previous={absenceTable.previous}
            next={absenceTable.next}
            count={absenceTable.count}
            fetchFunction={fetchPaginatedAbsences}
          />
        </>
        : absenceTablePage === 1 &&
          <>
            <div className="worker_details_absence__table__filters">
              <div className="worker_details_absence__table__filters__back">
                  <Button onClick={handleBackToAbsences}>{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.back')}</Button>
              </div>
            </div>
            {
              absenceTableModalData?.absence_type === 'vacation' &&
                <div className="worker_details_absence__table__display_days">
                    <div>{`${t('page_content.workforce_management.worker_details.worker_absence_vacation_card.remaining_total_vacation')}: ${workerAbsencesAndVacations?.usedDays || 0}/${workerAbsencesAndVacations?.assignedDays || 0}`}</div>
                </div>
            }
            {
              absenceTableModalData?.absence_type === 'sick-leave' &&
                <div className="worker_details_absence__table__display_days">
                    <div>{`${t('page_content.workforce_management.worker_details.worker_absence_vacation_card.sick_leave_last_three_years')}: ${workerAbsencesAndVacations?.absencesCount || 0}`}</div>
                </div>
            }
            <div className="worker_details_absence__table__add_edit_form">
              <div className="modal_row">
                  <div className="left_text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.absence_type')}:*</div>
                  <div className="right_select">
                    <Select
                      options={absenceOptions}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.id}
                      isSearchable
                      placeholder={t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.select_absence_type')}
                      onChange={(opt) => handleAbsenceModalChange('absence_type', opt?.id)}
                      value={absenceOptions.find((aO) => aO.id === absenceTableModalData?.absence_type) || ''}
                      styles={selectModalStyles}
                    />
                  </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.start_date')}:*</div>
                <div className="right_datePicker">
                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      placeholderText={t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.select_start_date')}
                      selected={absenceTableModalData?.start_date ? moment(absenceTableModalData.start_date).toDate() : null}
                      onChange={(date) => handleAbsenceModalChange('start_date', date)}
                      locale={getLocale(t)}
                    />
                  </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.end_date')}:*</div>
                <div className="right_datePicker">
                  <DatePicker
                    dateFormat="dd.MM.yyyy"
                    placeholderText={t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.select_end_date')}
                    selected={absenceTableModalData?.end_date ? moment(absenceTableModalData.end_date).toDate() : null}
                    onChange={(date) => handleAbsenceModalChange('end_date', date)}
                    locale={getLocale(t)}
                    minDate={absenceTableModalData?.start_date ? moment(absenceTableModalData?.start_date).startOf('year').toDate() : null}
                    maxDate={absenceTableModalData?.start_date ? moment(absenceTableModalData?.start_date).endOf('year').toDate() : null}
                  />
                </div>
              </div>
              <div className="modal_row">
                <div className="left_text">{t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.number_of_days')}:*</div>
                <div className="right_input">
                    <input type="number" value={absenceTableModalData?.number_of_days || ''} onChange={(e) => handleAbsenceModalChange('number_of_days', e.target.value)} />
                </div>
              </div>
              <div className="modal_row">
                <div style={{ marginLeft: 'auto' }}>
                    <Button
                      type="add"
                      disabled={!absenceTableModalData?.absence_type || !absenceTableModalData?.start_date || !absenceTableModalData?.end_date || !absenceTableModalData?.number_of_days}
                      onClick={handleSaveAbsence}
                    >
                      {t('page_content.workforce_management.worker_details.worker_absence_vacation_card.absence_tab.save')}
                    </Button>
                </div>
              </div>
              {absenceModalError && <span className="worker_details_absence__table__add_edit_form__error">{absenceModalError}</span>}
            </div>
          </>
    }
    <ConfirmationModal
      itemName={deleteData?.start_date && deleteData?.end_date ? `${moment(deleteData.start_date).format(defaultDateFormat)} - ${moment(deleteData.end_date).format(defaultDateFormat)}` : ''}
      showModal={showConfirmationDialog}
      handleCloseModal={() => setShowConfirmationDialog(false)}
      handleConfirmModal={handleDelete}
      type="warning"
    />
  </div>
  );
};

Absences.propTypes = {
  t: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  workerId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.object.isRequired,
  workerAbsencesAndVacations: PropTypes.object.isRequired,
  updateAbsenceAndVacationDays: PropTypes.func.isRequired,
};

export default (withTranslation()(Absences));
