import React, { useState } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { getLocale } from 'shared/DatePicker/constants';
import { Button } from 'shared';
import '../styles.scss';

import { setInitialVacations } from '../../../actions';

const ControlPanelActions = ({ t, companyId }) => {
  const defaultFormData = {
    company: companyId,
    year: moment().add(1, 'year').format('YYYY'),
    default_days: '',
    last_year: true,
  };
  const [formData, setFormData] = useState(defaultFormData);

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handleSave = async () => {
    await setInitialVacations(formData)
      .then(() => {
        setFormData(defaultFormData);
      });
  };

  return (
    <div className="control_panel_actions_container">
        <h3>{t('page_content.human_resources.control_panel.actions.header_assign_new_year_vacation_days')}</h3>

        <div className="modal_row">
            <div className="left_text">{t('page_content.human_resources.control_panel.actions.datepicker_year')}: *</div>
            <div className="right_datePicker">
                <DatePicker
                  dateFormat="yyyy"
                  showYearPicker
                  selected={formData?.year ? moment(formData.year, 'YYYY').toDate() : null}
                  onChange={(date) => handleChange('year', moment(date).format('YYYY'))}
                  locale={getLocale(t)}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
            </div>
        </div>

        <div className="modal_row">
            <div className="left_text">{t('page_content.human_resources.control_panel.actions.input_default_days_to_assign')}: *</div>
            <div className="right_input">
                <input
                  type="text"
                  value={get(formData, 'default_days', '')}
                  onChange={(e) => handleChange('default_days', e.target.value)}
                  onInput={(e) => {
                    const value = e.target.value;
                    e.target.value = value.replace(/[^0-9]/g, '');
                  }}
                />
            </div>
        </div>

        <div className="modal_row">
            <div className="left_text">{t('page_content.human_resources.control_panel.actions.checkbox_take_available_vacation_days_from_last_year')}:</div>
            <div className="right_checkbox">
                <input
                  type="checkbox"
                  checked={get(formData, 'last_year', '')}
                  onChange={(e) => handleChange('last_year', e.target.checked)}
                />
            </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              type="add"
              onClick={handleSave}
              disabled={!formData?.year || !formData?.default_days}
            >
                {t('page_content.human_resources.control_panel.actions.button_save')}
            </Button>
        </div>
    </div>
  );
};

ControlPanelActions.propTypes = {
  t: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default (withTranslation()(ControlPanelActions));
