import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Tooltip from 'rc-tooltip';
import update from 'immutability-helper';
import { SketchPicker } from 'react-color';
import { IconRemove, IconInfo } from 'shared/Icons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Select from 'react-select';
import {
  Button,
} from 'shared';
import DatapointForm from '../DatapointForm';
import CalculatedValueForm from '../CalculatedValueForm';
import CalculatedValueUsageForm from '../CalculatedValueUsageForm';
import { defaultSettings } from '../constants';
import '../styles.scss';
import { usingVariablesTooltip } from '../../../constants';

const colorIndex = [
  '#396ab1',
  '#da7c30',
  '#3e9651',
  '#cc2529',
  '#535154',
  '#6b4c9a',
  '#922428',
  '#8f8b3d',
];

class PieChartForm extends Component {
  constructor(props) {
    super(props);

    const widget_data = props.config.widget_data.map((item) => { item.randId = Math.random(); return item; });
    const widgetData = widget_data.filter((wD) => !wD.style.isCalculatedValue && !wD.style.isCalculatedValueUsage);
    const calculatedWidgetData = widget_data.filter((wD) => wD.style.isCalculatedValue).map((item) => { item.randId = Math.random(); return item; }) || [];
    const calculatedUsageWidgetData = widget_data.find((wD) => wD.style.isCalculatedValueUsage);

    this.state = {
      id: props.config.id,
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings: props.config.settings || defaultSettings.pie,
      title: props.config.title || '',
      type: props.config.type,
    };
  }

  addWidgetDataItem = () => {
    this.setState((prevState) => {
      const widgetData = [...prevState.widgetData];
      widgetData.push({
        randId: Math.random(),
        metric: null,
        field: null,
        operation: 'last',
        group_by: 'auto',
        style: {
          selectedField: null,
        },
      });
      return { widgetData };
    });
  }

  addCalculatedWidgetDataItem = () => {
    const calculatedWidgetData = this.state.calculatedWidgetData.slice(0);
    calculatedWidgetData.push({
      randId: Math.random(),
      metric: null,
      field: null,
      style: {
        selectedField: null,
        alias: '',
        isCalculatedValue: true,
      },
      group_by: 'auto',
      operation: 'avg',
    });

    const calculatedValueUsageWidgetDataItemExists = calculatedWidgetData.find((wD) => wD.style.isCalculatedValueUsage);
    if (!calculatedValueUsageWidgetDataItemExists) {
      this.setState({
        calculatedWidgetData,
        calculatedUsageWidgetData: {
          metric: null,
          field: null,
          style: {
            selectedField: null,
            alias: '',
            isCalculatedValueUsage: true,
          },
          group_by: 'auto',
          operation: 'avg',
        },
      });
    } else {
      this.setState({ calculatedWidgetData });
    }
  }

  updateWidgetDataItem = (numberInArray, widgetDataItem) => {
    this.setState((prevState) => {
      const widgetData = [...prevState.widgetData];
      widgetData[numberInArray] = widgetDataItem;
      return { widgetData };
    });
  }

  updateCalculatedWidgetDataItem = (numberInArray, widgetDataItem) => {
    this.setState((prevState) => {
      const calculatedWidgetData = [...prevState.calculatedWidgetData];
      calculatedWidgetData[numberInArray] = widgetDataItem;
      return { calculatedWidgetData };
    });
  }

  updateCalculatedUsageWidgetDataItem = (calculatedUsageWidgetData) => {
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateAdjustedValue = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.adjusted_value)) {
      settings.adjusted_value = [];
    }
    const existingValue = settings.adjusted_value.find((aV) => aV.metricId === metricId && aV.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.adjusted_value.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateAdjustedValueForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.adjusted_value = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateShiftFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.shift)) {
      settings.shift = [];
    }
    const existingValue = settings.shift.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.shift.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateShiftFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.shift = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateProductTypeFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.productType = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  updateProductTypeFilter = (val, id, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.productType)) {
      settings.productType = [];
    }
    const existingValue = settings.productType.find((w) => w.id === id && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.productType.push({ value: val, id, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilter = (val, metricId, indexData) => {
    const { settings } = this.state;
    if (!Array.isArray(settings.where)) {
      settings.where = [];
    }
    const existingValue = settings.where.find((w) => w.metricId === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = val;
    } else {
      settings.where.push({ value: val, metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWhereFilterForCalc = (val) => {
    const {
      calculatedUsageWidgetData,
    } = this.state;

    calculatedUsageWidgetData.style.where = val;
    this.setState({
      calculatedUsageWidgetData,
    });
  }

  removeWidgetDataItem = (elementRandomId) => {
    const widgetData = this.state.widgetData.slice(0);
    const filteredWidgetData = widgetData.filter((widgetItem) => {
      return widgetItem.randId !== elementRandomId;
    });
    // widgetData.splice(numberInArray, 1);
    this.setState({
      widgetData: filteredWidgetData,
    });
  }

  removeCalculatedWidgetDataItem = (elementRandomId) => {
    const {
      calculatedWidgetData,
    } = this.state;

    const newCalculatedWidgetData = calculatedWidgetData.slice(0);
    const filteredCalculatedWidgetData = newCalculatedWidgetData.filter((widgetItem) => {
      return widgetItem.randId !== elementRandomId;
    });
    // newCalculatedWidgetData.splice(numberInArray, 1);

    if (!filteredCalculatedWidgetData || !filteredCalculatedWidgetData.length) {
      this.setState({
        calculatedUsageWidgetData: null,
        calculatedWidgetData: filteredCalculatedWidgetData,
      });
    } else {
      this.setState({
        calculatedWidgetData: filteredCalculatedWidgetData,
      });
    }
  }

  saveWidget = () => {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
    } = this.state;

    let calcMetricsOK = true;
    if (calculatedWidgetData && calculatedWidgetData.length) {
      calculatedWidgetData.forEach((cWD) => {
        if (!cWD.metric) {
          calcMetricsOK = false;
        }
      });
    }

    if (!calcMetricsOK) {
      alert('All calculated values must have a selected source and metric.');
      return;
    }

    const widget_data = widgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    const cleanedCalculatedWidgetData = calculatedWidgetData.map((x) => {
      if (x.metric && x.metric.id) {
        x.metric = x.metric.id;
      }
      if (Object.prototype.hasOwnProperty.call(x, 'field')) {
        delete x.field;
      }
      return x;
    });

    if (calculatedUsageWidgetData) {
      if (Object.prototype.hasOwnProperty.call(calculatedUsageWidgetData, 'field')) {
        delete calculatedUsageWidgetData.field;
      }
      delete calculatedUsageWidgetData.metric;

      widget_data.push(calculatedUsageWidgetData);
    }

    const data = {
      widget_data: widget_data.concat(cleanedCalculatedWidgetData),
      id: this.state.id,
      title: this.state.title,
      settings: this.state.settings,
    };
    this.props.onSave(data);
  }

  updateDepartmentFilter = (value, metricId, indexData) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.department)) {
        settings.department = settings.department.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.department)) {
      settings.department = [];
    }
    const existingValue = settings.department.find((w) => w.id === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.department.push({ value, id: metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  updateWorkOperationsFilter = (value, metricId, indexData) => {
    const { settings } = this.state;
    if (!value) {
      if (Array.isArray(settings?.workOperation)) {
        settings.workOperation = settings.workOperation.filter((d) => !(d.id === metricId && d.indexData === indexData));
      }
      this.setState({
        settings: JSON.parse(JSON.stringify(settings)),
      });
      return;
    }
    if (!Array.isArray(settings.workOperation)) {
      settings.workOperation = [];
    }
    const existingValue = settings.workOperation.find((w) => w.id === metricId && w.indexData === indexData);
    if (existingValue) {
      existingValue.value = value;
    } else {
      settings.workOperation.push({ value, id: metricId, indexData });
    }

    this.setState({
      settings: JSON.parse(JSON.stringify(settings)),
    });
  }

  render() {
    const {
      widgetData,
      calculatedWidgetData,
      calculatedUsageWidgetData,
      settings,
      title,
      type,
    } = this.state;

    const { locationId, t } = this.props;
    const titleSides = ['left', 'center', 'right'];

    return (
      <div className="default-form">
        <table className="widget-title">
          <tr>
            <td>
              <label htmlFor="widget-title">
                {t('page_content.dashboards.edit_widget_modal.widget_title')}
              </label>
            </td>
            <td style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
              <input
                id="widget-title"
                onChange={(e) => this.setState({ title: e.target.value })}
                placeholder={t('page_content.dashboards.edit_widget_modal.widget_title_placeholder')}
                type="text"
                value={title}
              />
              <Tooltip
                id="tooltip-zoom"
                trigger={['hover']}
                placement="left"
                overlay={usingVariablesTooltip(t)}
                overlayClassName="where-filter-tooltip"
              >
                <span aria-describedby="tooltip-zoom">
                  <IconInfo color="#2e86de" height="15px" width="17px" />
                </span>
              </Tooltip>
            </td>
          </tr>
        </table>
        <Tabs>
          <TabList>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.datapoints')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.calculated_values')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.legend')}
            </Tab>
            <Tab>
              {t('page_content.dashboards.edit_widget_modal.tabs.appearance')}
            </Tab>
          </TabList>
          <TabPanel>
            {
              widgetData.map((widgetDataItem, i) => (
                <div key={`${widgetDataItem.randId}`} data-reactid={`${widgetDataItem.randId}`} className="datapoint-container">
                  <DatapointForm
                    type={type}
                    key={`widget-data-item-${widgetDataItem.randId}`}
                    // id={i}
                    indexData={i}
                    datapoint={widgetDataItem}
                    onChange={(item) => this.updateWidgetDataItem(i, item)}
                    locationId={locationId}
                    adjustedValue={Array.isArray(settings.adjusted_value) ? settings.adjusted_value.find((aV) => { if (widgetDataItem.metric) { return aV.metricId === widgetDataItem.metric.id && aV.indexData === i; } return false; }) : null}
                    updateAdjustedValue={(val, metricId, indexData) => this.updateAdjustedValue(val, metricId, indexData)}
                    shift={Array.isArray(settings.shift) ? settings.shift.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateShiftFilter={(val, id, indexData) => this.updateShiftFilter(val, id, indexData)}
                    productType={Array.isArray(settings.productType) ? settings.productType.find((w) => { if (widgetDataItem.metric) { return (w.id === widgetDataItem.metric.id && w.indexData === i); } return false; }) : null}
                    updateProductTypeFilter={(val, id, indexData) => this.updateProductTypeFilter(val, id, indexData)}
                    where={Array.isArray(settings.where) ? settings.where.find((w) => { if (widgetDataItem.metric) { return w.metricId === widgetDataItem.metric.id && w.indexData === i; } return false; }) : null}
                    updateWhereFilter={(val, metricId, indexData) => this.updateWhereFilter(val, metricId, indexData)}
                    updateDepartmentFilter={(value, metricId, indexData) => this.updateDepartmentFilter(value, metricId, indexData)}
                    department={Array.isArray(settings.department) ? settings.department.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                    updateWorkOperationsFilter={(value, metricId, indexData) => this.updateWorkOperationsFilter(value, metricId, indexData)}
                    workOperation={Array.isArray(settings.workOperation) ? settings.workOperation.find((w) => { if (widgetDataItem.metric) { return w.id === widgetDataItem.metric.id; } return false; }) : null}
                  />
                  <Button
                    onClick={() => this.removeWidgetDataItem(widgetDataItem.randId)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              widgetData.length < 10 &&
              <Button
                onClick={this.addWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.datapoints_tab.new_datapoint_button')}
              </Button>
            }
          </TabPanel>
          <TabPanel>
            {
              calculatedWidgetData.map((widgetDataItem, i) => (
                <div key={`${widgetDataItem.randId}`} data-reactid={`${widgetDataItem.randId}`} className="datapoint-container">
                  <CalculatedValueForm
                    key={`calc-val-${widgetDataItem.randId}`}
                    datapoint={widgetDataItem}
                    onChange={(item) => this.updateCalculatedWidgetDataItem(i, item)}
                    locationId={locationId}
                    alias={widgetDataItem.style.alias}
                  />
                  <Button
                    onClick={() => this.removeCalculatedWidgetDataItem(widgetDataItem.randId)}
                    size="small"
                    type="delete"
                  >
                    <IconRemove
                      height="14px"
                      width="14px"
                    />
                  </Button>
                </div>
              ))
            }
            {
              calculatedWidgetData.length <= 15 &&
              <Button
                onClick={this.addCalculatedWidgetDataItem}
                type="add"
              >
                {t('page_content.dashboards.edit_widget_modal.calculated_values_tab.new_calculated_value_button')}
              </Button>
            }
            {
              calculatedWidgetData.length > 0 &&
              <CalculatedValueUsageForm
                datapoint={calculatedUsageWidgetData}
                onChange={(item) => this.updateCalculatedUsageWidgetDataItem(item)}
                locationId={locationId}
                adjustedValue={calculatedUsageWidgetData.style.adjusted_value}
                updateAdjustedValue={(val) => this.updateAdjustedValueForCalc(val)}
                where={calculatedUsageWidgetData.style.where}
                updateWhereFilter={(val) => this.updateWhereFilterForCalc(val)}
                shift={calculatedUsageWidgetData.style.shift}
                updateShiftFilter={(val) => this.updateShiftFilterForCalc(val)}
                productType={calculatedUsageWidgetData.style.productType}
                updateProductTypeFilter={(val) => this.updateProductTypeFilterForCalc(val)}
                gapFill={calculatedUsageWidgetData.style.gapFill}
                updateGapFill={(val) => this.updateGapFillForCalc(val)}
              />
            }
          </TabPanel>
          <TabPanel>
            <table>
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.legend_tab.show_legend')}
                  </td>
                  <td>
                    <input
                      checked={settings.legend.show}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          legend: {
                            show: {
                              $set: !settings.legend.show,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </TabPanel>
          <TabPanel>
            <table className="widget-extras">
              <tbody>
                <tr>
                  <td>
                    {t('page_content.dashboards.edit_widget_modal.appearance_tab.show_tooltip')}
                  </td>
                  <td>
                    <input
                      checked={settings.appearance.tooltip}
                      onChange={() => this.setState({
                        settings: update(settings, {
                          appearance: {
                            tooltip: {
                              $set: !settings.appearance.tooltip,
                            },
                          },
                        }),
                      })}
                      type="checkbox"
                    />
                  </td>
                </tr>
                <tr>
                  <td className="label">
                    <label htmlFor="side">
                      {t('page_content.dashboards.edit_widget_modal.appearance_tab.align_title_text')}
                    </label>
                  </td>
                  <td className="input">
                    <Select
                      id="side"
                      className="AscaliaSelect"
                      onChange={(v) => {
                        const value = v.value;
                        this.setState({
                          settings: update(settings, {
                            appearance: {
                              title: {
                                $set: value,
                              },
                            },
                          }),
                        });
                      }}
                      options={titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      }))}
                      value={settings && settings.appearance && settings.appearance.title ? titleSides.map((dP) => ({
                        label: dP,
                        value: dP,
                      })).find((dP) => dP.value === settings.appearance.title) : null}
                    />
                  </td>
                </tr>
                {
                  widgetData.map((wD, i) => {
                    return (<tr>
                      <td>
                        {wD.label}
                      </td>
                      <td>
                        <SketchPicker
                          color={settings.pieColors ? settings.pieColors[wD.id] : colorIndex[i]}
                          onChangeComplete={(color) => {
                            if (!settings.pieColors) {
                              settings.pieColors = {};
                            }
                            const newPieColors = settings.pieColors;
                            newPieColors[wD.id] = color;
                            this.setState({
                              settings: update(settings, {
                                pieColors: {
                                  $set: newPieColors,
                                },
                              }),
                            });
                          }}
                        />
                      </td>
                    </tr>);
                  })
                }
              </tbody>
            </table>
          </TabPanel>
        </Tabs>
        <footer className="modal-footer">
          <Button
            onClick={this.saveWidget}
            title="Save widget config"
            type="success"
          >
            {t('page_content.dashboards.edit_widget_modal.save_changes_button')}
          </Button>
          <Button
            onClick={this.props.closeModal}
          >
            {t('page_content.dashboards.edit_widget_modal.cancel_button')}
          </Button>
        </footer>
      </div>
    );
  }
}

PieChartForm.propTypes = {
  locationId: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(PieChartForm);
