import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import moment from 'moment';

import {
  ComposedChart as RePercentAreaChart,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  CartesianGrid,
  ReferenceArea,
  ReferenceDot,
  Legend,
  ReferenceLine,
  Label,
} from 'recharts';
import { formatTypes } from 'shared/constants';
import WidgetPlaceholder from '../../WidgetPlaceholder';
import {
  formatTooltipDates,
  formatTicks,
  formatTooltip,
} from '../helpers';

import { timeRangeEnum, timeRangeConfig } from '../../../DatePicker/constants';

const colorIndex = [
  '#1abc9c',
  '#2980b9',
  '#e67e22',
  '#e74c3c',
  '#9b59b6',
  '#2ecc71',
  '#34495e',
  '#8e44ad',
  '#2c3e50',
  '#7f8c8d',
  '#c0392b',
  '#d35400',
  '#f39c12',
];

class PercentAreaChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      refAreaLeft: '',
      refAreaRight: '',
    };

    this.handleMouseMove = this.handleMouseMove.bind(this);
  }

  getPercent = (value, total) => {
    const ratio = total > 0 ? value / total : 0;
    return parseFloat(ratio.toFixed(4)) * 100;
  };

  toPercent = (decimal, fixed = 0) => `${(decimal * 100).toFixed(fixed)}%`;

  legendRender = () => {
    const { widgetData } = this.props;
    if (widgetData === null) {
      return (<ul />);
    }
    return (
      <ul className="recharts-default-legend" style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
        {
          widgetData.map((widgetItem, index) => {
            // <li key={`item-${index}`}>{entry.value}</li>
            const widgetColor = (widgetItem.style && widgetItem.style.color && widgetItem.style.color.r && widgetItem.style.color.g && widgetItem.style.color.b) ? `rgb(${widgetItem.style.color.r}, ${widgetItem.style.color.g}, ${widgetItem.style.color.b})` : colorIndex[index];
            return (
              <li key={`item-${index}`} className="recharts-legend-item legend-item-0" style={{ display: 'inline-block', marginRight: '10px', cursor: 'default' }}>
                <svg className="recharts-surface" width="14" height="14" viewBox="0 0 32 32" version="1.1" style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '4px' }}>
                  <path stroke="none" fill={widgetColor} d="M0,4h32v24h-32z" className="recharts-legend-icon" />
                </svg>
                <span className="recharts-legend-item-text" style={{ fontSize: '12px' }}>
                  {`${widgetItem.label}`}
                </span>
              </li>
            );
          })
        }
      </ul>
    );
  };

  tooltipRenderer = (o) => {
    // eslint-disable-next-line
    const { payload } = o;
    const {
      widgetData,
      meta: {
        daterange: {
          start,
          end,
          label,
        },
        range,
      },
    } = this.props;
    if (payload === null || widgetData === null) {
      return (<ul />);
    }
    const total = payload.reduce((result, entryItem) => result + entryItem.value, 0);

    let to = end;
    let from = start;
    const rangeLabel = label;

    if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
      to = timeRangeConfig()[rangeLabel].endDate;
      from = timeRangeConfig()[rangeLabel].startDate;
    }

    const timeRangeStart = from.valueOf();
    const timeRangeEnd = to.valueOf();
    const timeRange = range
      ? [range[0].valueOf(), range[1].valueOf()]
      : [timeRangeStart, timeRangeEnd];

    return (
      <div style={{ backgroundColor: 'white', border: '1px solid lightgray', padding: '12px' }}>
        <p style={{ fontSize: '12px', margin: '0', padding: '0', marginBottom: '6px' }}>{formatTooltipDates(o.label, { dateFrom: timeRange[0], dateTo: timeRange[1] })}</p>
        <ul style={{ listStyleType: 'none', margin: '0', padding: '0' }}>
          {
            payload.map((payloadEntry, index) => {
              const widgetItem = widgetData.find((endpoint) => endpoint.id === parseInt(payloadEntry.name, 10));
              const widgetColor = (widgetItem && widgetItem.style && widgetItem.style.color && widgetItem.style.color.r && widgetItem.style.color.g && widgetItem.style.color.b) ? `rgb(${widgetItem.style.color.r}, ${widgetItem.style.color.g}, ${widgetItem.style.color.b})` : colorIndex[index];
              return (
                <li key={`item-${index}`} style={{ fontSize: '12px', color: widgetColor, marginBottom: payload.length === index + 1 ? '0' : '10px' }}>
                  {`${widgetItem.label}: ${formatTooltip(payloadEntry.value)} (${this.getPercent(payloadEntry.value, total).toFixed(2)})%`}
                </li>
              );
            })
          }
        </ul>
      </div>
    );
  };

  prepareData(data = {}, widgetData = []) {
    const newData = [];

    Object.keys(data)
      .filter((key) => key !== 'events')
      .forEach((key) => {
        const currentWidgetData = widgetData.find((x) => String(x.id) === key);
        let valueKey = 'value';

        if (currentWidgetData) {
          const {
            style: {
              selectedField,
            },
          } = currentWidgetData;

          valueKey = selectedField || 'value';
        }

        data[key]
          .forEach((entry) => {
            let { time: timeString } = entry;
            if (!timeString) {
              timeString = entry.ts;
            }
            const timeValue = moment(timeString).valueOf();
            const value = entry[valueKey];

            const findIndex = newData.findIndex((x) => x.time === timeValue);
            if (findIndex < 0) {
              newData.push({ time: timeValue, [key]: value });
            } else {
              newData[findIndex][key] = value;
            }
          });
      });

    newData.sort((a, b) => a.time - b.time);

    const percentData = [];
    newData.forEach((entry) => {
      // eslint-disable-next-line
      const { time, ...entryValues } = entry;
      const entryFinal = { time: entry.time };
      Object.entries(entryValues).forEach((entryObj) => {
        const [entryKey, entryValue] = entryObj;
        entryFinal[entryKey] = entryValue;
      });
      percentData.push(entryFinal);
    });

    // return newData;
    return percentData;
  }

  zoom = () => {
    let { refAreaLeft, refAreaRight } = this.state;

    if (refAreaLeft === refAreaRight || refAreaRight === '') {
      this.setState(() => ({
        refAreaLeft: '',
        refAreaRight: '',
      }));
      return;
    }

    // xAxis domain
    if (refAreaLeft > refAreaRight) {
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];
    }

    this.props.setDateRange({
      label: 'custom',
      start: moment(refAreaLeft),
      end: moment(refAreaRight),
    });

    this.setState({
      refAreaLeft: '',
      refAreaRight: '',
    });
  }

  handleMouseMove = throttle((e) => {
    if (!e) {
      return;
    }
    const { refAreaLeft } = this.state;
    if (refAreaLeft) {
      this.setState({ refAreaRight: e.activeLabel });
    }
  }, 30);

  renderEventReferences = (eventsData = {}, yAxisId) => {
    const res = [];

    const resetEventDetails = (e, entry, formattedTime) => {
      const group = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}`);
      const text = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}-text`);
      if (group && text) {
        if (group.x === e.cx) {
          group.setAttribute('x', 1000);
          group.setAttribute('y', 1000);
          text.setAttribute('x', 1000);
          text.setAttribute('y', 1000);
          text.setAttribute('fill', 'transparent');
        } else {
          group.setAttribute('x', e.cx);
          group.setAttribute('y', e.cy);
          text.setAttribute('x', e.cx);
          text.setAttribute('y', e.cy);
          text.setAttribute('fill', '#000000');
        }
      }
    };

    Object.keys(eventsData).forEach((metricId, index) => {
      eventsData[metricId].forEach((entry) => {
        const formattedTime = moment(entry.time).valueOf();
        let color = colorIndex[index % (colorIndex.length - 1)];
        if (entry.log_level === 'warning') {
          color = 'yellow';
        } else if (entry.log_level === 'error') {
          color = 'red';
        }
        res.push(<ReferenceDot
          yAxisId={yAxisId}
          x={formattedTime}
          y={entry.threshold || 0}
          isFront
          stroke="none"
          fill={color}
          label={
            <g id={`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}`} x={1000} y={1000} onClick={(e) => resetEventDetails(e, entry, formattedTime)}>
              <text fontWeight="bold" fontSize="13" fill="transparent" id={`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}-text`} textAnchor="middle" dominantBaseline="central">{entry.value}</text>
            </g>
          }
          onClick={(e) => {
            const group = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}`);
            const text = document.getElementById(`${formattedTime}-${entry && entry.value ? entry.value.split(' ').join('') : ''}-text`);
            if (group.x === e.cx) {
              group.setAttribute('x', 1000);
              group.setAttribute('y', 1000);
              text.setAttribute('x', 1000);
              text.setAttribute('y', 1000);
              text.setAttribute('fill', 'transparent');
            } else {
              group.setAttribute('x', e.cx);
              group.setAttribute('y', e.cy);
              text.setAttribute('x', e.cx);
              text.setAttribute('y', e.cy);
              text.setAttribute('fill', '#000000');
            }
          }}
        />);
      });
    });
    return res;
  }

  render() {
    const {
      refAreaLeft,
      refAreaRight,
    } = this.state;

    const {
      data,
      widgetData,
      height,
      width,
      settings,
      meta: {
        daterange: {
          start,
          end,
          label,
        },
        range,
        // sync,
        // unit,
      },
      enableZoom,
      eventsData,
    } = this.props;

    if (!settings) {
      return <WidgetPlaceholder height={this.props.height} type="percent" />;
    }

    const graphData = this.prepareData(data, widgetData);

    let fetchEvents = null;
    if (settings.legend && settings.legend.events) {
      fetchEvents = settings.legend.events;
    }

    let to = end;
    let from = start;
    const rangeLabel = label;

    if (label !== timeRangeEnum.custom && label !== timeRangeEnum.nth_week && label !== timeRangeEnum.customYesterday && label !== timeRangeEnum.customToday) {
      to = timeRangeConfig()[rangeLabel].endDate;
      from = timeRangeConfig()[rangeLabel].startDate;
    }

    const timeRangeStart = from.valueOf();
    const timeRangeEnd = to.valueOf();
    const timeRange = range
      ? [range[0].valueOf(), range[1].valueOf()]
      : [timeRangeStart, timeRangeEnd];

    let leftYAxisId;
    const firstYAxis = 'yAxis-0';

    return (
      <RePercentAreaChart
        width={width}
        height={height}
        data={graphData}
        syncId="shared"
        syncMethod="value"
        stackOffset="expand"
        margin={{ top: 25, right: 40, left: 5, bottom: 5 }}
        onMouseDown={enableZoom && ((e) => {
          if (!e) {
            return;
          }
          this.setState({ refAreaLeft: e.activeLabel });
        })}
        onMouseMove={enableZoom && this.handleMouseMove}
        onMouseUp={enableZoom && this.zoom}
      >
        {
          (settings && settings.appearance && settings.appearance.grid) &&
          <CartesianGrid strokeDasharray="3 3" />
        }
        <XAxis
          dataKey="time"
          scale="time"
          tickFormatter={(tick) => formatTicks(tick, formatTypes.date, { dateFrom: timeRange[0], dateTo: timeRange[1] })}
          tick={{ fontSize: 10 }}
        />
        <YAxis
          tickFormatter={(value) => this.toPercent(value, 2)}
          tick={{ fontSize: 10 }}
        />
        {
          (refAreaLeft && refAreaRight) ?
            <YAxis
              hide
              key="yAxis-refarea"
              yAxisId="yAxis-refarea"
              name=""
              orientation={settings.axis.side ? settings.axis.side : 'left'}
              tick={{ fontSize: 10 }}
              width={90}
            /> : ''
        }
        {
          (settings && settings.appearance && settings.appearance.tooltip) &&
          <Tooltip content={this.tooltipRenderer} />
        }
        {
          widgetData.filter((wD) => !wD.style.isCalculatedValue).map(({
            metric,
            style,
            id,
          }, index) => {
            if (!metric) return '';
            const areaColor = (style && style.color && style.color.r && style.color.g && style.color.b) ? `rgb(${style.color.r}, ${style.color.g}, ${style.color.b})` : colorIndex[index];
            return (
              <Area
                key={`area-${id}`}
                type="monotone"
                dataKey={id}
                stackId={1}
                fill={areaColor}
                fillOpacity={(style && style.color && style.color.a) ? style.color.a : 1}
              />
            );
          })
        }
        {
          (settings && settings.legend && settings.legend.show) &&
          <Legend
            content={this.legendRender}
          />
        }
        {
          settings && settings.annotation && settings.annotation.value && firstYAxis ?
            <ReferenceLine
              key="line-widget-data-annotation"
              yAxisId={firstYAxis}
              y={parseFloat(settings.annotation.value)}
              stroke={settings.annotation.color || '#3182bd'}
              strokeWidth={settings.annotation.thickness || 1}
              strokeDasharray={settings.annotation.dashed ? '5 5' : '10000'}
            >
              <Label value={settings.annotation.value || 'Threshold'} position="right" style={{ fontSize: 11 }} fill="#666" />
            </ReferenceLine> : ''
        }
        {
          (refAreaLeft && refAreaRight)
            ? (
              <ReferenceArea
                yAxisId="yAxis-refarea"
                x1={refAreaLeft}
                x2={refAreaRight}
                strokeOpacity={0.3}
              />
            )
            : null
        }
        {
          fetchEvents &&
          this.renderEventReferences(eventsData, leftYAxisId)
        }
      </RePercentAreaChart>
    );
  }
}

PercentAreaChart.defaultProps = {
  data: {},
  widgetData: [],
  setDateRange: () => { },
  enableZoom: true,
  settings: {},
};

PercentAreaChart.propTypes = {
  data: PropTypes.object,
  widgetData: PropTypes.array,
  height: PropTypes.number,
  width: PropTypes.number,
  settings: PropTypes.object,
  setDateRange: PropTypes.func.isRequired,
  enableZoom: PropTypes.bool,
  eventsData: PropTypes.object,
  meta: PropTypes.object.isRequired,
};

export default PercentAreaChart;
