import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { IconUser } from 'shared/Icons';
import PropTypes from 'prop-types';
import * as userActions from 'user/actions';
import { Button } from '../../shared/index';
import Enable2FA from './Enable2FA/index';
import Disable2FA from './Disable2FA/index';

class TwoFactorAuth extends Component {
  constructor() {
    super();
    this.state = {
      show_form: false,
    };
  }

  handleShowForm = () => {
    this.setState({
      show_form: true,
    });
  }

  resetPage = () => {
    const { getCurrentUser } = this.props;

    getCurrentUser()
      .then(() => { this.setState({ show_form: false }); })
      .catch((err) => console.log(err));
  }

  render() {
    const { show_form } = this.state;
    const { currentUser, t } = this.props;

    return (
      <div>
        <div className="current_user">
          <IconUser
            height="60px"
            width="60px"
          />
          <h3>{currentUser.email}</h3>
        </div>
        {
          !show_form ?
            currentUser && currentUser.is_active_2fa ?
              <p>{t('user_profile_page.active_2FA_message')}</p> :
              <p style={{ color: 'rgba(255, 0, 0, 0.6)' }}>{t('user_profile_page.disabled_2FA_message')}</p> : ''
        }
        {
          !show_form &&
          <Button onClick={() => this.handleShowForm()} style={{ color: currentUser && currentUser.is_active_2fa && 'rgba(255, 0, 0, 0.8)' }}>
            {currentUser && currentUser.is_active_2fa ? t('user_profile_page.disable_2FA_button') : t('user_profile_page.enable_2FA_button')}
          </Button>
        }
        {
          show_form ?
            currentUser && currentUser.is_active_2fa ?
              <Disable2FA resetPage={this.resetPage} t={t} /> :
              <Enable2FA resetPage={this.resetPage} currentUser={currentUser} t={t} /> : ''
        }
      </div>
    );
  }
}

TwoFactorAuth.propTypes = {
  getCurrentUser: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  getCurrentUser: userActions.getCurrentUser,
};

export default withRouter(connect(null, mapDispatchToProps)(withTranslation()(TwoFactorAuth)));
