import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { modalSizes } from 'shared/constants';
import { Modal, ContentLoader } from 'shared';
import { getLocale } from 'shared/DatePicker/constants';
import { selectModalStyles } from 'styles/modules/reactSelect';
import { addExceptionDay, editExceptionDay, getDepartmentShifts } from '../actions';
import './styles.scss';

const ExceptionModal = ({
  t,
  isOpen,
  companyId,
  handleCloseModal,
  initialValues = {},
  closeModalAndRefetch,
  departments,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingShifts, setIsLoadingShifts] = useState(true);
  const [formValues, setFormValues] = useState(initialValues);
  const [shifts, setShifts] = useState([]);

  const fetchAllShifts = () => {
    setIsLoadingShifts(true);

    let apiFilters = '&limit=9999&order_by=department__name';

    if (formValues?.department) {
      apiFilters += `&department=${typeof formValues?.department === 'object' ? formValues?.department?.id : formValues?.department}`;
    }

    getDepartmentShifts(companyId, apiFilters)
      .then((res) => {
        setShifts(get(res, 'data.results', []));
        setIsLoadingShifts(false);
      })
      .catch(() => {
        setShifts([]);
        setIsLoadingShifts(false);
      });
  };

  useEffect(() => {
    fetchAllShifts();
  }, [formValues?.department]);

  const onChange = (key, value) => {
    if (key === 'is_working_day' && !value) {
      setFormValues((prevValues) => ({
        ...prevValues,
        begin_time: null,
        end_time: null,
        begin_break: null,
        end_break: null,
      }));
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  };

  const handleSave = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const payload = {
      ...formValues,
      company: companyId,
      date: moment(formValues.date).format('YYYY-MM-DD'),
      department: typeof formValues?.department === 'object' ? formValues?.department?.id : formValues?.department,
      department_shift: typeof formValues?.department_shift === 'object' ? formValues?.department_shift?.id : formValues?.department_shift,
    };

    if (formValues?.id) {
      await editExceptionDay(formValues?.id, companyId, payload);
    } else {
      await addExceptionDay(companyId, payload);
    }

    setIsLoading(false);
    closeModalAndRefetch();
  };

  return (
        <Modal
          isOpen={isOpen}
          handleSave={handleSave}
          size={modalSizes.medium}
          handleClose={handleCloseModal}
        >
            {isLoading ? (
                <ContentLoader />
            ) : (
                <div className="exception_modal_container">
                    <div className="modal_row">
                        <div className="left_text">{t('page_content.human_resources.shifts.exceptions.date')}:</div>
                        <div className="right_datePicker">
                        <DatePicker
                          dateFormat="dd.MM.yyyy"
                          selected={get(formValues, 'date', '') ? moment(formValues.date, 'HH:mm').toDate() : null}
                          onChange={(e) => onChange('date', e)}
                          showTimeSelect={false}
                          disabledKeyboardNavigation
                          locale={getLocale(t)}
                        />
                        </div>
                    </div>
                    <div className="modal_row">
                        <div className="left_text">{t('page_content.human_resources.shifts.exceptions.is_working_day')}:</div>
                        <div className="right_checkbox">
                            <input
                              type="checkbox"
                              checked={get(formValues, 'is_working_day', false)}
                              onChange={(e) => onChange('is_working_day', e.target.checked)}
                            />
                        </div>
                    </div>
                    <div className="modal_row">
                        <div className="left_text">{t('page_content.human_resources.shifts.exceptions.begin_time')}:</div>
                        <div className="right_datePicker">
                            <DatePicker
                              selected={get(formValues, 'begin_time', '') ? moment(formValues.begin_time, 'HH:mm').toDate() : null}
                              onChange={(time) => onChange('begin_time', time ? moment(time).format('HH:mm') : null)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption={t('date_picker_locale.time_translation')}
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              disabled={!get(formValues, 'is_working_day', false)}
                            />
                        </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.end_time')}:</div>
                        <div className="right_datePicker">
                            <DatePicker
                              selected={get(formValues, 'end_time', '') ? moment(formValues.end_time, 'HH:mm').toDate() : null}
                              onChange={(time) => onChange('end_time', time ? moment(time).format('HH:mm') : null)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption={t('date_picker_locale.time_translation')}
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              disabled={!get(formValues, 'is_working_day', false)}
                            />
                        </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.begin_break')}:</div>
                        <div className="right_datePicker">
                            <DatePicker
                              selected={get(formValues, 'begin_break', '') ? moment(formValues.begin_break, 'HH:mm').toDate() : null}
                              onChange={(time) => onChange('begin_break', time ? moment(time).format('HH:mm') : null)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption={t('date_picker_locale.time_translation')}
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              disabled={!get(formValues, 'is_working_day', false)}
                            />
                        </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.end_break')}:</div>
                        <div className="right_datePicker">
                            <DatePicker
                              selected={get(formValues, 'end_break', '') ? moment(formValues.end_break, 'HH:mm').toDate() : null}
                              onChange={(time) => onChange('end_break', time ? moment(time).format('HH:mm') : null)}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={1}
                              timeCaption={t('date_picker_locale.time_translation')}
                              timeFormat="HH:mm"
                              dateFormat="HH:mm"
                              disabled={!get(formValues, 'is_working_day', false)}
                            />
                        </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.department')}:</div>
                        <div className="right_select">
                            <div style={{ width: '100%' }}>
                                <Select
                                  options={departments}
                                  getOptionLabel={(option) => option.name}
                                  getOptionValue={(option) => option.id}
                                  isSearchable
                                  isClearable
                                  menuPosition="fixed"
                                  onChange={(val) => onChange('department', val)}
                                  value={get(formValues, 'department', null) ?? departments?.find((val) => val.id === formValues?.department?.id)}
                                  styles={selectModalStyles}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="modal_row">
                    <div className="left_text">{t('page_content.human_resources.shifts.exceptions.department_shift')}:</div>
                        <div className="right_select">
                            <div style={{ width: '100%' }}>
                                <Select
                                  options={shifts}
                                  getOptionLabel={(option) => `${option?.name} (${moment(option?.begin_time, 'HH:mm:ss').format('HH:mm')} - ${moment(option?.end_time, 'HH:mm:ss').format('HH:mm')})`}
                                  getOptionValue={(option) => option.id}
                                  isSearchable
                                  isClearable
                                  menuPosition="fixed"
                                  onChange={(val) => onChange('department_shift', val)}
                                  value={get(formValues, 'department_shift', null) ?? shifts?.find((val) => val.id === formValues?.department_shift?.id)}
                                  styles={selectModalStyles}
                                  isDisabled={!get(formValues, 'department', false)}
                                  isLoading={isLoadingShifts}
                                  loadingMessage={() => <ContentLoader />}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
  );
};

ExceptionModal.propTypes = {
  isOpen: PropTypes.bool,
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  handleCloseModal: PropTypes.func,
  closeModalAndRefetch: PropTypes.func,
  companyId: PropTypes.number.isRequired,
  departments: PropTypes.array,
};

const mapStateToProps = (state) => ({
  companyId: get(state, 'app.company.id'),
});

export default connect(mapStateToProps, null)(withTranslation()(ExceptionModal));
