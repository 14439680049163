import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import WorkersList from './components/WorkersList';
import WorkersAvailability from './components/WorkersAvailability';
import QualificationsAndProfessions from './components/QualificationsAndProfessions';

const Workers = ({
  t,
  hrTabs,
  department,
  locationId,
  companyId,
  customDepartment,
  userHaveFullAccess,
  managerDepartments,
  currentUserRole,
}) => {
  const shouldDisplayTab = (tabName) => {
    const defaultHrTabs = hrTabs || [];
    return defaultHrTabs.some((o) => o.name === tabName);
  };
  return (
      <Tabs>
        <TabList>
          <Tab>{t('page_content.human_resources.workers.workers_list')}</Tab>
          {shouldDisplayTab('Workers -> Workers Availability') && <Tab>{t('page_content.human_resources.workers.workers_availability')}</Tab>}
          {shouldDisplayTab('Workers -> Qualifications and professions') && <Tab>{t('page_content.human_resources.workers.qualifications_and_professions')}</Tab>}
        </TabList>
        <TabPanel>
            <WorkersList
              department={department}
              locationId={locationId}
              companyId={companyId}
              customDepartment={customDepartment}
              userHaveFullAccess={userHaveFullAccess}
              managerDepartments={managerDepartments}
              currentUserRole={currentUserRole}
            />
        </TabPanel>

        <TabPanel>
            <WorkersAvailability
              locationId={locationId}
              customDepartment={customDepartment}
              managerDepartments={managerDepartments}
              currentUserRole={currentUserRole}
            />
        </TabPanel>

        <TabPanel>
          <QualificationsAndProfessions
            companyId={companyId}
            locationId={locationId}
            userHaveFullAccess={userHaveFullAccess}
          />
          </TabPanel>
      </Tabs>
  );
};

Workers.propTypes = {
  t: PropTypes.func,
  department: PropTypes.number,
  currentUserRole: PropTypes.string,
  customDepartment: PropTypes.string,
  userHaveFullAccess: PropTypes.bool,
  hrTabs: PropTypes.array.isRequired,
  managerDepartments: PropTypes.array,
  companyId: PropTypes.number.isRequired,
  locationId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    hrTabs: get(state, 'app.companyConfig.config.hr_tabs', null),
  };
}

export default connect(mapStateToProps)(withTranslation()(Workers));
