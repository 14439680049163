import React, { Fragment, useEffect, useState } from 'react';
import { get } from 'lodash';
import Tooltip from 'rc-tooltip';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { IconInfo } from 'shared/Icons';
import { ContentLoader, Button, JsonEditor } from 'shared';
import { getCompanyData, editCompany } from '../../../actions';
import { getCompanyDescription, companyFormConfigurations } from '../helpers';
import '../styles.scss';

const CompanyConfig = ({ companyId }) => {
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [companyConfig, setCompanyConfig] = useState(null);
  const [jsonEditorError, setJsonEditorError] = useState(false);

  const fetchData = async () => {
    try {
      const res = await getCompanyData(companyId);
      setCompanyConfig(get(res, 'data.config', null));
      setIsFormUpdated(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const updateConfig = (key, value) => {
    if (companyConfig) {
      const updatedConfig = {
        ...companyConfig,
        [key]: value,
      };

      setIsFormUpdated(true);
      setCompanyConfig(updatedConfig);
    }
  };

  const handleSaveForm = async () => {
    try {
      setIsLoading(true);

      await editCompany(companyId, { config: { ...companyConfig } });
      fetchData();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const renderTooltip = (key) => (
    <Tooltip
      id="tooltip-zoom"
      trigger={['hover']}
      placement="left"
      overlay={<p>{getCompanyDescription(key)}</p>}
      overlayClassName="where-filter-tooltip"
    >
      <span aria-describedby="tooltip-zoom">
        <IconInfo color="#2e86de" height="15px" width="17px" />
      </span>
    </Tooltip>
  );

  const handleJSONEditorError = (value) => {
    if (value && value.length > 0) {
      setJsonEditorError(true);
    } else {
      setJsonEditorError(false);
    }
  };

  if (isLoading) {
    return <ContentLoader />;
  }

  return (
    <React.Fragment>
      <Button
        type="success"
        disabled={!isFormUpdated || jsonEditorError}
        onClick={handleSaveForm}
      >
        Save changes
      </Button>
      <div className="company-config">
        <form>
          <ul>
            {companyFormConfigurations.map((config) => (
            <Fragment key={config.key}>
              <li key={config.key}>
                <div className="title">
                  {renderTooltip(config.key)}
                  <label>{config.label}</label>
                </div>
                {config.type === 'checkbox' ? (
                  <input
                    type={config.type}
                    checked={get(companyConfig, config.key, config.defaultValue)}
                    onChange={(e) => updateConfig(config.key, e.target.checked)}
                  />
                ) : config.type === 'JSON' ? (
                  <div style={{ height: '200px', width: '42%' }}>
                    <JsonEditor
                      value={get(companyConfig, config.key, config.defaultValue)}
                      onChange={(e) => updateConfig(config.key, e)}
                      isError={handleJSONEditorError}
                    />
                  </div>
                ) : config.type === 'multiselect' ?
                  (
                  <div style={{ width: '42%' }}>
                    <Select
                      isMulti
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option.name}
                      options={config.options}
                      onChange={(selectedOptions) => updateConfig(config.key, selectedOptions)}
                      value={get(companyConfig, config.key, config.defaultValue)}
                    />
                  </div>
                  ) : (
                  <input
                    type={config.type}
                    value={get(companyConfig, config.key, config.defaultValue)}
                    onChange={(e) => updateConfig(config.key, e.target.value)}
                  />
                  )}
              </li>
              </Fragment>
            ))}
          </ul>
        </form>
      </div>
    </React.Fragment>
  );
};

CompanyConfig.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default withTranslation()(CompanyConfig);
