import api from 'helpers/api';
import { Notification } from 'shared';
import * as dashboardActions from 'shared/dashboard/actions';
import * as actionTypes from './actionTypes';

export const showAddWidget = dashboardActions.showAddWidget(actionTypes);

export const fetchDashboard = dashboardActions.fetchDashboard(actionTypes);

export const createWidget = dashboardActions.createWidget(actionTypes);

export const editWidget = dashboardActions.editWidget(actionTypes);

export const setLayouts = dashboardActions.setLayouts(actionTypes);

export const deleteWidget = dashboardActions.deleteWidget(actionTypes);

export const copyWidget = dashboardActions.copyWidget(actionTypes);

export const patchDashboardRangeChange = dashboardActions.patchDashboardRangeChange;

export const updateManualRefresh = dashboardActions.updateManualRefresh(actionTypes);

export const patchDashboard = dashboardActions.patchDashboard(actionTypes);

export const getOrderRange = async (order_id = '') => {
  try {
    const res = await api
      .get(`/api/v1/industry/production_data_order_range/?order_id=${order_id}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching order range', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getProductionDataOrders = async (companyId, locationId, filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/industry/production_data_orders/?company=${companyId}&location=${locationId}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching production data orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};

export const getOrders = async (companyId, location = '', filters = '') => {
  try {
    const res = await api
      .get(`/api/v1/orders/orders/?company=${companyId}&location=${location}${filters}`);
    if (res.status === undefined || res.status === 400 || res.status === 403 || res.status === 404 || res.status === 500 || !res.data.results) {
      throw new Error('');
    }
    return res;
  } catch (error) {
    return Notification('error', 'An error occurred while fetching orders', (error && error.message && error.message !== '') ? error.message : 'We could not perform your request, please try again.');
  }
};
