import React from 'react';
import api from 'helpers/api';
import { IconError, IconInfo, IconWarning } from 'shared/Icons';

export const renderEventIcon = (level) => {
  switch (level) {
    case 'warning':
      return (<IconWarning
        color="#ff9f43"
        height="14px"
        width="16px"
      />);
    case 'error':
      return (<IconError
        color="#ee5253"
        height="16px"
        width="16px"
      />);
    default:
      return (<IconInfo
        color="#2e86de"
        height="14px"
        width="16px"
      />);
  }
};

export const checkAccess = (resource, companyId) => api.get(`/api/v1/access/?company=${companyId}&resource=${resource}`);

export const checkAccessOnPage = (companyId) => {
  return new Promise((resolve) => {
    const url = new URL(window.location.href);
    let resource = url.pathname;

    // this is added because of IndustryLocationOrderDetails component
    // because we do not have resource ".../orders/{orderId}" and then access will always be full acces
    // code underneath will make sure to remove that id and check access of order resource and IndustryLocationOrderDetails will depend on that
    if (resource.includes('/orders/')) {
      let indexOfEnd = resource.indexOf('/orders/');
      indexOfEnd = indexOfEnd === -1 ? -1 : indexOfEnd + '/orders/'.length;
      resource = resource.substring(0, indexOfEnd);
    }

    if (resource.includes('/projects/')) {
      let indexOfEnd = resource.indexOf('/projects/');
      indexOfEnd = indexOfEnd === -1 ? -1 : indexOfEnd + '/projects/'.length;
      resource = resource.substring(0, indexOfEnd);
    }

    // remove / from end if exists
    if (resource.slice(-1) === '/') {
      resource = resource.slice(0, -1);
    }

    checkAccess(resource, companyId)
      .then((re) => {
        const data = re.data;
        resolve(data.access);
      });
  });
};

export const redirectToHomePage = (companyId, locationId) => {
  const homePage = `/${companyId}/industry/location/${locationId}/`;
  const url = new URL(window.location.href);
  const base = url.origin;
  alert('You do not have the access to this page.');
  window.location.href = `${base}${homePage}`;
};

export const redirectToSettingsPage = (companyId, locationId) => {
  const homePage = `/${companyId}/industry/location/${locationId}/settings/`;
  const url = new URL(window.location.href);
  const base = url.origin;
  alert('You do not have the access to this page.');
  window.location.href = `${base}${homePage}`;
};

export const checkModules = (companyId) => api.get(`/api/v1/modules/?company=${companyId}`);

export const checkActiveFilters = (defaultParams, userParams) => {
  let filtersActive = false;
  Object.entries(defaultParams).forEach(([filterKey, filterValue]) => {
    if (Object.prototype.hasOwnProperty.call(userParams, filterKey) && userParams[filterKey] !== filterValue) {
      filtersActive = true;
    }
  });
  return filtersActive;
};

export const numberSeparatorFormat = (numberFormatLocale, value, minimumFractionDigits, maximumFractionDigits, useGrouping) => {
  numberFormatLocale = numberFormatLocale === 'en' ? 'hr' : numberFormatLocale;
  return new Intl.NumberFormat(
    numberFormatLocale,
    {
      minimumFractionDigits: minimumFractionDigits || 0,
      maximumFractionDigits: maximumFractionDigits || 0,
      useGrouping: useGrouping || false,
    },
  ).format(value);
};

export const styledStatusOptions = (status) => {
  const defaultStyle = {
    display: 'inline-block',
    border: '1px solid gray',
    color: '#555',
    borderRadius: '4px',
    padding: '4px 8px',
    width: 'auto',
    textAlign: 'center',
  };

  switch (status) {
    case 'created':
      return {
        ...defaultStyle,
        color: '#374af0',
        borderColor: '#374af0',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #376bf0',
      };
    case 'in_progress':
      return {
        ...defaultStyle,
        color: '#fac12a',
        borderColor: '#fac12a',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #fac12a',
      };
    case 'in_warehouse':
      return {
        ...defaultStyle,
        color: '#ff8744',
        borderColor: '#ff8744',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ff8744',
      };
    case 'in_transit':
      return {
        ...defaultStyle,
        color: '#747d88',
        borderColor: '#747d88',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #747d88',
      };
    case 'finished':
      return {
        ...defaultStyle,
        color: '#3cc13b',
        borderColor: '#3cc13b',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #3cc13b',
      };
    case 'delivered':
      return {
        ...defaultStyle,
        color: '#9b16a7',
        borderColor: '#9b16a7',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #9b16a7',
      };
    case 'scheduled':
      return {
        ...defaultStyle,
        color: '#17a2b8',
        borderColor: '#17a2b8',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #17a2b8',
      };
    case 'paused':
      return {
        ...defaultStyle,
        color: '#dc3545',
        borderColor: '#dc3545',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #dc3545',
      };
    case 'partial_delivery':
      return {
        ...defaultStyle,
        color: '#d97750',
        borderColor: '#d97750',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #d97750',
      };
    case 'delivered_to_customer':
      return {
        ...defaultStyle,
        color: '#28a745',
        borderColor: '#28a745',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #28a745',
      };
    case 'closed':
      return {
        ...defaultStyle,
        color: '#6c757d',
        borderColor: '#6c757d',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #6c757d',
      };
    case 'graded':
      return {
        ...defaultStyle,
        color: '#6610f2',
        borderColor: '#6610f2',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #6610f2',
      };
    default:
      return defaultStyle;
  }
};

export const styledBookingStatusOptions = (status) => {
  const defaultStyle = {
    display: 'inline-block',
    border: '1px solid gray',
    color: '#555',
    borderRadius: '4px',
    padding: '4px 8px',
    width: 'auto',
    textAlign: 'center',
  };

  switch (status) {
    case 'created':
      return {
        ...defaultStyle,
        color: '#374af0',
        borderColor: '#374af0',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #376bf0',
      };
    case 'confirmation':
      return {
        ...defaultStyle,
        color: '#17a2b8',
        borderColor: '#17a2b8',
        background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #17a2b8',
      };
    case 'packaging':
      return {
        ...defaultStyle,
        color: '#fac12a',
        borderColor: '#fac12a',
        background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #fac12a',
      };
    case 'raw':
      return {
        ...defaultStyle,
        color: '#9b16a7',
        borderColor: '#9b16a7',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #9b16a7',
      };
    case 'all':
      return {
        ...defaultStyle,
        color: '#6c757d',
        borderColor: '#6c757d',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #6c757d',
      };
    default:
      return defaultStyle;
  }
};

export const styledAPIEventsOptions = (status) => {
  const defaultStyle = {
    display: 'inline-block',
    border: '1px solid gray',
    color: '#555',
    borderRadius: '4px',
    padding: '4px 8px',
    width: 'auto',
    textAlign: 'center',
  };

  switch (status) {
    case 'created':
      return {
        ...defaultStyle,
        color: '#374af0',
        borderColor: '#374af0',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #376bf0',
      };
    case 'resolved':
      return {
        ...defaultStyle,
        color: '#17a2b8',
        borderColor: '#17a2b8',
        background:
      'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #17a2b8',
      };
    case 'in_queue':
      return {
        ...defaultStyle,
        color: '#fac12a',
        borderColor: '#fac12a',
        background:
        'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #fac12a',
      };
    case 'on_hold':
      return {
        ...defaultStyle,
        color: '#747d88',
        borderColor: '#747d88',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #747d88',
      };
    default:
      return defaultStyle;
  }
};

export const styledWorkInjuryStatusOptions = (status) => {
  const defaultStyle = {
    display: 'inline-block',
    border: '1px solid gray',
    color: '#555',
    borderRadius: '4px',
    padding: '4px 8px',
    width: 'auto',
    textAlign: 'center',
  };

  switch (status) {
    case 'resolved':
      return {
        ...defaultStyle,
        color: '#28a745',
        borderColor: '#28a745',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #28a745',
      };
    case 'denied':
      return {
        ...defaultStyle,
        color: '#dc3545',
        borderColor: '#dc3545',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #dc3545',
      };
    case 'unresolved':
      return {
        ...defaultStyle,
        color: '#ffc107',
        borderColor: '#ffc107',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ffc107',
      };
    case 'in_progress':
      return {
        ...defaultStyle,
        color: '#007bff',
        borderColor: '#007bff',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #007bff',
      };
    default:
      return defaultStyle;
  }
};

export const styledIndividualGoalsStatus = (status) => {
  const defaultStyle = {
    display: 'inline-block',
    border: '1px solid gray',
    color: 'red',
    borderColor: 'red',
    background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), red',
    borderRadius: '4px',
    padding: '4px 8px',
    width: 'auto',
    textAlign: 'center',
  };

  switch (status) {
    case 'completed':
      return {
        ...defaultStyle,
        color: '#4caf50',
        borderColor: '#4caf50',
        background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #81c784',
      };
    default:
      return defaultStyle;
  }
};

export const styledAssignedEquipmentStatusOptions = (status) => {
  const defaultStyle = {
    display: 'inline-block',
    border: '1px solid gray',
    color: '#555',
    borderRadius: '4px',
    padding: '4px 8px',
    width: 'auto',
    textAlign: 'center',
  };

  switch (status) {
    case 'taken':
      return {
        ...defaultStyle,
        color: '#17a2b8',
        borderColor: '#17a2b8',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #17a2b8',
      };
    case 'returned':
      return {
        ...defaultStyle,
        color: '#28a745',
        borderColor: '#28a745',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #28a745',
      };
    case 'lost':
      return {
        ...defaultStyle,
        color: '#dc3545',
        borderColor: '#dc3545',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #dc3545',
      };
    case 'damaged':
      return {
        ...defaultStyle,
        color: '#ffc107',
        borderColor: '#ffc107',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ffc107',
      };
    default:
      return defaultStyle;
  }
};

export const styledFormStatusOptions = (status) => {
  const defaultStyle = {
    display: 'inline-block',
    border: '1px solid gray',
    color: '#555',
    borderRadius: '4px',
    padding: '4px 8px',
    width: 'auto',
    textAlign: 'center',
  };

  switch (status) {
    case 'submitted':
      return {
        ...defaultStyle,
        color: '#007bff',
        borderColor: '#007bff',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #007bff',
      };
    case 'on_hold':
      return {
        ...defaultStyle,
        color: '#ffc107',
        borderColor: '#ffc107',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #ffc107',
      };
    case 'rejected':
      return {
        ...defaultStyle,
        color: '#dc3545',
        borderColor: '#dc3545',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #dc3545',
      };
    case 'granted':
      return {
        ...defaultStyle,
        color: '#28a745',
        borderColor: '#28a745',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #28a745',
      };
    case 'finished':
      return {
        ...defaultStyle,
        color: '#28a745',
        borderColor: '#28a745',
        background:
          'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #28a745',
      };
    default:
      return defaultStyle;
  }
};
