import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { connect } from 'react-redux';
import ChangePassword from './ChangePassword/index';
import './style.scss';
import TwoFactorAuth from './2FA/index';

class UserProfile extends React.Component {
  render() {
    const { currentUser, t, companyConfig } = this.props;

    return (
      <div className="industry-tab user-profile fullscreen">
        <Tabs>
          <TabList>
            <Tab>{t('user_profile_page.reset_password_tab')}</Tab>
            <Tab>{t('user_profile_page.two_factor_tab')}</Tab>
          </TabList>
          <main>
            <TabPanel>
              <ChangePassword
                currentUser={currentUser}
                t={t}
                companyConfig={companyConfig}
              />
            </TabPanel>
            <TabPanel>
              <TwoFactorAuth
                currentUser={currentUser}
              />
            </TabPanel>
          </main>

        </Tabs>
      </div>
    );
  }
}

UserProfile.propTypes = {
  currentUser: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  companyConfig: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    ...state.app,
    currentUser: state.currentUser,
    companyConfig: state.app && state.app.companyConfig ? state.app.companyConfig : {},
  };
};

export default connect(mapStateToProps, null)(withTranslation()(UserProfile));
