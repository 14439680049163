import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { withTranslation } from 'react-i18next';

import { selectModalStyles } from 'styles/modules/reactSelect';
import { Modal } from 'shared';
import './styles.scss';

const ShiftModal = ({
  shift,
  isOpen,
  closeModal,
  saveShift,
  shifts,
  t,
  departments,
}) => {
  const [shiftObject, setShiftObject] = useState({
    name: '',
    order: shifts?.length + 1,
    begin_time: null,
    end_time: null,
    begin_break: null,
    end_break: null,
    saturday_is_working_day: false,
    sunday_is_working_day: false,
    department: null,
  });
  const {
    name,
    order,
    begin_time,
    end_time,
    begin_break,
    end_break,
    saturday_is_working_day,
    sunday_is_working_day,
    department,
  } = shiftObject;

  useEffect(() => {
    if (shift) setShiftObject(shift);
  }, []);

  const handleSave = () => {
    if (shift) saveShift({ ...shiftObject, id: shift.id, department: department.id });
    else saveShift({ ...shiftObject, department: department.id });
  };

  const changeField = (key, value) => {
    setShiftObject((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      handleSave={handleSave}
      handleClose={closeModal}
      title={
        shift
          ? t(
            'page_content.human_resources.shifts.shiftModal.placeholder_modal_edit',
          )
          : t(
            'page_content.human_resources.shifts.shiftModal.placeholder_modal_add',
          )
      }
      disableSave={!name || !begin_time || !end_time || !begin_break || !end_break || !department}
    >
      <div className="custom_data_modal_container_shifts">
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.shifts.table_column_shift_name')}*
          </div>
          <div className="right_input">
            <input
              type="text"
              value={name}
              onChange={(e) => changeField('name', e.target.value)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t(
              'page_content.human_resources.shifts.shiftModal.modal_row_shift_sequence',
            )}
          </div>
          <div className="right_input">
            <input
              type="number"
              value={order}
              onChange={(e) => changeField('order', e.target.value)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.shifts.table_column_start')}*
          </div>
          <div className="right_input">
            <DatePicker
              selected={
                begin_time ? moment(begin_time, 'HH:mm').toDate() : null
              }
              onChange={(time) => changeField(
                'begin_time',
                time ? moment(time).format('HH:mm') : null,
              )}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption={t('date_picker_locale.time_translation')}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.shifts.table_column_end')}*
          </div>
          <div className="right_input">
            <DatePicker
              selected={end_time ? moment(end_time, 'HH:mm').toDate() : null}
              onChange={(time) => changeField(
                'end_time',
                time ? moment(time).format('HH:mm') : null,
              )}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption={t('date_picker_locale.time_translation')}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.shifts.table_column_begin_break')}*
          </div>
          <div className="right_input">
            <DatePicker
              selected={
                begin_break ? moment(begin_break, 'HH:mm').toDate() : null
              }
              onChange={(time) => changeField(
                'begin_break',
                time ? moment(time).format('HH:mm') : null,
              )}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption={t('date_picker_locale.time_translation')}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t('page_content.human_resources.shifts.table_column_end_break')}*
          </div>
          <div className="right_input">
            <DatePicker
              selected={end_break ? moment(end_break, 'HH:mm').toDate() : null}
              onChange={(time) => changeField(
                'end_break',
                time ? moment(time).format('HH:mm') : null,
              )}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={1}
              timeCaption={t('date_picker_locale.time_translation')}
              timeFormat="HH:mm"
              dateFormat="HH:mm"
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t(
              'page_content.human_resources.shifts.shiftModal.modal_row_shift_saturday_is_working_day',
            )}
          </div>
          <div className="right_input">
            <input
              type="checkbox"
              checked={saturday_is_working_day}
              onChange={(e) => changeField('saturday_is_working_day', e?.target?.checked || false)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t(
              'page_content.human_resources.shifts.shiftModal.modal_row_shift_sunday_is_working_day',
            )}
          </div>
          <div className="right_input">
            <input
              type="checkbox"
              checked={sunday_is_working_day}
              onChange={(e) => changeField('sunday_is_working_day', e?.target?.checked || false)}
            />
          </div>
        </div>
        <div className="modal_row">
          <div className="left_text">
            {t(
              'page_content.human_resources.shifts.shiftModal.modal_row_shift_department',
            )}
            *
          </div>
          <div className="right_select">
            <div style={{ width: '100%' }}>
                <Select
                  options={departments}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option.id}
                  isSearchable
                  isClearable
                  menuPosition="fixed"
                  placeholder={t(
                    'page_content.human_resources.shifts.shiftModal.placeholder_select_department',
                  )}
                  onChange={(val) => changeField('department', val)}
                  value={
                        department ??
                        departments?.find((val) => val.id === department?.id)
                    }
                  styles={selectModalStyles}
                />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ShiftModal.propTypes = {
  shift: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  saveShift: PropTypes.func.isRequired,
  shifts: PropTypes.array.isRequired,
  departments: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ShiftModal);
