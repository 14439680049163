import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { widgetTypes } from '../constants';
import './styles.scss';

const renderIcon = (widgetType) => {
  const widget = widgetTypes.find((w) => w.type === widgetType);
  return widget ? (<div>{widget.icon}<span>{widget.name}</span></div>) : null;
};

class WidgetPlaceholder extends Component {
  render() {
    const { height, type } = this.props;
    let h = height;
    if (height <= 10 && type === 'scalar') {
      h = 50;
    }

    return (
      <div className="widget-placeholder" style={{ height: `${h}px` }}>
        {renderIcon(this.props.type)}
      </div>
    );
  }
}

WidgetPlaceholder.propTypes = {
  height: PropTypes.number.isRequired,
  type: PropTypes.string,
};

export default WidgetPlaceholder;
