import React from 'react';
import PropTypes from 'prop-types';

import QualificationsTable from './QualificationsTable';
import ProfessionsTable from './ProfessionsTable';

const QualificationsAndProfessions = ({ companyId, locationId, userHaveFullAccess }) => {
  return (
        <div className="qualifications_and_professions_tab">
            <QualificationsTable
              companyId={companyId}
              locationId={locationId}
              userHaveFullAccess={userHaveFullAccess}
            />

            <ProfessionsTable
              companyId={companyId}
              locationId={locationId}
              userHaveFullAccess={userHaveFullAccess}
            />
        </div>
  );
};

QualificationsAndProfessions.propTypes = {
  locationId: PropTypes.number.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userHaveFullAccess: PropTypes.bool.isRequired,
};

export default QualificationsAndProfessions;
